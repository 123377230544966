import React from 'react'
import { Link, RouteComponentProps, useLocation, navigate } from '@reach/router'
import Fuse from 'fuse.js'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import reduce from 'lodash/reduce'
import useApps from 'hooks/useApps'
import useHeader from 'hooks/useHeader'
import Entity from 'components/shared/Entity'
import {
  getServerAnalyticsItems,
  getTVEResourcesItems,
  getVoiceOfCustomerItems,
} from 'components/layout/DashboardSidebar/items'
import { HandleApplicationSwitcherClose } from 'components/layout/Topbar/ApplicationSwitcher'
import { AppWithAlias } from 'components/App/Providers/Data/types'
import * as S from './styles'
import {
  getAppOrGroupLink,
  getClientAnalyticsDevResourcesItems,
  getDomain,
  APP_PLACEHOLDER,
} from '../utils'
import Typography from 'components/shared/typography/Typography'
import Flex from 'components/shared/flex/Flex'
import Tooltip from '@material-ui/core/Tooltip'

function ApplicationsList({
  apps,
  search,
  isFavoritesList = false,
  handleApplicationSwitcherClose,
}: ApplicationsListProps) {
  const { appsByDataSource, appsByDomain, entityID, appsWithAlias } = useApps()
  const { filterValues, updateValues } = useHeader()
  const location = useLocation()

  const searchApplications = new Fuse((appsWithAlias as AppWithAlias[]) || [], {
    minMatchCharLength: 3,
    threshold: 0.4,
    keys: ['app', 'alias'],
  })

  const filteredApps = (
    isEmpty(search)
      ? apps
      : searchApplications
          .search(search as string)
          .map((result: Fuse.FuseResult<AppWithAlias>) => result.item.app)
  ) as string[]

  const clientAnalyticsDevResourcesItems = getClientAnalyticsDevResourcesItems()
  const serverAnalyticsItems = getServerAnalyticsItems(APP_PLACEHOLDER)
  const tveItems = getTVEResourcesItems(APP_PLACEHOLDER)
  const voiceOfCustomerItems = getVoiceOfCustomerItems(APP_PLACEHOLDER)

  function getSection(app: string) {
    if (appsByDataSource.quantum.includes(app)) return 'client-analytics'
    if (appsByDataSource.qube.includes(app)) return 'server-analytics'
    if (appsByDataSource.tve.includes(app)) return 'tve-dashboards'
    if (appsByDataSource.journey_survey?.includes(app)) return 'voice-of-customer'
    if (appsByDataSource.employee_experience?.includes(app)) return 'employee-experience'
    return 'client-analytics'
  }

  function getItems(app: string) {
    if (appsByDataSource.quantum.includes(app)) return clientAnalyticsDevResourcesItems
    if (appsByDataSource.qube.includes(app)) return serverAnalyticsItems
    if (appsByDataSource.tve.includes(app)) return tveItems
    if (appsByDataSource.journey_survey?.includes(app)) return voiceOfCustomerItems
    if (appsByDataSource.employee_experience?.includes(app)) return voiceOfCustomerItems
    return clientAnalyticsDevResourcesItems
  }

  const applicationRows = filteredApps.map(app => {
    const appIDParts = app.split('@')
    const name = appIDParts[0]
    const type = appIDParts[1]
    const techType = appIDParts[2]

    const section = getSection(app)

    enum sectionLabels {
      'voice-of-customer' = 'Journey',
      'server-analytics' = 'Server',
      'client-analytics' = 'Client',
      'tve-dashboards' = 'TVE',
      'employee-experience' = 'Emp Exp',
    }

    const sectionLabel = sectionLabels[section]
    const isFavorite = isFavoritesList ? ({ isFavorite: 'selected' } as any) : {}
    const domain = getDomain(app, appsByDomain)

    const items = getItems(app)

    const link = getAppOrGroupLink(items, domain, app, location.pathname, entityID, section, false)

    function handleLinkClick(value: any) {
      const filtersWithoutDefaultFilters = omit(filterValues, [
        'environment',
        'period',
        'step',
        'groupBy',
      ])
      const filtersWithEmptyValues = reduce(
        filtersWithoutDefaultFilters,
        (acc, value, key) => {
          acc[key] = []
          return acc
        },
        {}
      )
      // This is used to remove unnecessary filters when app or group is switched.
      // Most of filter values are not relevant to different app. For example, application version, experiment uuids are different for each app.
      updateValues(filtersWithEmptyValues)
      handleApplicationSwitcherClose(value)
      navigate(link)
    }

    return (
      <div>
        <Tooltip arrow={true} title={name} placement="top">
          <Link onClick={handleLinkClick} to={link} style={{ width: '100%' }}>
            <Flex alignItems={'center'}>
              <Entity
                title={name}
                description={type ? `${type}${techType ? ` (${techType})` : ''}` : ''}
                domain={sectionLabel}
                {...isFavorite}
                data-pho="topbarApplication"
              />
            </Flex>
          </Link>
        </Tooltip>
      </div>
    )
  })

  return (
    <div>
      {!isEmpty(applicationRows) ? (
        applicationRows
      ) : (
        <S.NoResultsMessage>
          {isFavoritesList ? 'No favorites currently selected.' : 'No results found.'}
        </S.NoResultsMessage>
      )}
    </div>
  )
}

interface ApplicationsListProps extends RouteComponentProps {
  apps: string[]
  search?: string
  isFavoritesList?: boolean
  handleApplicationSwitcherClose: (event: HandleApplicationSwitcherClose) => void
}

export default ApplicationsList
