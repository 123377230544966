import styled from 'styled-components'
import NucleusReactPill from 'components/shared/Pill'
import Spacing from '../bit/utils/spacing'
import { Badge } from 'components/shared/Badge'
export const PillGroup = styled.div`
  > div[role='button'] {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const PillContainer = styled.div`
  > div[role='button'] > span > p > div {
    font-weight: 500;
  }

  margin: 0 0.5rem;
`

export const Pill = styled(NucleusReactPill)`
  && {
    margin-top: 1.125rem;
    margin-bottom: 0.8125rem;
  }
  &&:focus {
    margin-top: 1.125rem;
    margin-bottom: 0.8125rem;
  }
`

export const CustomPill = styled(NucleusReactPill)`
  width: auto;
  && {
    height: 1.25rem !important;
    margin: 0 0 0 0.75rem !important;
  }
  && p {
    font-weight: 500;
  }
`

export const TooltipContainer = styled.div`
  display: flex;
`

export const LeftColumn = styled.div`
  margin-right: 1rem;
`

export const PillGroupContainer = styled.div<{ isCustomPills?: boolean }>`
  width: ${({ isCustomPills }) => (isCustomPills ? 'max-content' : '100%')};
  display: flex;
  margin-bottom: ${Spacing.element.xs};
`

export const StyledPill = styled(Badge)`
  &&,
  &&:focus {
    margin-bottom: 0.8125rem;
  }
`
