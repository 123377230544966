import React from 'react'
import { isEmpty } from 'lodash'
import Link from './Link'
import Icon, { IconName } from '../icon/Icon'
import colors from '../bit/utils/colors'
import * as S from './styles'

function Pill({
  icon,
  customIcon,
  href,
  to,
  outline,
  children,
  value,
  onClick,
  onDelete,
  deletable = true,
  ...otherProps
}: PillProps): React.FunctionComponentElement<PillProps> {
  function handleClick() {
    onClick && onClick(value || children)
  }

  function handleDelete() {
    onDelete && onDelete(value || children)
  }

  const commonPillProps = {
    icon: customIcon || (icon && <Icon name={icon} />),
    outline: outline,
    label: <S.Text> {children}</S.Text>,
    variant: outline ? 'outlined' : 'default',
    'data-cy': 'pill',
  }

  const deletablePill = (
    <S.StyledChip
      {...commonPillProps}
      deleteIcon={<S.DeletableIcon name="X" size={'0.5rem'} />}
      onClick={onClick && handleClick}
      onDelete={onDelete && handleDelete}
      {...otherProps}
    />
  )

  const regularPill = <S.StyledChip {...commonPillProps} {...otherProps} />

  if (!isEmpty(href) || !isEmpty(to))
    return (
      <Link href={href} to={to}>
        {deletable ? deletablePill : regularPill}
      </Link>
    )

  return deletable ? deletablePill : regularPill
}

interface PillProps {
  children: React.ReactNode
  className?: string
  backgroundColor?: string
  color?: string
  deletable?: boolean
  onClick?: Function
  onDelete?: Function
  icon?: IconName
  outline?: boolean
  href?: string
  to?: string
  value?: KeyValueObj | string
  customIcon?: React.ReactNode
  style?: Record<string, string>
}

type KeyValueObj = {
  key: string
  value: string
}

Pill.defaultProps = {
  backgroundColor: colors.gray20,
  color: colors.black,
}

export default Pill
export { S as Styles }
