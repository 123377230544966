import styled, { css } from 'styled-components'
import elevations, { Elevation } from '../bit/utils/elevation'
import colors from 'components/shared/utils/colors'
import transition from 'components/shared/utils/transition'
import spacing from 'components/shared/utils/spacing'
import { mediaQueries } from '../bit/utils/breakpoints'

interface CardProps {
  $width: string
  $minWidth?: string
  $maxWidth?: string
  $height?: string
  $minHeight?: string
  $maxHeight?: string
  $centered?: boolean
  $justifySelf?: string
  $alignSelf?: string
  $elevation?: Elevation
  $backgroundColor?: string
  $disableBorder: boolean
  $disableRounding: boolean
  $disableShadow: boolean
  $isDarkMode?: boolean
  $overflow?: string
}

export const Card = styled.div<CardProps>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  ${({ $elevation, $isDarkMode, $disableShadow }) =>
    !$disableShadow &&
    $elevation &&
    ($isDarkMode ? elevations.darkMode[$elevation] : elevations.lightMode[$elevation])};
  width: ${({ $width }) => $width};
  background-color: ${({ $backgroundColor, $elevation, $isDarkMode }) => {
    if ($backgroundColor) return $backgroundColor
    return colors[$isDarkMode ? 'darkMode' : ('lightMode' as any)].elevation[
      $elevation as Elevation
    ]
  }};
  border: 1px solid
    ${({ $disableBorder, $isDarkMode }) =>
      $isDarkMode || $disableBorder ? 'transparent' : colors.border};
  text-align: ${({ $centered }) => ($centered ? 'center' : 'left')};
  transition-property: background-color, box-shadow;
  transition-duration: ${transition.durations.medium};
  transition-timing-function: ${transition.timingFunctions.standard};
  overflow: hidden;
  box-sizing: border-box;

  :focus {
    outline: none;
  }

  ${({ $disableRounding }) =>
    !$disableRounding &&
    css`
      border-radius: 4px;
    `}
  ${({ $minWidth }) =>
    $minWidth &&
    css`
      min-width: ${$minWidth};
    `};
  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${$maxWidth};
    `};
  ${({ $height }) =>
    $height &&
    css`
      height: ${$height};
    `};
  ${({ $minHeight }) =>
    $minHeight &&
    css`
      min-height: ${$minHeight};
    `};
  ${({ $maxHeight }) =>
    $maxHeight &&
    css`
      max-height: ${$maxHeight};
    `};
  ${({ $justifySelf }) =>
    $justifySelf &&
    css`
      justify-self: ${$justifySelf};
    `};
  ${({ $alignSelf }) =>
    $alignSelf &&
    css`
      align-self: ${$alignSelf};
    `};
  ${({ $overflow }) =>
    $overflow &&
    css`
      overflow: ${$overflow};
    `};
`

interface ImageProps {
  $width: string
  $src: string
}

export const Image = styled.div<ImageProps>`
  width: ${({ $width }) => $width};
  height: calc(${({ $width }) => $width} / 16 * 9);
  background: url(${({ $src }) => $src}) no-repeat center / cover;
`

interface CardContentProps {
  $padding: string
  $disablePadding: boolean
}

export const CardContent = styled.div<CardContentProps>`
  box-sizing: border-box;
  width: 100%;
  flex: 1;
  padding: ${({ $disablePadding, $padding }) =>
    $disablePadding ? 0 : $padding || spacing.element.xxl};

  ${mediaQueries.md} {
    padding: ${({ $disablePadding, $padding }) =>
      $disablePadding ? 0 : $padding || spacing.element.xl};
  }

  ${mediaQueries.xs},${mediaQueries.sm} {
    padding: ${({ $disablePadding, $padding }) =>
      $disablePadding ? 0 : $padding || spacing.element.md};
  }
`
