import { ItemProps } from 'components/shared/Sidebar/Item'
import { Domains } from 'common/constants'
import {
  getClientAnalyticsItems,
  getQuantumResourcesItems,
} from 'components/layout/DashboardSidebar/items'

export const APP_PLACEHOLDER = 'EntityID'

export function getDomain(app: string, appsByDomain: Record<string, string[]>) {
  if (appsByDomain.internal_apps.includes(app)) {
    return Domains.Internal
  } else if (appsByDomain.video.includes(app)) {
    return Domains.Video
  } else if (appsByDomain.journey_survey?.includes(app)) {
    return Domains.JourneySurvey
  } else if (appsByDomain.employee_experience?.includes(app)) {
    return Domains.EmployeeExperience
  } else {
    return Domains.Portals
  }
}

export function getClientAnalyticsDevResourcesItems() {
  const clientAnalyticsItems = getClientAnalyticsItems(APP_PLACEHOLDER)
  const quantumResourcesItems = getQuantumResourcesItems(APP_PLACEHOLDER)
  return clientAnalyticsItems.concat(quantumResourcesItems)
}

export function filterItems(
  items: ItemProps[],
  currentDomain: string,
  currentApp: string,
  isGroup?: boolean
): ItemProps[] {
  return items.filter(({ domains, apps, noGroups }) => {
    if (isGroup && noGroups) {
      return false
    }
    if (domains || apps) {
      return (
        (domains || []).some(domain => currentDomain === domain) ||
        (apps || []).some(app => currentApp === app)
      )
    }
    return true
  })
}

export function getAppOrGroupLink(
  items: ItemProps[],
  domain: string,
  app: string,
  currentPathname: string,
  currentEntityID: any,
  section: string,
  isGroup?: boolean
) {
  const filteredSidebarItems = filterItems(items, domain, app, isGroup)
  const pathnameWithAppPlaceholder = currentPathname.replace(currentEntityID, APP_PLACEHOLDER)
  // To support feature https://gitlab.spectrumflow.net/client-analytics/prism/-/issues/1138
  const splitPathname = currentPathname.split('/')
  // /Prism@Web/quantum-resources/event-types/apiCall/internal_apps => /Prism@Web/quantum-resources/event-types or /Prism@Web/client-analytics/apis/details => Prism@Web/client-analytics/apis
  const shallowDepthPathname = splitPathname.slice(0, 4).join('/')
  // /Prism@Web/quantum-resources/event-types => /EntityID/quantum-resources/event-types
  const shallowDepthPathnameWithAppPlaceholder = shallowDepthPathname.replace(
    currentEntityID,
    APP_PLACEHOLDER
  )

  const pathExistsInSidebarItems = filteredSidebarItems.find(sidebarItem => {
    if (sidebarItem.items) {
      return sidebarItem.items.find(
        subItems => subItems.link === pathnameWithAppPlaceholder && !subItems.overviewWhenAppSwitch
      )
    }
    return sidebarItem.link === pathnameWithAppPlaceholder
  })

  const shallowDepthPathExistsInSidebarItems = filteredSidebarItems.find(sidebarItem => {
    if (sidebarItem.items) {
      return sidebarItem.items.find(
        subItems =>
          subItems.link === shallowDepthPathnameWithAppPlaceholder &&
          !subItems.overviewWhenAppSwitch
      )
    }
    return sidebarItem.link === shallowDepthPathnameWithAppPlaceholder
  })

  if (pathExistsInSidebarItems) {
    return currentPathname.replace(currentEntityID, app)
  }

  if (shallowDepthPathExistsInSidebarItems) {
    return shallowDepthPathname.replace(currentEntityID, app)
  }

  if (section === 'tve-dashboards') return `/${app}/${section}/auth`
  if (section === 'voice-of-customer') return `/${app}/${section}/digital-surveys/overview`
  if (section === 'employee-experience') return `/${app}/voice-of-customer/digital-surveys/overview`

  return `/${app}/${section}/overview`
}

export function getPrismLogoLink(
  appsByDataSource: Record<string, string[]>,
  currentApps?: string[],
  entityID?: string,
  search?: string
) {
  if (entityID && entityID === 'TVE') return `/${entityID}/tve-dashboards/auth`
  if (entityID && currentApps?.[0]) {
    if (appsByDataSource.journey_survey?.includes(currentApps[0])) {
      return `/${entityID}/voice-of-customer/digital-surveys/overview${search}`
    } else if (appsByDataSource.employee_experience?.includes(currentApps[0])) {
      return `/${entityID}/voice-of-customer/digital-surveys/overview${search}`
    } else {
      const section = appsByDataSource.quantum?.includes(currentApps[0])
        ? 'client-analytics'
        : 'server-analytics'
      return `/${entityID}/${section}/overview${search}`
    }
  }
  return '/'
}
