import styled, { css } from 'styled-components'
import ReactSlider from 'react-slider'
import colors from 'components/shared/utils/colors'

export const Slider = styled(ReactSlider)<{ disabled?: boolean }>`
  width: 100%;
  height: 4px;
  margin-top: 10px;
`

export const Thumb = styled.div<{ disabled?: boolean; isDarkMode?: boolean }>`
  height: 14px;
  width: 14px;
  border-radius: 50%;
  top: -5px;

  /* This instructions can be used to give the thumb a rectangle style (we can use this in the future)
      height: 16px;
      width: 6px;
      top: -6px;
      background-color: #9BA9BD; */

  ${({ isDarkMode, disabled }) =>
    isDarkMode
      ? disabled
        ? css`
            background-color: ${colors.gray30};
            cursor: not-allowed;
          `
        : css`
            background-color: ${colors.blue10};
            cursor: grab;
          `
      : disabled
      ? css`
          background-color: ${colors.lightBlue10};
          cursor: not-allowed;
        `
      : css`
          background-color: ${colors.blue20};
          cursor: grab;
        `}
`

export const Track = styled.div<{
  index?: number
  disabled?: boolean
  isDarkMode?: boolean
}>`
  top: 0;
  bottom: 0;
  border-radius: 999px;

  ${({ isDarkMode, disabled, index }) =>
    isDarkMode
      ? disabled
        ? css`
            background: ${index === 1 ? colors.gray10 : colors.gray30};
          `
        : css`
            background: ${index === 1 ? colors.gray10 : colors.blue10};
          `
      : disabled
      ? css`
          background: ${index === 1 ? colors.gray20 : colors.lightBlue10};
        `
      : css`
          background: ${index === 1 ? colors.gray20 : colors.blue20};
        `}
`

export const Mark = styled.div<{
  markWidth: number
  markBorder: number
}>`
  height: ${({ markWidth }) => `${markWidth}px`};
  width: ${({ markWidth }) => `${markWidth}px`};
  top: -3px;
  border: ${({ markBorder }) => `${markBorder}px solid ${colors.blue20}`};
  border-radius: 50%;
  background-color: ${colors.white};
  cursor: pointer;
`

export const MarkTick = styled.span<{
  tickWidth: number
  isDarkMode?: boolean
}>`
  top: -25px;
  color: ${({ isDarkMode }) => (isDarkMode ? 'white' : colors.gray30)};
  width: ${({ tickWidth }) => `${tickWidth}px`};
  text-align: center;
`
