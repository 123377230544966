import React from 'react'
import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import { Button } from 'components/shared/Button'
import colors from '../bit/utils/colors'

export const StyledFeedbackIcon = styled.img`
  width: 1rem;
  margin-right: 0.5rem;
`

export const SuccessContainer = styled.div`
  text-align: center;
`

export const FeedbackButtonContainer = styled.div`
  background-color: transparent;
  line-height: 0;
`

export const StyledButton = styled(Button)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: capitalize;
`

export const ModalSubmitButton = styled(Button)`
  && {
    width: 165px;
  }
`

export const ModalTitle = styled.h4`
  font-size: 1.25rem;
  margin-bottom: 0.625rem;
  color: ${colors.blue10};
`

export const CategoryContainer = styled(FormControl)`
  margin-top: 1.875rem;
  width: 500px;
`
export const FeedbackErrorText = styled(
  ({ shouldDisplay, ...otherProps }: { shouldDisplay: boolean; [key: string]: any }) => (
    <div {...otherProps} />
  )
)`
  visibility: ${props => (props.shouldDisplay ? 'visible' : 'hidden')};
  margin-top: 1.25rem;
  margin-bottom: -1.875rem;
  opacity: ${props => (props.shouldDisplay ? 1 : 0)};
  transition: opacity 0.2s linear;
  color: ${colors.red10};
`

export const StarRatingContainer = styled.div`
  margin: 1.5rem 0;
  display: flex;
  flex-flow: column nowrap;
  > span:last-child {
    margin-top: 0.5rem;
  }
`
