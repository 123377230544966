import styled from 'styled-components'
import Typography from '../../typography/Typography'

export const Legend = styled(Typography).attrs(() => ({
  as: 'legend',
  variant: 'title6',
}))`
  && {
    padding: 0;
  }
`
