import React from 'react'
import styled from 'styled-components'
import colors from 'components/shared/bit/utils/colors'
export const StyledInput = styled(
  ({ noBorderBottom, ...otherProps }: { noBorderBottom?: boolean; [key: string]: any }) => (
    <input {...otherProps} />
  )
)`
  width: 100%;
  border: none;
  padding: 0.75rem 1.25rem;
  line-height: normal;
  border: solid 1px ${colors.gray30};
  border-radius: 21px;

  && input {
    ::placeholder {
      color: ${colors.gray30};
      opacity: 1;
    }
  }

  :active,
  :focus {
    outline: none;
  }
`
