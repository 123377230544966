import styled from 'styled-components'
import colors from 'components/shared/bit/utils/colors'
import { defaultFont } from 'common/constants'

export const ErrorMessageContainer = styled.div`
  ${defaultFont};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: 400;
  color: ${colors.black};
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`

export const ErrorMessageOldUrl = styled.div`
  font-weight: 500;
  line-height: 1.63;
  letter-spacing: -0.3px;
  color: ${colors.gray30};
  > p {
    font-weight: 500;
    line-height: 1.63;
    letter-spacing: -0.3px;
    color: ${colors.gray30};
    margin-bottom: 0.5rem;
  }
`

export const ErrorMessageOldUrlLink = styled.div`
  color: ${colors.blue10};
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
`

export const UpdateTitle = styled.div`
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.63;
  letter-spacing: -0.3px;
  color: ${colors.gray30};
  margin-bottom: 1rem;
`

export const ErrorMessageReport = styled.span`
  font-weight: 500;
  line-height: 1.63;
  letter-spacing: -0.3px;
  color: ${colors.gray30};
`

export const IssueButton = styled.span`
  margin-top: 1rem;
`
