import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import colors from '../bit/utils/colors'

export const PopoverContainer = styled.div`
  color: ${colors.black};
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
`

export const AnchorButton = styled(
  ({
    backgroundColor,
    fontColor,
    borderColor,
    height,
    ...otherProps
  }: {
    backgroundColor?: string
    fontColor?: string
    borderColor?: string
    height?: string
    [key: string]: any
  }) => <Button disableRipple {...otherProps} />
)<{ backgroundColor?: string }>`
  box-shadow: none;
  color: ${colors.black};
  font-family: inherit;
  min-width: unset;
  padding: 0;
  text-transform: unset;
  &&:hover {
    background: none;
  }
  && .MuiButton-label > span {
    display: inherit;
  }
  ${props => (props.backgroundColor ? `background-color: ${props.backgroundColor};` : '')}
  ${props => (props.fontColor ? `color: ${props.fontColor};` : '')}
  ${props => (props.height ? `height: ${props.height};` : '')}
  ${props => (props.borderColor ? `border-color: ${props.borderColor};` : '')}
`
