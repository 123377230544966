import { useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { Button } from 'components/shared/Button'
import { Toast } from 'components/shared/Toast'
import { copyToClipboard } from 'utils'

function CopyVisitIDButton({
  visitID,
  isTextButton = false,
}: {
  visitID: string
  isTextButton?: boolean
}) {
  const [open, setOpen] = useState(false)

  function handleClick() {
    copyToClipboard(visitID)
    setOpen(true)
  }

  return (
    <>
      <Toast
        placement="bottom-left"
        isToastOpen={open}
        onDismiss={() => setOpen(false)}
        duration={6}
        label={`Copied to Clipboard: ${visitID}`}
      />
      <Tooltip title={`Copy to clipboard: ${visitID}`} placement="top" arrow>
        <div>
          {isTextButton ? (
            <Button icon="Copy" onClick={handleClick} variant="borderless" data-pho="copyVisitID">
              Copy Visit ID
            </Button>
          ) : (
            <Button
              iconSize="sm"
              icon="Copy"
              onClick={handleClick}
              variant="borderless"
              data-pho="copyVisitID"
            >
              Copy Visit ID
            </Button>
          )}
        </div>
      </Tooltip>
    </>
  )
}

export default CopyVisitIDButton
