import styled from 'styled-components'
import { Button } from 'components/shared/Button'
import colors from '../bit/utils/colors'
export const AnchorButton = styled(Button)`
  && {
    box-shadow: none;
    color: ${colors.black};
    min-width: unset;
    transition-duration: 200;
    transition-property: background;
    :disabled {
      color: ${colors.gray20};
    }
  }
`

export const Text = styled.span`
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: unset;
`
