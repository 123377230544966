import React from 'react'
import * as S from './styles'
import colors from '../bit/utils/colors'
import Typography from '../typography/Typography'

export type Label = string | number

export interface Item {
  label: Label
  value: any
  onClick?: (value: any, label?: Label) => void
  isSelected: boolean
  isDarkMode: boolean
  width?: number
}

export interface DropdownItemProps extends Item {
  handleSelect: (value: any, label: Label) => void
}

export const DropdownItem = ({
  handleSelect,
  label,
  value,
  onClick,
  isSelected,
  isDarkMode,
  width,
}: DropdownItemProps) => {
  const handleClick = React.useCallback(() => {
    onClick && onClick(value, label)
    handleSelect(value, label)
  }, [onClick, handleSelect])

  return (
    <S.DropdownItem
      onClick={handleClick}
      $width={width}
      $isSelected={isSelected}
      $isDarkMode={isDarkMode}
      data-pho={label}
    >
      <S.StyledIcon name="Checkmark" size="xs" color={colors.blue20} $isSelected={isSelected} />
      <Typography variant="body" color={isDarkMode ? colors.white : colors.black} noMargin>
        {label}
      </Typography>
    </S.DropdownItem>
  )
}
