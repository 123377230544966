import { Domains } from 'common/constants'
import { ItemProps } from 'components/shared/Sidebar/Item'
import { LogoContainer } from 'components/shared/Sidebar/styles'
import styled from 'styled-components'

// Icons for Sidebar
//metrics>all metrics
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList'
//metrics>dashboard
import DashboardIcon from '@material-ui/icons/Dashboard'
//voc overview
import PollIcon from '@material-ui/icons/Poll'
//voc reviews
import RateReviewIcon from '@material-ui/icons/RateReview'
//voc categorize reviews
import Category from '@material-ui/icons/Category'
//voc ml best practices
import AssignmentIcon from '@material-ui/icons/Assignment'
//training data
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting'
//training data metrics
import TableChartIcon from '@material-ui/icons/TableChart'
//training data metrics
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
//helix req version
import AccountTree from '@material-ui/icons/AccountTree'
//req decoder
import VpnKey from '@material-ui/icons/VpnKey'
//library version
import LocalLibrary from '@material-ui/icons/LocalLibrary'
// api name mapping
import Map from '@material-ui/icons/Map'
// api service result
import AssistantIcon from '@material-ui/icons/Assistant'
// map unknowns
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation'
// Overview
import HomeIcon from '@material-ui/icons/Home'
// Reports
import DvrIcon from '@material-ui/icons/Dvr'
// General Usage
import BarChartIcon from '@material-ui/icons/BarChart'
// 'Page Views
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
// Errors
import ErrorIcon from '@material-ui/icons/Error'
// Authentication
import SecurityIcon from '@material-ui/icons/Security'
// APIs
import DnsIcon from '@material-ui/icons/Dns'
// Video Quality
import TvIcon from '@material-ui/icons/Tv'
// Events
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt'
// Metrics
import SpeedIcon from '@material-ui/icons/Speed'
// Experiment Snapshot
import VisibilityIcon from '@material-ui/icons/Visibility'
// Customer Journey
import ExploreIcon from '@material-ui/icons/Explore'
// Voice of Customer
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
// Pulse
import GpsFixedIcon from '@material-ui/icons/GpsFixed'
// Event Types
import LayersIcon from '@material-ui/icons/Layers'
// Event Cases
import DescriptionIcon from '@material-ui/icons/Description'
// Volt Tests
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
// Platform List
import ListIcon from '@material-ui/icons/List'
// Incoming API Transactions
import CallReceivedIcon from '@material-ui/icons/CallReceived'
// Outgoing API Transactions
import CallMadeIcon from '@material-ui/icons/CallMade'
// Create New
import AddIcon from '@material-ui/icons/Add'
// My Dashboards
import PersonIcon from '@material-ui/icons/Person'

const SubmenuIconWrapper = styled.span`
  svg.MuiSvgIcon-root {
    font-size: 1rem;
  }
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
    font-size: 0.9rem;
  }
`

function getVoiceOfCustomerSubItems(entitySlug?: string, section?: string) {
  return [
    {
      title: 'Overview',
      link: `/${entitySlug}/${section}/overview`,
      icon: iconArgs => (
        <SubmenuIconWrapper>
          <PollIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />
        </SubmenuIconWrapper>
      ),
    },
    {
      title: 'Reviews',
      link: `/${entitySlug}/${section}/reviews`,
      icon: iconArgs => (
        <SubmenuIconWrapper>
          <RateReviewIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />
        </SubmenuIconWrapper>
      ),
    },
    {
      title: 'Categorize Reviews',
      link: `/${entitySlug}/${section}/edit-reviews`,
      icon: iconArgs => (
        <SubmenuIconWrapper>
          <Category fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />
        </SubmenuIconWrapper>
      ),
    },
    {
      title: 'Training Data',
      link: `/${entitySlug}/${section}/training-data`,
      icon: iconArgs => (
        <SubmenuIconWrapper>
          <PermDataSettingIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />
        </SubmenuIconWrapper>
      ),
    },
    {
      title: 'Training Data Metrics',
      link: `/${entitySlug}/${section}/td-metrics`,
      icon: iconArgs => (
        <SubmenuIconWrapper>
          <TableChartIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />
        </SubmenuIconWrapper>
      ),
    },
    {
      title: 'ML Best Practices',
      link: `/${entitySlug}/${section}/Machine-Learning-Best-Practices`,
      icon: iconArgs => (
        <SubmenuIconWrapper>
          <AssignmentIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />
        </SubmenuIconWrapper>
      ),
    },
    {
      title: 'Settings',
      link: `/${entitySlug}/${section}/settings`,
      icon: iconArgs => (
        <SubmenuIconWrapper>
          <SettingsApplicationsIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />
        </SubmenuIconWrapper>
      ),
    },
  ] as ItemProps[]
}

export function getClientAnalyticsItems(entitySlug?: string): ItemProps[] {
  const voiceOfCustomerSubItems = getVoiceOfCustomerSubItems(
    entitySlug,
    'client-analytics/voice-of-customer'
  )
  return [
    {
      title: 'Overview',
      icon: iconArgs => <HomeIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      link: `/${entitySlug}/client-analytics/overview`,
    },
    {
      title: 'Reports',
      icon: iconArgs => <DvrIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      apps: ['TechMobile@Android', 'TechOS@Android'],
      items: [
        {
          title: 'KPIs',
          link: `/${entitySlug}/client-analytics/tech-mobile/overview`,
        },
        {
          title: 'Job Start/Completion',
          link: `/${entitySlug}/client-analytics/tech-mobile/job-start-completion`,
        },
      ],
    },
    {
      title: 'Self Install Reports',
      icon: iconArgs => <DvrIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      apps: ['MySpectrum@Android', 'MySpectrum@iOS', 'MySpectrum@Web'],
      items: [
        {
          title: 'Kibana Dashboard',
          link: `https://quantum-tools.spectrumtoolbox.com/kibana/app/dashboards#/view/4652d240-b724-11ea-9d93-f5bc42e92eb8?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(description:'',filters:!(('$state':(store:appState),meta:(alias:Prod,disabled:!f,index:'6fa471d0-5b9a-11e9-84c1-c58183342c1b',key:visit.applicationDetails.environment.environmentName,negate:!f,params:(query:prod),type:phrase),query:(match:(visit.applicationDetails.environment.environmentName:(query:prod,type:phrase))))),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!t,title:'MSA%20%7C%20Self%20Install',viewMode:view)`,
          isExternal: true,
        },
        {
          title: 'Tableau Reports',
          link: 'https://tableau.pi.spectrumtoolbox.com/#/views/Self-InstallReportnewasof12_22_2020/Self-ServiceSummary',
          isExternal: true,
        },
      ],
    },
    {
      title: 'General Usage',
      icon: iconArgs => <BarChartIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      link: `/${entitySlug}/client-analytics/general-usage`,
    },
    {
      title: 'Page Views',
      icon: iconArgs => <LibraryBooksIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      link: `/${entitySlug}/client-analytics/page-views`,
    },
    {
      title: 'Errors',
      icon: iconArgs => <ErrorIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      link: `/${entitySlug}/client-analytics/errors`,
    },
    {
      title: 'Authentication',
      icon: iconArgs => <SecurityIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      link: `/${entitySlug}/client-analytics/authentication`,
    },
    {
      title: 'APIs',
      icon: iconArgs => <DnsIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      link: `/${entitySlug}/client-analytics/apis`,
    },
    {
      title: 'Release Summary',
      noGroups: true,
      icon: iconArgs => (
        <LogoContainer
          alt="Release Summary Logo"
          src="/assets/rocket.svg"
          width={iconArgs?.isCondensed ? '1.25rem' : '1rem'}
          maxHeight={iconArgs?.isCondensed ? '1.25rem' : '1rem'}
          hasPaddingLeft
        />
      ),
      isGrayscaleImg: false,
      link: `/${entitySlug}/client-analytics/release-summary`,
    },
    {
      title: 'Video Quality',
      icon: iconArgs => <TvIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      domains: [Domains.Video],
      link: `/${entitySlug}/client-analytics/video-quality`,
    },
    {
      title: 'Events',
      icon: iconArgs => <OfflineBoltIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      link: `/${entitySlug}/client-analytics/events`,
    },
    {
      title: 'Metrics',
      icon: iconArgs => <SpeedIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      domains: [Domains.Portals],
      items: [
        {
          title: 'All Metrics',
          link: `/${entitySlug}/client-analytics/metrics/all`,
          icon: iconArgs => (
            <SubmenuIconWrapper>
              <FeaturedPlayListIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />
            </SubmenuIconWrapper>
          ),
        },
        {
          title: 'Dashboards',
          link: `/${entitySlug}/client-analytics/metrics/dashboards`,
          icon: iconArgs => (
            <SubmenuIconWrapper>
              <DashboardIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />
            </SubmenuIconWrapper>
          ),
        },
      ],
    },
    {
      title: 'Experiment Snapshot',
      icon: iconArgs => <VisibilityIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      link: `/${entitySlug}/client-analytics/experiment-snapshot`,
    },
    {
      title: 'Customer Journey',
      icon: iconArgs => <ExploreIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      noGroups: true,
      link: `/${entitySlug}/client-analytics/customer-journey`,
    },
    {
      title: 'Voice of Customer',
      icon: iconArgs => (
        <RecordVoiceOverIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />
      ),
      domains: [Domains.Portals, Domains.Video, Domains.Internal],
      items: voiceOfCustomerSubItems,
    },
    {
      title: 'Pulse',
      icon: iconArgs => <GpsFixedIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      link: `/${entitySlug}/client-analytics/pulse`,
      overviewWhenAppSwitch: true,
    },
  ] as ItemProps[]
}

export function getEmployeeExperienceItems(entitySlug?: string): ItemProps[] {
  const voiceOfCustomerSubItems = getVoiceOfCustomerSubItems(
    entitySlug,
    'voice-of-customer/digital-surveys'
  )
  return [
    {
      title: 'Voice of Customer',
      icon: iconArgs => (
        <RecordVoiceOverIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />
      ),
      domains: [Domains.Portals, Domains.Video, Domains.Internal, Domains.EmployeeExperience],
      items: voiceOfCustomerSubItems,
    },
  ]
}

export function getQuantumResourcesItems(entitySlug?: string): ItemProps[] {
  return [
    {
      title: 'Event Types',
      icon: iconArgs => <LayersIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      link: `/${entitySlug}/quantum-resources/event-types`,
    },
    {
      title: 'Event Cases',
      icon: iconArgs => <DescriptionIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      link: `/${entitySlug}/quantum-resources/event-cases`,
    },
    {
      title: 'Quantum Schema',
      icon: iconArgs => (
        <LogoContainer
          alt="Quauntum Logo"
          src="/assets/quantum-icon.1798e160.svg"
          width={iconArgs?.isCondensed ? '1.25rem' : '1rem'}
          maxHeight={iconArgs?.isCondensed ? '1.25rem' : '1rem'}
          hasPaddingLeft
        />
      ),
      link: `/${entitySlug}/quantum-resources/schema`,
    },
    {
      title: 'Volt Tests',
      icon: iconArgs => <CheckCircleIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      link: `/${entitySlug}/quantum-resources/volt-tests`,
    },
    {
      title: 'Platform List',
      icon: iconArgs => <ListIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      link: `/${entitySlug}/quantum-resources/platforms-list`,
    },
    {
      title: 'Helix',
      icon: iconArgs => (
        <LogoContainer
          alt="Helix Logo"
          src="/assets/helix-logo-06.svg"
          width={iconArgs?.isCondensed ? '1.25rem' : '1rem'}
          maxHeight={iconArgs?.isCondensed ? '1.25rem' : '1rem'}
          hasPaddingLeft
        />
      ),
      domains: [Domains.Portals, Domains.Video, Domains.Internal],
      items: [
        {
          title: 'Requirements Versions',
          link: `/${entitySlug}/quantum-resources/helix/requirements/versions`,
          icon: iconArgs => (
            <SubmenuIconWrapper>
              <AccountTree fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
            </SubmenuIconWrapper>
          ),
        },
        {
          title: 'Requirements Decoder',
          link: `/${entitySlug}/quantum-resources/helix/requirements/decoder/latest`,
          icon: iconArgs => <VpnKey fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
        },
        {
          title: 'Library Versions',
          link: `/${entitySlug}/quantum-resources/helix/release-notes`,
          icon: iconArgs => (
            <SubmenuIconWrapper>
              <LocalLibrary fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />
            </SubmenuIconWrapper>
          ),
        },
      ],
    },
    {
      title: 'API',
      icon: iconArgs => <DnsIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      domains: [Domains.Portals, Domains.Video, Domains.Internal],
      items: [
        {
          title: 'Name Mappings',
          link: `/${entitySlug}/quantum-resources/api-name-mappings/names`,
          icon: iconArgs => <Map fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
        },
        {
          title: 'Service Result',
          link: `/${entitySlug}/quantum-resources/api-name-mappings/service`,
          icon: iconArgs => <AssistantIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
        },
        {
          title: 'Map Unknowns',
          link: `/${entitySlug}/quantum-resources/api-name-mappings/unknowns`,
          icon: iconArgs => (
            <SubmenuIconWrapper>
              <NotListedLocationIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />
            </SubmenuIconWrapper>
          ),
        },
      ],
    },
    {
      title: 'Status',
      icon: iconArgs => <DvrIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      link: `/${entitySlug}/quantum-resources/status`,
    },
  ]
}

export function getServerAnalyticsItems(entitySlug?: string): ItemProps[] {
  return [
    {
      title: 'Overview',
      icon: iconArgs => <BarChartIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      link: `/${entitySlug}/server-analytics/overview`,
    },
    {
      title: 'Incoming API Transactions',
      icon: iconArgs => <CallReceivedIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      link: `/${entitySlug}/server-analytics/incoming`,
    },
    {
      title: 'Outgoing API Transactions',
      icon: iconArgs => <CallMadeIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      link: `/${entitySlug}/server-analytics/outgoing`,
    },
    {
      title: 'Pulse',
      icon: iconArgs => <GpsFixedIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      link: `/${entitySlug}/server-analytics/pulse`,
      overviewWhenAppSwitch: true,
    },
  ]
}

export function getQubeResourcesItems(entitySlug?: string): ItemProps[] {
  return [
    {
      title: 'Platform List',
      icon: iconArgs => <ListIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      link: `/${entitySlug}/quantum-resources/platforms-list`,
    },
    {
      title: 'API',
      icon: iconArgs => <DnsIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      domains: [Domains.Portals, Domains.Video, Domains.Internal],
      items: [
        {
          title: 'Name Mappings',
          link: `/${entitySlug}/quantum-resources/api-name-mappings/names`,
          icon: iconArgs => (
            <SubmenuIconWrapper>
              <Map fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />
            </SubmenuIconWrapper>
          ),
        },
        {
          title: 'Service Result',
          link: `/${entitySlug}/quantum-resources/api-name-mappings/service`,
          icon: iconArgs => (
            <SubmenuIconWrapper>
              <AssistantIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />
            </SubmenuIconWrapper>
          ),
        },
        {
          title: 'Map Unknowns',
          link: `/${entitySlug}/quantum-resources/api-name-mappings/unknowns`,
          icon: iconArgs => (
            <SubmenuIconWrapper>
              <NotListedLocationIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />
            </SubmenuIconWrapper>
          ),
        },
      ],
    },
  ]
}

export function getDashboardsItems(entitySlug?: string): ItemProps[] {
  return [
    {
      title: 'My Dashboards',
      icon: iconArgs => <PersonIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      link: `/${entitySlug}/dashboards/mine`,
    },
    {
      title: 'Dashboards List',
      icon: iconArgs => <DashboardIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      link: `/${entitySlug}/dashboards/list`,
    },
    {
      title: 'Sankey',
      icon: (iconArgs: any) => (
        <TableChartIcon
          style={{ transform: 'rotate(270deg)' }}
          fontSize={iconArgs?.isCondensed ? 'medium' : 'small'}
        />
      ),
      link: `/${entitySlug}/dashboards/sankey`,
      chipText: 'BETA',
    },
    {
      title: 'Create New',
      icon: iconArgs => <AddIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      link: `/${entitySlug}/dashboards/new`,
    },
  ]
}

export function getTVEResourcesItems(entitySlug?: string): ItemProps[] {
  return [
    {
      title: 'TVE - AuthZ',
      icon: iconArgs => <SecurityIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />,
      link: `/${entitySlug}/tve-dashboards/auth`,
    },
  ]
}

export function getVoiceOfCustomerItems(entitySlug?: string): ItemProps[] {
  const voiceOfCustomerDigitalSurveysSubItems = getVoiceOfCustomerSubItems(
    entitySlug,
    'voice-of-customer/digital-surveys'
  )
  const voiceOfCustomerJourneySurveySubItems = getVoiceOfCustomerSubItems(
    entitySlug,
    'voice-of-customer/journey-surveys'
  )

  const voiceOfCustomerItems = [
    {
      title: 'Digital Surveys',
      icon: iconArgs => (
        <RecordVoiceOverIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />
      ),
      domains: [Domains.JourneySurvey],
      items: voiceOfCustomerDigitalSurveysSubItems,
    } as ItemProps,
    {
      title: 'Journey Surveys',
      icon: iconArgs => (
        <RecordVoiceOverIcon fontSize={iconArgs?.isCondensed ? 'medium' : 'small'} />
      ),
      domains: [Domains.JourneySurvey],
      items: voiceOfCustomerJourneySurveySubItems,
    } as ItemProps,
  ]
  return voiceOfCustomerItems.filter(voiceOfCustomerItem => voiceOfCustomerItem) as ItemProps[]
}
