import { types } from '..'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import * as S from '../styles'

export default function SortLabel({
  sortField,
  header,
  sortDirection,
  handleSortChange,
}: {
  sortField: string
  header: types.ColumnHeader
  sortDirection: string
  handleSortChange: Function
}) {
  function renderTableSortLabel(
    sortField: string,
    header: { id: string; label: string; align?: string },
    sortDirection: string
  ) {
    return (
      <TableSortLabel
        key={header.id}
        active={sortField === header.id}
        direction={sortDirection as any}
        onClick={() => handleSortChange(header.id)}
        data-pho="feedbackTableSortLabel"
      >
        {header.label}
      </TableSortLabel>
    )
  }

  switch (header.id) {
    case 'awsSentiment':
      return (
        <TableCell
          key={header.id}
          align={(header.align as any) ?? 'left'}
          data-pho={`awsSentimentFeedbackTableHeader`}
        >
          {header.label}
          <S.MultipleTableSorts>
            {header.sublabels?.map((sublabel: { id: string; label: string }) =>
              renderTableSortLabel(sortField, sublabel, sortDirection)
            )}
          </S.MultipleTableSorts>
        </TableCell>
      )
    default:
      return (
        <TableCell
          key={header.id}
          align={(header.align as any) ?? 'left'}
          data-pho={`${header.id}FeedbackTableHeader`}
          style={{ minWidth: header.minWidth }}
        >
          {renderTableSortLabel(sortField, header, sortDirection)}
        </TableCell>
      )
  }
}
