import Icon from 'components/shared/icon/Icon'
import Spacing from 'components/shared/bit/utils/spacing'
import Flex from 'components/shared/flex/Flex'
import colors from 'components/shared/bit/utils/colors'
import Tooltip from '@material-ui/core/Tooltip'
import * as S from './styles'

export default function KryptoInstructions() {
  return (
    <S.InstructionContainer>
      <S.InstructionMessage>
        When uploading a CSV for Encrypt or Decrypt, you must have only 2 columns, “field” and
        “value.”
        <br />
        These columns should be labeled with a header row.
        <Tooltip
          title={
            <Flex
              alignItems="space-between"
              justifyContent="space-around"
              padding="8px"
              gap={Spacing.element.sm}
              direction="column"
            >
              <Flex>field,value</Flex>
              <Flex>accountNumber,267690603</Flex>
              <Flex>accountNumber,277691609</Flex>
            </Flex>
          }
        >
          <span>
            <Icon
              name="InfoCircle"
              data-pho="encryptDecryptUploadinfo"
              size="small"
              margin={{ left: '.5rem' }}
            />
          </span>
        </Tooltip>
      </S.InstructionMessage>
      <p>
        <small style={{ color: colors.gray30 }}>
          *Note: There is a 500 row limit (excluding the header row) for each CSV upload
        </small>
      </p>
    </S.InstructionContainer>
  )
}
