import styled from 'styled-components'
import colors from '../bit/utils/colors'
import spacing from '../bit/utils/spacing'
import transition from '../bit/utils/transition'

interface ToolTipContainerProps {
  $width: number
  $height: number
}

interface TooltipStylingBaseProps {
  $isDarkMode: boolean
  $shouldDisplay: boolean
  $placement:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
}

interface TooltipStylingProps extends TooltipStylingBaseProps {
  $backgroundColor: string
  $top?: string
  $left?: string
  $right?: string
  $bottom?: string
  $transformX: string
  $transformY: string
  $borderLeft?: string
  $borderTop?: string
  $borderRight?: string
  $borderBottom?: string
  $tooltipLeft?: string
  $tooltipTop?: string
  $tooltipRight?: string
  $tooltipBottom?: string
  $tooltipTransformX?: string
  $tooltipTransformY?: string
}

export const TooltipContainer = styled.div<ToolTipContainerProps>`
  margin: 0;
  padding: 0;
  position: absolute;
  width: ${({ $width }) => `${$width}px`};
  height: ${({ $height }) => `${$height}px`};
`

export const Tooltip = styled.div<TooltipStylingProps>`
  position: absolute;
  z-index: 10000;
  background: ${({ $backgroundColor }) => $backgroundColor};
  opacity: ${({ $shouldDisplay }) => ($shouldDisplay ? '1' : '0')};
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: ${transition.timingFunctions.standard};
  color: ${colors.white};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  padding: ${spacing.element.xxs} ${spacing.element.sm};
  width: fit-content;
  min-width: 65px;
  max-width: 325px;
  ${({ $left }) => ($left ? `left: ${$left};` : '')};
  ${({ $top }) => ($top ? `top: ${$top};` : '')};
  ${({ $right }) => ($right ? `right: ${$right};` : '')};
  ${({ $bottom }) => ($bottom ? `bottom: ${$bottom};` : '')};
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  pointer-events: none;

  transform: translate(${({ $transformX }) => $transformX}, ${({ $transformY }) => $transformY});

  > * {
    z-index: 10000;
  }

  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    z-index: 9999;
    ${({ $borderLeft }) => ($borderLeft ? `border-left: ${$borderLeft};` : '')}
    ${({ $borderTop }) => ($borderTop ? `border-top: ${$borderTop};` : '')}
    ${({ $borderRight }) => ($borderRight ? `border-right: ${$borderRight};` : '')}
    ${({ $borderBottom }) => ($borderBottom ? `border-bottom: ${$borderBottom};` : '')}
    ${({ $tooltipTop }) => ($tooltipTop ? `top: ${$tooltipTop};` : '')}
    ${({ $tooltipBottom }) => ($tooltipBottom ? `bottom: ${$tooltipBottom};` : '')}
    ${({ $tooltipLeft }) => ($tooltipLeft ? `left: ${$tooltipLeft};` : '')}
    ${({ $tooltipRight }) => ($tooltipRight ? `right: ${$tooltipRight};` : '')}
    ${({ $tooltipTransformX, $tooltipTransformY }) =>
      $tooltipTransformX && $tooltipTransformY
        ? `transform: translate(
    ${$tooltipTransformX},
    ${$tooltipTransformY}
  );`
        : ''}
  }
`

export const StyledTooltip = styled(Tooltip).attrs((props: TooltipStylingBaseProps) => ({
  ...getPositioningStyles(props),
}))``

function getPositioningStyles(props: TooltipStylingBaseProps): TooltipStylingProps {
  const { $isDarkMode, $placement } = props
  const backgroundColor = $isDarkMode ? colors.darkBlue10 : colors.darkBlue30

  const defaultStyles = {
    ...props,
    $backgroundColor: backgroundColor,
  }

  const topStyles = {
    ...defaultStyles,
    $top: '-10px',
    $left: '50%',
    $transformX: '-50%',
    $transformY: '-100%',
    $borderLeft: '7px solid transparent',
    $borderTop: `7px solid ${backgroundColor}`,
    $borderRight: '7px solid transparent',
    $tooltipBottom: '2px',
    $tooltipLeft: '50%',
    $tooltipTransformX: '-50%',
    $tooltipTransformY: '100%',
  }

  const topStartStyles = {
    ...defaultStyles,
    $top: '-10px',
    $left: '5px',
    $transformX: '-100%',
    $transformY: '-100%',
    $borderLeft: '7px solid transparent',
    $borderTop: `7px solid ${backgroundColor}`,
    $borderRight: '7px solid transparent',
    $tooltipBottom: '2px',
    $tooltipRight: '4px',
    $tooltipTransformX: '0%',
    $tooltipTransformY: '100%',
  }

  const topEndStyles = {
    ...defaultStyles,
    $top: '-10px',
    $left: '90%',
    $transformX: '0%',
    $transformY: '-100%',
    $borderLeft: '7px solid transparent',
    $borderTop: `7px solid ${backgroundColor}`,
    $borderRight: '7px solid transparent',
    $tooltipBottom: '2px',
    $tooltipLeft: '4px',
    $tooltipTransformX: '0%',
    $tooltipTransformY: '100%',
  }

  const bottomStartStyles = {
    ...defaultStyles,
    $bottom: '-10px',
    $left: '5px',
    $transformX: '-100%',
    $transformY: '100%',
    $borderLeft: '7px solid transparent',
    $borderBottom: `7px solid ${backgroundColor}`,
    $borderRight: '7px solid transparent',
    $tooltipTop: '2px',
    $tooltipRight: '4px',
    $tooltipTransformX: '0%',
    $tooltipTransformY: '-100%',
  }
  const bottomStyles = {
    ...defaultStyles,
    $bottom: '-10px',
    $left: '50%',
    $transformX: '-50%',
    $transformY: '100%',
    $borderLeft: '7px solid transparent',
    $borderBottom: `7px solid ${backgroundColor}`,
    $borderRight: '7px solid transparent',
    $tooltipTop: '2px',
    $tooltipLeft: '50%',
    $tooltipTransformX: '-50%',
    $tooltipTransformY: '-100%',
  }
  const bottomEndStyles = {
    ...defaultStyles,
    $bottom: '-10px',
    $left: '90%',
    $transformX: '0%',
    $transformY: '100%',
    $borderLeft: '7px solid transparent',
    $borderBottom: `7px solid ${backgroundColor}`,
    $borderRight: '7px solid transparent',
    $tooltipTop: '2px',
    $tooltipLeft: '4px',
    $tooltipTransformX: '0%',
    $tooltipTransformY: '-100%',
  }
  const leftEndStyles = {
    ...defaultStyles,
    $bottom: '10px',
    $left: '-10px',
    $transformX: '-100%',
    $transformY: '100%',
    $borderTop: '7px solid transparent',
    $borderLeft: `7px solid ${backgroundColor}`,
    $borderBottom: '7px solid transparent',
    $tooltipTop: '-12px',
    $tooltipRight: '2px',
    $tooltipTransformX: '100%',
    $tooltipTransformY: '100%',
  }
  const leftStyles = {
    ...defaultStyles,
    $bottom: '50%',
    $left: '-10px',
    $transformX: '-100%',
    $transformY: '50%',
    $borderTop: '7px solid transparent',
    $borderLeft: `7px solid ${backgroundColor}`,
    $borderBottom: '7px solid transparent',
    $tooltipBottom: '50%',
    $tooltipRight: '2px',
    $tooltipTransformX: '100%',
    $tooltipTransformY: '50%',
  }
  const leftStartStyles = {
    ...defaultStyles,
    $bottom: '90%',
    $left: '-10px',
    $transformX: '-100%',
    $transformY: '0%',
    $borderTop: '7px solid transparent',
    $borderLeft: `7px solid ${backgroundColor}`,
    $borderBottom: '7px solid transparent',
    $tooltipBottom: '17px',
    $tooltipRight: '2px',
    $tooltipTransformX: '100%',
    $tooltipTransformY: '100%',
  }
  const rightEndStyles = {
    ...defaultStyles,
    $bottom: '10px',
    $right: '-10px',
    $transformX: '100%',
    $transformY: '100%',
    $borderTop: '7px solid transparent',
    $borderRight: `7px solid ${backgroundColor}`,
    $borderBottom: '7px solid transparent',
    $tooltipTop: '-12px',
    $tooltipLeft: '2px',
    $tooltipTransformX: '-100%',
    $tooltipTransformY: '100%',
  }
  const rightStyles = {
    ...defaultStyles,
    $bottom: '50%',
    $right: '-10px',
    $transformX: '100%',
    $transformY: '50%',
    $borderTop: '7px solid transparent',
    $borderRight: `7px solid ${backgroundColor}`,
    $borderBottom: '7px solid transparent',
    $tooltipBottom: '50%',
    $tooltipLeft: '2px',
    $tooltipTransformX: '-100%',
    $tooltipTransformY: '50%',
  }
  const rightStartStyles = {
    ...defaultStyles,
    $bottom: '90%',
    $right: '-10px',
    $transformX: '100%',
    $transformY: '0%',
    $borderTop: '7px solid transparent',
    $borderRight: `7px solid ${backgroundColor}`,
    $borderBottom: '7px solid transparent',
    $tooltipBottom: '17px',
    $tooltipLeft: '2px',
    $tooltipTransformX: '-100%',
    $tooltipTransformY: '100%',
  }

  switch ($placement) {
    case 'top-start':
      return topStartStyles
    case 'top-end':
      return topEndStyles
    case 'bottom-start':
      return bottomStartStyles
    case 'bottom':
      return bottomStyles
    case 'bottom-end':
      return bottomEndStyles
    case 'left-end':
      return leftEndStyles
    case 'left':
      return leftStyles
    case 'left-start':
      return leftStartStyles
    case 'right-end':
      return rightEndStyles
    case 'right':
      return rightStyles
    case 'right-start':
      return rightStartStyles
    default:
      return topStyles
  }
}
