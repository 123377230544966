import React from 'react'
import styled from 'styled-components'
import colors from '../bit/utils/colors'
import notFound from 'assets/notFound.svg'

/* STYLES */
const NotFoundContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 3rem;
`

const NotFoundImage = styled.img`
  margin-bottom: 3rem;
  height: 50%;
  max-height: 20rem;
`

const Title = styled.h1`
  margin-bottom: 1rem;
  letter-spacing: -0.3px;
`

const NotFoundText = styled.p`
  font-weight: 500;
  line-height: 1.63;
  letter-spacing: -0.3px;
  color: ${colors.gray30};
  max-width: 40rem;
  margin: 0;

  a {
    color: ${colors.blue10};
  }
`

const NotFoundImageSmall = styled(NotFoundImage)`
  margin-bottom: 2rem;
  max-height: 15rem;
`

const TitleSmall = styled.h3`
  margin-bottom: 0.5rem;
  letter-spacing: -0.3px;
`

const NotFoundTextSmall = styled(NotFoundText)`
  font-size: 0.875rem;
`

/* PRESENTATION */
export default function NotFound({
  title = 'Data in Superposition',
  text = (
    <>
      We couldn't find the data you were looking for. Verify your filter options and search terms
      are correct. If you continue to experience issues, contact us at{' '}
      <a href="mailto:DL-XGClientAnalytics-Tools@charter.com">
        DL-XGClientAnalytics-Tools@charter.com
      </a>
    </>
  ),
  className,
  isSmall,
}: {
  title?: string
  text?: React.ReactNode
  className?: string
  isSmall?: boolean
}) {
  if (isSmall) {
    return (
      <NotFoundContainer className={className}>
        <NotFoundImageSmall src={notFound} alt="not found" />
        <TitleSmall>{title}</TitleSmall>
        <NotFoundTextSmall>{text}</NotFoundTextSmall>
      </NotFoundContainer>
    )
  }
  return (
    <NotFoundContainer className={className}>
      <NotFoundImage src={notFound} alt="not found" />
      <Title>{title}</Title>
      <NotFoundText>{text}</NotFoundText>
    </NotFoundContainer>
  )
}
