import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import colors from 'components/shared/bit/utils/colors'

export const Title = styled.div`
  font-weight: bold;
  font-size: large;
  height: 1.5rem;
`

export const EditViewsContainer = styled.div`
  padding: 1rem;
`

export const StyledViewContainer = styled.div`
  margin-bottom: 1rem;
`

export const ViewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 20rem;
  font-weight: 500;
`

export const StyledTextField = styled(TextField)`
  margin-bottom: 2rem;
  && {
    width: 100%;
  }
`

export const ModalContentContainer = styled.div`
  min-width: 34.375rem;
  padding: 1rem;
`

export const ViewActions = styled.div`
  display: flex;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: -0.5rem;
`

export const ColumnDragContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid 1px ${colors.gray20};
  padding: 0 0.5rem;
  cursor: pointer;
`
