import styled, { css } from 'styled-components'

import colors from 'components/shared/bit/utils/colors'

export const TopChartContainer = styled.div`
  width: 100%;
`

export const TopChartRow = styled.div<{ showBold?: boolean; backgroundGreyOnHover?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 8px 8px;
  border-radius: 8px;
  ${props =>
    props.showBold &&
    css`
    background-color: #ebecee
    font-weight: 600;
  `};
  ${props =>
    props.backgroundGreyOnHover &&
    css`
      span:first-of-type {
        color: ${colors.blue20};
      }
      &:hover {
        background-color: #ebecee;
        cursor: pointer;
        && > span {
          color: ${colors.blue20};
        }
      }
    `};
`

export const TopChartStoryContainer = styled.div`
  width: 250px;
`

export const WideColumnTopChartStoryContainer = styled.div`
  width: 550px;
`

export const TopChartTitle = styled.span<{ textAlign: string; width: string }>`
  font-weight: 600;
  width: ${props => props.width};
  text-align: ${props => props.textAlign};
`

export const Cell = styled.span<{ textAlign: string; width: string }>`
  text-align: ${props => props.textAlign};
  width: ${props => props.width};
  overflow: hidden;
  text-overflow: ellipsis;
`
