import styled from 'styled-components'
import spacing, { SpacingElement } from '../utils/spacing'
import Flex from '../flex/Flex'

export interface Margin {
  top?: SpacingElement | string
  right?: SpacingElement | string
  bottom?: SpacingElement | string
  left?: SpacingElement | string
}

interface ContainerProps {
  $size: string
  $color: string
  $margin?: Margin
}

export const FlexContainer = styled(Flex)<ContainerProps>`
  font-size: ${({ $size }) => $size};
  line-height: 1;
  margin: ${({ $margin }) =>
    `${$margin?.top ? spacing.element[$margin.top] || $margin.top : 0} ${
      $margin?.right ? spacing.element[$margin.right] || $margin.right : 0
    } ${$margin?.bottom ? spacing.element[$margin.bottom] || $margin.bottom : 0} ${
      $margin?.left ? spacing.element[$margin.left] || $margin.left : 0
    }`};
  color: ${({ $color }) => $color};

  > svg {
    stroke-width: 0;
  }
`
