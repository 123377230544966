import styled from 'styled-components'
import { LoadingSpinner } from 'components/shared/LoadingSpinner'
import MuiFormLabel from '@material-ui/core/FormLabel'
import MuiFormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Option from 'components/shared/Option'
import colors from 'components/shared/utils/colors'

export const FormLabel = styled(MuiFormLabel)`
  && {
    font-size: 0.875rem;
    transform-origin: top left;
    transform: scale(0.75);
    margin-bottom: 0.5rem;
  }
`

export const InputLabel = styled(MuiFormControlLabel)`
  && {
    margin: 0;

    span:last-child {
      margin-bottom: 0;
      margin-left: 0.5rem;
    }

    .MuiTypography-root {
      font-size: 0.875rem;
    }
  }
`

export const SelectAllContainer = styled.div`
  display: flex;
  align-content: center;
`

export const SelectAllOption = styled(Option)`
  && {
    margin-bottom: 1.3rem;
  }
`

export const OptionGroupFormControl = styled(FormControl as any)`
  min-width: 12rem;
  padding-bottom: 1rem;
`

export const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-top: 0.5rem;
`

export const ErrorMessage = styled.div`
  color: ${colors.red10};
`

export const GroupComponent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`
