import styled, { css } from 'styled-components'
import colors from 'components/shared/bit/utils/colors'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { Button } from 'components/shared/Button'
import { HexColorPicker, HexColorInput } from 'react-colorful'

export const ErrorContainer = styled.div`
  text-align: center;
  color: ${colors.red10};
  padding: 0.5rem 0 1rem;
`

export const Label = styled.label`
  width: 100%;
  cursor: 'pointer';
`

export const ColorPicker = styled(HexColorPicker)`
  width: unset !important;
  padding: 10px;
`
export const ColorInput = styled(HexColorInput)`
  width: 80%;
  margin-left: 10%;
  margin-top: 0.75rem;
`

export const ColorActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`

export const Input = styled.input`
  opacity: 0;
  font-size: 0;
  position: absolute;
  cursor: 'pointer';
`
export const ColorButton = styled(Button)`
  width: 70px;
  margin: 0 10px;
`

export const Caret = styled(ArrowDropDownIcon)<{ open: boolean }>`
  transition: transform 1s;
  ${props =>
    props.open &&
    css`
      transform: rotate(180deg);
    `}
`

export const Span = styled.span`
  color: ${colors.blue10};
`
