import styled from 'styled-components'
import RadioButtonGroup from 'components/shared/bit/radio-button-group/RadioButtonGroup'
import { Card } from 'components/shared/Card'
import { Button } from 'components/shared/Button'
import { Checkbox } from 'components/shared/Checkbox'
import List from '@material-ui/core/List'
import TextField from '@material-ui/core/TextField'
import CallSplitIcon from '@material-ui/icons/CallSplit'
import Chip from '@material-ui/core/Chip'
import FormGroup from '@material-ui/core/FormGroup'
import colors from 'components/shared/bit/utils/colors'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { Switch } from 'components/shared/Switch'
import Flex from 'components/shared/flex/Flex'
import Spacing from 'components/shared/utils/spacing'
import { lighten } from 'polished'
import InputLabel from '@material-ui/core/InputLabel'
import Highlighter from 'react-highlight-words'

export const EditReviewsContainer = styled(Flex).attrs({ column: true })``

export const TabContainer = styled(Card)`
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 0.5rem;
  padding-bottom: 0;
`

export const TabContent = styled.div`
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

export const ListContainer = styled.div<{ $height: number }>`
  flex: 3 3 0;
`

export const StyledHighlightContent = styled.span`
  color: ${colors.black};
  font-weight: 700;
`

export const StyledHighlightWrapper = styled.span`
  background-color: ${colors.yellow10};
`

export const FeedbackCardArea = styled.div`
  flex: 9 9 0;
`

export const StyledList = styled(List)`
  padding: 0rem;
  overflow: auto;
`

export const StyledFeedbackList = styled(List)`
  flex: 1 1 0;
  padding: 0rem;
  overflow-x: hidden;
  overflow-wrap: anywhere;
`

export const RerouteIcon = styled(CallSplitIcon)`
  color: ${colors.orange};
`

export const StyledListItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 6rem;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
`

export const StyledListFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75em;
  padding: 1rem;
  border-top: solid 1px ${colors.gray20};
`

export const ListItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const ShowIcon = styled(VisibilityIcon)`
  margin-right: 0.5rem;
`

export const HideIcon = styled(VisibilityOffIcon)`
  margin-right: 0.5rem;
`

export const IsHiddenIcon = styled(VisibilityOffIcon)`
  margin-left: 1rem;
`

export const Awscategories = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 0.75em;
`

export const ListItemContent = styled.div``

export const StyledHighlighter = styled(Highlighter)`
  line-break: normal !important;
`

export const ListItemDate = styled.div`
  font-size: 0.75em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ButtonDiv = styled.div`
  margin-right: 1rem;
`

export const LoadingSpinner = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: center;
  padding: 1rem;
  color: ${colors.black};
`

export const LoadingMessage = styled.div`
  margin-left: 1rem;
  font-size: 0.75rem;
`

export const TabSubheader = styled.div`
  display: flex;
  justify-content: space-between;
`
export const TitleDiv = styled.div`
  text-align: center;
  width: 30%;
`

export const SearchBarDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  flex: 0;
`

export const FeedbackCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: ${Spacing.element.lg};
  padding-top: ${Spacing.element.xs};
  min-height: 20rem;
  overflow-wrap: anywhere;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`

export const FeedbackCardHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-flow: row nowrap;
`

export const SubHeaderInfo = styled.div`
  display: flex;
  width: 70%;
  justify-content: flex-start;
  flex-flow: column wrap;
`

export const SubHeaderActions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
`

export const FeedbackCardTimestamp = styled.div`
  padding-top: 12px;
`

export const ContentDiv = styled.div`
  flex: 1 1 0;
`

export const FeedbackHeading = styled(Flex)`
  flex-grow: 1;
  > * {
    margin-right: 1rem;
    margin-bottom: 0;
  }
`

export const VerbatimDiv = styled.div`
  font-size: 16px;
  line-height: 1.6em;
`
export const AltVerbatimDiv = styled.div`
  margin-top: 1rem;
  font-size: 16px;
  line-height: 1.6em;
`

export const VerbatimLabel = styled.span`
  font-size: 16 px;
  line-height: 1.6em;
  font-weight: bold;
`

export const FeedbackStats = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`
export const FeedbackStat = styled.div`
  padding: 1.5rem 1.5rem 0 0;
`

export const CategoriesInput = styled.div`
  display: flex;
`

export const InputContainer = styled.div`
  width: 100%;
`
export const ButtonCorral = styled.div`
  display: flex;
  flex-grow: 1;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 1rem 0 0 0;
`

export const NavigationButtons = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin-right: 1rem;
`

export const CategorySelector = styled.div`
  padding: 1rem 1rem 0px 1rem;
`

export const FeedbackCardError = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.08);
  padding: 1rem;
  min-height: 300px;
`

export const VisitIDContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`

export const CategoryChip = styled(Chip)<{ isMiddleCategory?: boolean }>`
  margin-right: 0.5rem;
  height: 1.25rem;
  background-color: ${({ isMiddleCategory }) => (isMiddleCategory ? '#999999' : colors.blue10)};
  font-weight: 500;
  color: white;
  > span {
    padding: 0px 10px;
  }
`

export const SaveButtonContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  padding-right: 1rem;
`

export const NotesInputContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 0 1.2rem;
`

export const NotesInputContent = styled.div`
  width: 100%;
`

export const NotesInputTextField = styled(TextField)`
  width: 100%;
  margin-bottom: 0.5rem;
`

export const NotesInputHeader = styled.div`
  color: ${colors.blue10};
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`
// export const NotificationContainer = styled.div`
//   padding: 1rem 0 0 1rem;
//   min-height: 4.5rem;
// `

export const NotificationBox = styled.div`
  font-weight: 500;
  color: ${colors.orange};
  padding: 0 0 0.5rem 0;
`

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`

export const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: row;
`

export const ViewMoreButton = styled(Button)`
  border-radius: 0;
  border-color: #ccc;
  margin-right: -21px;
  margin-top: -18px;
`

export const StyledSwitch = styled(Switch)`
  margin-right: 1.5rem;
`

export const StyledTab = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

export const StyledIndividualCheckbox = styled(({ ...props }) => <Checkbox {...props} />)`
  margin: 0.5rem;
  opacity: ${props => (props.checked ? 1 : 0)};
  /* For checkbox to not stay selected */
  pointer-events: none;

  &:hover {
    opacity: 1;
  }
`

export const FeedbackItem = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  background: ${props => (props.selected ? colors.gray20 : 'none')};

  &:hover {
    background: ${props => (props.selected ? colors.gray20 : colors.gray10)};
    cursor: pointer;

    &:hover > div > div {
      opacity: 1;
    }
  }
`

export const MultiEditOptions = styled.div`
  padding: 1rem 1rem 0 1rem;
  display: flex;
  justify-content: space-between;
`

export const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`

export const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  border: none;
  padding: 0 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`

export const StyledRadioButton = styled(Button)`
  margin-right: 0.5rem;
`

export const MultiEditContainer = styled.div`
  min-height: 40rem;
`

export const NothingSelectedMessage = styled.div`
  display: flex;
  min-height: 40rem;
  align-items: center;
  justify-content: center;
`

export const SpinnerContainer = styled.div`
  min-height: 40rem;
  display: flex;
  align-items: center;
`

export const CheckboxContainer = styled.div<{ selected?: boolean }>`
  margin: 0 0.1rem;
  border-radius: 100%;
  &:hover {
    background: ${props =>
      props.selected ? lighten(0.3, colors.gray30) : lighten(0.4, colors.gray30)};

    &:hover > div {
      opacity: 1;
    }
  }
`

export const SelectAllContainer = styled.div`
  min-width: 1.75rem;
  max-width: 1.75rem;
  padding-right: 0.5rem;
`
export const CategorySelectContainer = styled.div`
  text-align: start;
  && > div {
    width: 100%;
  }
`
export const InputLabelContainerHeader = styled(InputLabel)`
  margin-bottom: 2rem;
  text-align: start;
  && > div {
    width: 100%;
  }
  font-size: larger;
`
export const MiddleLevelCategorySelectContainerModel = styled.div`
  margin-top: 2rem;
  text-align: start;
  display: flex;
  flex-direction: row;
`
export const ItemContainer = styled.div`
  width: 100%;
  display: inline-block;
`

export const InputLabelContainer = styled(InputLabel)`
  color: ${colors.black};
  margin-bottom: 0.5rem;
`
export const ModalFieldLabelMiddleLevel = styled.div`
  text-align: center;
  margin: 0 auto;
  width: 35rem;
`
export const ModelError = styled(InputLabel)`
  margin-top: 10px;
  color: ${colors.red10};
`
export const MiddleLevelCategorySelectContainer = styled.div`
  margin-top: 1.4rem;
  text-align: start;
  && > div {
    width: 100%;
  }
`
export const styledDropdown = styled.div`
  padding: 20px;
`
