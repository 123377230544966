import React from 'react'
import pascalcase from 'pascalcase'
import { camelCase } from 'lodash'
import * as icons from './icons'
import * as S from './styles'
import { useDarkMode } from '../dark-mode'
import colors, { Color } from '../utils/colors'

export type IconName = keyof typeof icons

export interface IconProps extends React.PropsWithoutRef<JSX.IntrinsicElements['span']> {
  /** One of the title cased Kite Icon names. (ie: AnnouncementF). */
  name: IconName
  /** A named size which corresponds to Kite's iconography scale or a CSS size. */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | string
  /** Either a Kite color name or a CSS color. If a color is not provided, one will be chosen based on whether there is dark mode present or not. */
  color?: string
  /** A handy utility to add margin to your icon's top, right, bottom, or left, based on Kite spacing. (ie: margin={{top: 'xs'}}). */
  margin?: S.Margin
}

export const sizes = {
  xxs: '12px',
  xs: '14px',
  sm: '16px',
  md: '20px',
  lg: '24px',
  xl: '32px',
}

export default ({ name, size = 'md', color, margin, ...props }: IconProps) => {
  const { isDarkMode } = useDarkMode()
  const IconComponent = icons[pascalcase(name) as IconName]
  const kiteColor = colors[camelCase(color) as Color] as string | undefined
  const modeColor = isDarkMode ? colors.darkMode.text : colors.lightMode.text
  const pixelSize = sizes[size] || size
  const dataTestID = props['data-testid'] || name

  return (
    <S.FlexContainer
      inline
      center
      width={pixelSize}
      height={pixelSize}
      $size={pixelSize}
      $color={kiteColor || color || modeColor}
      $margin={margin}
      {...props}
      data-testid={dataTestID}
    >
      <IconComponent />
    </S.FlexContainer>
  )
}
