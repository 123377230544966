import React, { useEffect, useState } from 'react'
import { endOfToday, isFuture, isValid } from 'date-fns'
import { Switch } from 'components/shared/Switch'
import Typography from 'components/shared/typography/Typography'
import { TextInput } from 'components/shared/TextInput'
import { Button } from 'components/shared/Button'
import Flex from 'components/shared/flex/Flex'
import { Dropdown } from 'components/shared/Dropdown'
import { Slider } from 'components/shared/Slider'
import { Accordion } from 'components/shared/Accordion'
import colors from 'components/shared/bit/utils/colors'
import Tooltip from 'components/shared/Tooltip'
import Icon from 'components/shared/icon/Icon'
import Alert from 'components/shared/Alert'
import spacing from 'components/shared/utils/spacing'
import * as S from './styles'
import * as U from './utils'
import * as _ from 'lodash'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import useApps from 'hooks/useApps'
import useGroups from 'hooks/useGroups'
import { times } from 'utils'
import moment from 'moment'
import * as F from '../VoCTimeMenu/utils'
import { useLocation } from '@reach/router'
import { useDarkMode } from 'components/shared/dark-mode'

const HEIGHT_DIFFERENCE_BETWEEN_LONG_AND_SHORT_TIME_MENU = 190

export interface TimeMenuProps {
  /** Value of the component for a controlled state */
  value?: Partial<U.State>
  /** Initial time range to display (ie: "7d" or {start: 1619848800000, end: 1626790441851}) */
  initialRange?: U.Range
  // /** The minimum duration a range can have (ie: "60s" or "1m") */
  // minDuration?: string
  // /** The maximum duration a range can have (ie: "7d" or "1w") */
  // maxDuration?: string
  // /** The time "bucketing" options for aggregation (ie: "1m" or "2d") */
  // stepOptions?: string[]
  /** Initial step for time aggregations (ie: "1d") */
  initialStep?: number
  /** Initial UTC offset (defaults to 0, which is GMT) */
  initialUtcOffset?: U.UtcOffset
  /** Initial Partial Data (default to false) */
  initialPartialData?: boolean
  /** Initial Fiscal Time (default to false) */
  initialFiscalTime?: boolean
  /** Minimum date that the user is allowed to choose; Currently doesn't account for UTC offset */
  minDate?: Date
  /** Maximum date that the user is allowed to choose; Currently doesn't account for UTC offset */
  maxDate?: Date
  // /** Triggered when a selection is made in the Time Menu */
  // onChange?: (arg: { range: Range; step?: string }) => void
  /** Should block future start dates? (for viewing past data, for example) */
  shouldBlockFutureStarts?: boolean
  /** Should block past start dates? (for scheduling future events, for example) */
  shouldBlockPastStarts?: boolean
  /** Change handler for the component for a controlled state */
  onChange?: (selected: U.State) => void
  /** Used to override the default static range options */
  staticRanges?: Array<any>
  /** Used to override the default UTC offset options */
  utcOffsetOptions?: Array<any>
  /** Used to enable fiscal time steps */
  enableFiscalTimeSteps?: boolean
  isSSPP?: boolean
  stepOptions?: any
  filters?: any
}

export default ({
  initialRange = '-7d',
  initialStep = U.DAY_IN_MILLISECONDS,
  initialPartialData = false,
  initialFiscalTime = false,
  initialUtcOffset,
  shouldBlockFutureStarts = false,
  shouldBlockPastStarts = false,
  onChange,
  value,
  minDate,
  maxDate = endOfToday(),
  staticRanges: staticRangesProp,
  utcOffsetOptions: utcOffsetOptionsProp,
  stepOptions = {},
  enableFiscalTimeSteps = false,
  isSSPP,
  filters = F.defaultFilters,
  ...props
}: TimeMenuProps) => {
  const menuRef = React.useRef<HTMLDivElement>(null)
  const menuModalRef = React.useRef<HTMLDivElement>(null)
  const dataTestID = props['data-testid'] || 'time-menu'
  const { isDarkMode } = useDarkMode()
  const [isOpen, setIsOpen] = React.useState(false)

  const [isSpecGuide, setIsSpecGuide] = useState<boolean>(false)
  const [isOneApp, setIsOneApp] = useState<boolean>(false)
  const { currentApps, app } = useApps()
  const { currentGroup } = useGroups()
  const [error, setError] = useState<Nullable<string>>(null)
  const [isClientAnalytics, setIsClientAnalytics] = useState(false)
  const [isApiMappings, setIsApiMappings] = useState(false)
  const location = useLocation()
  const initialState = React.useMemo(
    () => ({
      range: value?.range || initialRange,
      step: value?.step || initialStep,
      utcOffset: value?.utcOffset || initialUtcOffset || U.getClientUtcOffset(),
      isPartialData: value?.isPartialData || initialPartialData,
      isFiscalTime: value?.isFiscalTime || initialFiscalTime,
      stepOptions:
        value?.stepOptions || isSSPP
          ? U.customStepOptions(initialRange, enableFiscalTimeSteps)
          : U.getStepOptions(initialRange, enableFiscalTimeSteps),
    }),
    []
  )

  const defaultState = React.useMemo(
    () => ({
      range: initialRange,
      step: initialStep,
      utcOffset: initialUtcOffset || U.getClientUtcOffset(),
      isPartialData: initialPartialData,
      isFiscalTime: initialFiscalTime,
      stepOptions: U.getStepOptions(initialRange, enableFiscalTimeSteps),
    }),
    []
  )

  const [applied, setApplied] = React.useState<U.State>(initialState)
  const [selected, setSelected] = React.useState<U.State>(initialState)
  const [isLong, setIsLong] = React.useState(true)
  const [isScrollable, setIsScrollable] = React.useState(false)
  const [height, setHeight] = React.useState(0)
  const [showAlert, setShowAlert] = React.useState(false)

  const hasUnapplied = React.useMemo(
    () =>
      selected.range !== (value || applied).range ||
      selected.step !== (value || applied).step ||
      selected.utcOffset !== (value || applied).utcOffset ||
      selected.isPartialData !== (value || applied).isPartialData ||
      selected.isFiscalTime !== (value || applied).isFiscalTime,
    [value, applied, selected]
  )
  const hasChanged = React.useMemo(
    () =>
      selected.range !== defaultState.range ||
      selected.step !== defaultState.step ||
      selected.utcOffset !== defaultState.utcOffset ||
      selected.isPartialData !== defaultState.isPartialData ||
      selected.isFiscalTime !== defaultState.isFiscalTime,
    [selected, defaultState]
  )
  const staticRanges = React.useMemo(
    () =>
      staticRangesProp || isSSPP
        ? U.customDefaultStaticRanges(selected)
        : U.defaultStaticRanges(selected),
    [staticRangesProp, selected]
  )
  const utcOffsetOptions = React.useMemo(
    () => utcOffsetOptionsProp || U.utcOffsetOptions,
    [utcOffsetOptionsProp]
  )
  const defaultInputRanges: Array<string> = []

  const toggleOpen = React.useCallback(() => {
    setIsOpen(state => !state)
  }, [setIsOpen])

  const handleClickOutside = React.useCallback(
    event => {
      const didClickOutsideMenu = menuRef.current && !menuRef.current.contains(event.target)
      if (isOpen && didClickOutsideMenu) {
        setIsOpen(false)
        setShowAlert(false)
      }
    },
    [isOpen, setIsOpen, setShowAlert, menuRef.current]
  )

  const handleSelectDateRange = React.useCallback(
    (range: any) => {
      const selection = range['selection'] as any
      if (selection) {
        const isAbsolute = selection.key === 'selection'
        setSelected(selected =>
          isAbsolute
            ? {
                ...selected,
                range: {
                  startMs: selection.startDate.valueOf(),
                  endMs: selection.endDate.valueOf(),
                },
                stepOptions: U.getStepOptions(selection, enableFiscalTimeSteps),
              }
            : {
                ...selected,
                range: selection.key,
                stepOptions: U.getStepOptions(selection.key, enableFiscalTimeSteps),
              }
        )
      }
    },
    [setSelected]
  )

  const handleStepChange = React.useCallback(
    value => {
      setSelected(selected => {
        if (value.label.includes('fiscal')) {
          return { ...selected, step: value.step, isFiscalTime: true }
        }
        return { ...selected, step: value.step, isFiscalTime: false }
      })
    },
    [setSelected]
  )

  const handlePartialSwitch = React.useCallback(
    (newState: boolean) => {
      setSelected(selected => {
        return { ...selected, isPartialData: !newState }
      })
    },
    [setSelected]
  )

  const handleUtcOffsetChange = React.useCallback(
    (value: any) => {
      setSelected(selected => {
        return { ...selected, utcOffset: value }
      })
    },
    [setSelected]
  )

  const handleReset = React.useCallback(
    (defaultState: U.State, hasChanged: boolean) => () => {
      if (hasChanged) {
        setApplied({
          ...defaultState,
          period: transformToPositivePeriod(defaultState) as string | undefined,
        })

        setSelected({
          ...defaultState,
          period: transformToPositivePeriod(defaultState) as string | undefined,
        })
        setIsOpen(false)
        setShowAlert(false)
        onChange &&
          onChange({
            ...defaultState,
            period: transformToPositivePeriod(defaultState) as string | undefined,
          })
      }
    },
    [setSelected, setApplied, setIsOpen, setShowAlert, onChange, value]
  )

  const handleCancel = React.useCallback(
    (applied: U.State) => () => {
      setSelected(applied)
      setIsOpen(false)
      setShowAlert(false)
    },
    [setSelected, setIsOpen, setShowAlert, value]
  )

  const handleApply = React.useCallback(
    (selected: U.State, hasChanged: boolean) => () => {
      if (hasChanged) {
        setApplied(selected)
        setSelected(selected)
        selected.period = transformToPositivePeriod(selected) as string | undefined
        setIsOpen(false)
        setShowAlert(false)
        onChange && onChange(selected)
      }
    },
    [setApplied, setIsOpen, setShowAlert, onChange, value]
  )

  const getButtonLabelProps = React.useCallback(() => {
    if (value?.range && value?.step) {
      return {
        range: value.range,
        step: value.step,
      }
    }
    if (applied?.range && applied?.step) {
      return {
        range: applied.range,
        step: applied.step,
      }
    }
    return {
      range: initialRange,
      step: initialStep,
    }
  }, [value, applied, initialRange, initialStep])

  function handleResize(): void {
    const viewportHeight = document.documentElement.clientHeight
    const bottomOfTimeMenu: any = menuModalRef?.current?.getBoundingClientRect()?.bottom
    const topOfTimeMenu: any = menuModalRef?.current?.getBoundingClientRect()?.top

    if (viewportHeight < bottomOfTimeMenu && isLong) setIsLong(false)
    if (viewportHeight < bottomOfTimeMenu && !isLong) {
      setIsScrollable(true)
      setHeight(viewportHeight - topOfTimeMenu)
    }
    if (viewportHeight > bottomOfTimeMenu) {
      setIsScrollable(false)
      setHeight(undefined as any)
    }
    if (viewportHeight > bottomOfTimeMenu + HEIGHT_DIFFERENCE_BETWEEN_LONG_AND_SHORT_TIME_MENU) {
      setIsLong(true)
      setIsScrollable(false)
      setHeight(undefined as any)
    }
  }

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, { capture: true })
    return () =>
      document.removeEventListener('click', handleClickOutside, {
        capture: true,
      })
  }, [menuRef.current])

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [isLong])

  React.useEffect(() => {
    handleResize()
  }, [isOpen])

  const { startDate, endDate } = U.convertToDateRange(selected.range || initialRange)

  const isDateRangeValid = (range: any) => {
    const selection = range['selection'] as any
    if (selection) {
      const isAbsolute = selection.key === 'selection'
      if (isAbsolute && selection.endDate && selection.startDate) {
        if (selection.endDate < selection.startDate) {
          return false
        } else {
          return true
        }
      }
    }
    return false
  }

  const handleStartDateChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedDate = event.target.value !== '' ? event.target.value : U.getISODate(new Date())

      const selectedTime = isValid(startDate) ? U.getISOTime(startDate) : U.getISOTime(new Date())

      const newStartDate = `${selectedDate}T${selectedTime}`
      const jsStartDate = new Date(newStartDate)

      const range = {
        selection: {
          startDate: isFuture(jsStartDate) ? new Date() : jsStartDate,
          endDate,
          key: 'selection',
        },
      } as any

      if (isDateRangeValid(range)) {
        setShowAlert(false)
        handleSelectDateRange(range)
      } else {
        setShowAlert(true)
      }
    },
    [handleSelectDateRange, startDate, endDate]
  )

  const handleEndDateChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedDate = event.target.value !== '' ? event.target.value : U.getISODate(new Date())

      const selectedTime = isValid(endDate) ? U.getISOTime(endDate) : U.getISOTime(new Date())

      const newEndDate = `${selectedDate}T${selectedTime}`
      const jsEndDate = new Date(newEndDate)

      const range = {
        selection: {
          startDate,
          endDate: isFuture(jsEndDate) ? new Date() : jsEndDate,
          key: 'selection',
        },
      } as any

      if (isDateRangeValid(range)) {
        setShowAlert(false)
        handleSelectDateRange(range)
      } else {
        setShowAlert(true)
      }
    },
    [handleSelectDateRange, startDate, endDate]
  )

  const handleStartTimeChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const formattedPreviousTime = U.getISOTime(startDate)
      const previousMinutes = formattedPreviousTime.split(':')[1]

      const timeToSet = _.isEmpty(event.target.value) ? `00:${previousMinutes}` : event.target.value

      const newStartDate = `${U.getISODate(startDate)}T${timeToSet}`
      const jsStartDate = new Date(newStartDate)

      const range = {
        selection: {
          startDate: isFuture(jsStartDate) ? new Date() : jsStartDate,
          endDate,
          key: 'selection',
        },
      } as any

      if (isDateRangeValid(range)) {
        setShowAlert(false)
        handleSelectDateRange(range)
      } else {
        setShowAlert(true)
      }
    },
    [handleSelectDateRange, startDate, endDate]
  )

  const handleEndTimeChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const formattedPreviousTime = U.getISOTime(endDate)
      const previousMinutes = formattedPreviousTime.split(':')[1]

      const timeToSet = _.isEmpty(event.target.value) ? `00:${previousMinutes}` : event.target.value

      const newEndDate = `${U.getISODate(endDate)}T${timeToSet}`
      const jsEndDate = new Date(newEndDate)

      const range = {
        selection: {
          startDate,
          endDate: isFuture(jsEndDate) ? new Date() : jsEndDate,
          key: 'selection',
        },
      } as any

      if (isDateRangeValid(range)) {
        setShowAlert(false)
        handleSelectDateRange(range)
      } else {
        setShowAlert(true)
      }
    },
    [handleSelectDateRange, startDate, endDate]
  )

  const todayGMT = moment().endOf('day').valueOf()
  const todayUTC = moment.utc().endOf('day').valueOf()

  const errorSetter = (days: string) => {
    if (currentGroup?.name) {
      setError(
        `The data for group "${currentGroup.name}" is subject to a ${days}-day retention policy.`
      )
    } else {
      setError(
        `The data for application "${app?.name}" is subject to a ${days}-day retention policy`
      )
    }
  }

  const specGuideOrOneApp = (application: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (location.pathname.includes('experiment-snapshot')) {
      location.pathname.includes('details') ? application(true) : application(false)
    } else {
      application(true)
    }
  }

  useEffect(() => {
    if (currentApps?.some(app => app.toLowerCase().includes('specguide'))) {
      specGuideOrOneApp(setIsSpecGuide)
    } else if (currentApps?.some(app => app.toLowerCase().includes('oneapp'))) {
      specGuideOrOneApp(setIsOneApp)
    }
    if (location.pathname.includes('/client-analytics')) {
      setIsClientAnalytics(true)
    } else {
      setIsClientAnalytics(false)
    }
    if (location.pathname.includes('/quantum-resources/api-name-mappings/')) {
      setIsApiMappings(true)
    } else {
      setIsApiMappings(false)
    }
  }, [location.pathname, filters])

  const handleDateRangePicker = React.useCallback(
    (range: any) => {
      const { startDate, endDate } = range.selection
      if (startDate && endDate) {
        const [fromDateVal, toDateVal] =
          endDate < startDate ? [endDate, startDate] : [startDate, endDate]
        const dateFrom = times.getTimestampDayStart(moment(fromDateVal).valueOf())
        const dateTo = times.getTimestampDayEnd(moment(toDateVal).valueOf())
        const fifteenDays = new Date().setDate(new Date().getDate() - 16)
        const twentyOneDays = new Date().setDate(new Date().getDate() - 22)

        if ((dateTo as number) > todayGMT && (dateTo as number) > todayUTC) {
          setError('You cannot pick a date after today.')
        } else if (isSpecGuide && dateFrom < fifteenDays) {
          errorSetter('15')
        } else if (isOneApp && dateFrom < twentyOneDays) {
          errorSetter('21')
        } else if (isOneApp || isSpecGuide) {
          setError(null)
        }
      }

      const selection = range['selection'] as any
      const type = range['selection']['type']
      if (selection) {
        const isAbsolute = selection.key === 'selection'
        const isStaticAbsolute = type === 'staticAbsolute'
        const defaultStartTime = isStaticAbsolute ? undefined : '00:00'
        const defaultEndTime = isStaticAbsolute ? undefined : '23:59'

        const newStartDate = `${U.getISODate(selection.startDate)}T${U.getISOTime(
          selection.startDate,
          defaultStartTime
        )}`
        const newEndDate = `${U.getISODate(selection.endDate)}T${U.getISOTime(
          selection.endDate,
          defaultEndTime
        )}`

        return isAbsolute
          ? handleSelectDateRange({
              selection: {
                startDate: new Date(newStartDate),
                endDate: new Date(newEndDate),
                key: 'selection',
              },
            })
          : handleSelectDateRange(range)
      }
    },
    [handleSelectDateRange, startDate, endDate]
  )

  function getCurrentStep() {
    const fiscalStepExists = selected?.stepOptions?.some(
      stepOption => stepOption.step === selected.step && stepOption.label.includes('fiscal')
    )
    const matchedStep = selected?.stepOptions?.find(stepOption => {
      if (selected.isFiscalTime && fiscalStepExists) {
        return stepOption.step === selected.step && stepOption.label.includes('fiscal')
      }
      return stepOption.step === selected.step
    })

    const stepValue = matchedStep
      ? matchedStep.value
      : selected.stepOptions
      ? selected.stepOptions[0].value
      : ''

    const activeStep = selected?.stepOptions?.filter(option => option.value === stepValue)?.[0]
      ?.step
    if (activeStep && selected?.step !== activeStep) {
      setSelected(selected => {
        return { ...selected, step: activeStep }
      })
    }
    return stepValue
  }

  const currentRecentPeriod = JSON.parse(window.localStorage.getItem('recentPeriods') as any) || []

  const filterCurrentRecentPeriods = currentRecentPeriod.filter(
    (item: any) => item !== 'last3Days' && item !== 'last2Days'
  )

  const currentRecentPeriods = isSSPP ? currentRecentPeriod : filterCurrentRecentPeriods

  function handleStaticRangeClick(rangeObj: U.RangeObj, selectedRangeKey: string) {
    const selectedRange = rangeObj.range()
    const isCurrentRangeRecent = currentRecentPeriods.includes(selectedRangeKey)

    if (!isCurrentRangeRecent) {
      const newRecentRangesTo = [selectedRangeKey, ...currentRecentPeriods]
      const recentRangesToSet =
        newRecentRangesTo.length > 3 ? _.dropRight(newRecentRangesTo, 1) : newRecentRangesTo

      window.localStorage.setItem('recentPeriods', JSON.stringify(recentRangesToSet))
    }

    const isRelative = selectedRange?.key

    const formattedSelection = isRelative
      ? { selection: selectedRange }
      : { selection: { key: 'selection', ...selectedRange } }
    handleDateRangePicker(formattedSelection)
  }

  const recentRanges = {
    label: 'Recently Selected',
    sectionRanges: currentRecentPeriods.reduce((acc: U.RangeObj[], rangeKey: string) => {
      const range = U.ranges[rangeKey]
      return _.isNil(range) ? acc : acc.concat(range)
    }, []),
  }

  const quickSelectionRanges = [recentRanges, ...staticRanges]

  return (
    <S.MenuContainer ref={menuRef}>
      <Button
        data-testid={`${dataTestID}-button`}
        variant="borderless"
        icon="Reminder"
        onClick={toggleOpen}
      >
        {U.constructButtonLabel(getButtonLabelProps())}
      </Button>
      {isOpen && (
        <S.Menu
          data-testid={dataTestID}
          $isDarkMode={isDarkMode}
          ref={menuModalRef}
          key={`timeMenuModal${isLong}`}
          $height={height}
          $isScrollable={isScrollable}
        >
          <Flex
            justifyContent="flex-end"
            padding="sm"
            marginBetween="sm"
            style={{ borderTop: `1px solid ${colors.gray20}` }}
            data-test={`${dataTestID}-top-buttons-container`}
          >
            {!isClientAnalytics && (
              <Button
                disabled={!hasChanged}
                data-testid={`${dataTestID}-reset-button`}
                variant="borderless"
                onClick={handleReset(defaultState, hasChanged)}
              >
                Reset
              </Button>
            )}
            <Button
              data-testid={`${dataTestID}-cancel-button`}
              variant="secondary"
              onClick={handleCancel(applied)}
            >
              Cancel
            </Button>
            <Button
              disabled={error != null || !hasUnapplied}
              data-testid={`${dataTestID}-apply-button`}
              variant="primary"
              onClick={handleApply(selected, hasUnapplied)}
            >
              Apply
            </Button>
          </Flex>
          <Flex direction="row">
            <S.AccordionContainer>
              <Accordion
                asList
                width="15rem"
                initialExpandedIndex={0}
                panels={
                  quickSelectionRanges.map(staticRange => {
                    const isRecentRanges = staticRange.label === 'Recently Selected'
                    const areRangesEmpty = _.isEmpty(staticRange.sectionRanges)
                    return {
                      title: staticRange.label,
                      content: (
                        <Flex direction="column" style={{ cursor: 'default' }}>
                          {isRecentRanges && areRangesEmpty ? (
                            <Typography noMargin>
                              <p>No Recent Ranges Available</p>
                            </Typography>
                          ) : (
                            staticRange.sectionRanges.map((range: any) => (
                              <S.Range
                                isDarkMode={isDarkMode}
                                onClick={() => handleStaticRangeClick(range, range.rangeKey)}
                              >
                                <Typography noMargin>
                                  <p>{range.label}</p>
                                </Typography>
                              </S.Range>
                            ))
                          )}
                        </Flex>
                      ),
                    }
                  }) as any
                }
              />
            </S.AccordionContainer>
            <div>
              <Flex direction="row" padding="sm" marginBetween="sm" justifyContent={'center'}>
                <S.DateRangePicker
                  ranges={[
                    {
                      startDate,
                      endDate,
                      key: 'selection',
                    },
                  ]}
                  onChange={handleDateRangePicker}
                  staticRanges={[]}
                  inputRanges={defaultInputRanges as any}
                  minDate={minDate}
                  maxDate={maxDate}
                  $hideStaticRangesSection={true}
                  $isDarkMode={isDarkMode}
                />
              </Flex>
              <Flex
                direction={isLong ? 'column' : 'row'}
                padding="sm"
                marginBetween={isLong ? 'sm' : 'xxxl'}
                style={{ borderTop: `1px solid ${colors.gray20}` }}
              >
                <div>
                  {error && <S.ErrorMessage>{error}</S.ErrorMessage>}
                  {showAlert && (
                    <S.DateRangeAlert>
                      <Alert variant="page-error" isPersistent>
                        End Time must be greater than Start Time.
                      </Alert>
                    </S.DateRangeAlert>
                  )}
                  {!isClientAnalytics && !isApiMappings && (
                    <>
                      <Typography>Start Time</Typography>
                      <Flex direction="row" marginBetween="sm">
                        <TextInput
                          label="Start Date"
                          hideLabel
                          type="date"
                          error="error message"
                          width="11rem"
                          value={U.getISODate(startDate)}
                          onChange={handleStartDateChange}
                        />
                        <TextInput
                          label="Start Time"
                          hideLabel
                          type="time"
                          error="error message"
                          width="11rem"
                          value={U.getISOTime(startDate)}
                          onChange={handleStartTimeChange}
                        />
                      </Flex>
                    </>
                  )}
                </div>
                {!isClientAnalytics && !isApiMappings && (
                  <div>
                    <Typography>End Time</Typography>
                    <Flex direction="row" marginBetween="sm">
                      <TextInput
                        label="End Date"
                        hideLabel
                        type="date"
                        error="error message"
                        width="11rem"
                        value={U.getISODate(endDate)}
                        onChange={handleEndDateChange}
                      />
                      <TextInput
                        label="End Time"
                        hideLabel
                        type="time"
                        error="error message"
                        width="11rem"
                        value={U.getISOTime(endDate)}
                        onChange={handleEndTimeChange}
                      />
                    </Flex>
                  </div>
                )}
              </Flex>
              {!isClientAnalytics && !isApiMappings && utcOffsetOptions.length ? (
                <Flex
                  direction="row"
                  justifyContent="right"
                  padding={isLong ? 'sm' : `0 ${spacing.element.sm} ${spacing.element.sm}`}
                >
                  <Dropdown
                    data-testid={`${dataTestID}-time-zone-dropdown`}
                    label="Time Zone"
                    isInline
                    defaultSelected={selected.utcOffset}
                    items={utcOffsetOptions}
                    handleChange={handleUtcOffsetChange}
                  />
                </Flex>
              ) : null}
            </div>
          </Flex>
          <Flex
            direction={isLong ? 'column' : 'row'}
            padding="sm"
            marginBetween="sm"
            style={{ borderTop: `1px solid ${colors.gray20}` }}
          >
            {isLong && (
              <Flex>
                <Typography>Group by Intervals</Typography>
              </Flex>
            )}
            <Flex padding="md" margin="0 1rem" width={!isLong && ('50%' as any)}>
              <Slider
                marks={selected.stepOptions as any}
                value={getCurrentStep()}
                onChange={handleStepChange}
              />
            </Flex>
            <Flex
              justifyContent="flex-end"
              padding="sm"
              marginBetween="xs"
              width={!isLong && ('50%' as any)}
            >
              <Switch active={!selected.isPartialData} onSwitch={handlePartialSwitch}>
                Show Only Complete Intervals
              </Switch>

              <Tooltip
                title="If Show Only Complete Intervals is disabled, this will show steps that have not yet completed (like the current day or hour)."
                placement="left"
                minWidth={300}
              >
                <Icon name="InfoCircle" size="sm" />
              </Tooltip>
            </Flex>
          </Flex>
        </S.Menu>
      )}
    </S.MenuContainer>
  )
}

const transformToPositivePeriod = (selected: U.State): string | null => {
  return _.isString(selected.range) ? selected.range.toString().replace('-', '') : null
}
