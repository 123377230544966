import React, { ReactNode, useState } from 'react'
import { IntrinsicElementsKeys } from 'styled-components'
import pascalcase from 'pascalcase'
import { camelCase } from 'lodash'
import { useDarkMode } from '../dark-mode'
import colors, { Color } from '../bit/utils/colors'
import './fonts.css'
import * as S from './styles'
import { isEmpty, isNumber } from 'lodash'
import { CenterEllipsis, RightEllipsis } from './Ellipsis'

export type TypographyVariantType =
  | 'display'
  | 'title1'
  | 'title2'
  | 'title3'
  | 'title4'
  | 'title5'
  | 'title6'
  | 'bodyDisplay'
  | 'body'
  | 'eyebrow'
  | 'caption'
  | 'container'

export interface TypographyProps extends React.PropsWithoutRef<JSX.IntrinsicElements['div']> {
  /** The text for the variants or the semantic HTML for the container component. */
  children: ReactNode
  /** The different typography styles. If none is chosen, the component defaults to a div and adds all the typography styles, letting you use native HTML elements to craft your copy. */
  variant?: TypographyVariantType
  /** Force a typographic scale. If none is provided, one is picked, automatically, based on the viewport width. */
  scale?: 'large' | 'small' | 'auto'
  /** Override the default text alignment */
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit'
  /** Remove margin from the typography. */
  noMargin?: boolean
  /** Override the default color with your own snazzy one! Or type out the name of a kite color (ie: dark-blue-30). */
  color?: string
  /** Use to override the underlying HTML element (ie: as="span") */
  as?: IntrinsicElementsKeys
  /** Used to limit the text to a certain amount of lines. You can send the amount of lines you want before it gets ellipsised */
  ellipsis?: number | 'center'
  /** Tooltip Text in case Ellipsis is activated*/
  tooltip?: string
  /** Tooltip Disabled completely when set to true. Default false*/
  disableTooltip?: boolean
}

interface EllipsisProps {
  ellipsis: any
  children: ReactNode
  setTooltipText: any
  setEllipsisActive: any
}

interface TypographyWithTooltipProps {
  tooltip: string
  active: boolean
  children: ReactNode
  dataTest?: string
}

export default ({
  variant = 'container',
  scale = 'auto',
  noMargin = false,
  color,
  align = 'inherit',
  ellipsis,
  tooltip,
  disableTooltip,
  ...props
}: TypographyProps) => {
  const { isDarkMode } = useDarkMode()
  const kiteColor = colors[camelCase(color) as Color] as string | undefined
  const TypographyVariant =
    variant === 'container' ? S.Typography : S.variants[pascalcase(variant) as any]

  const [ellipsisActive, setEllipsisActive] = useState(false)
  const [tooltipText, setTooltipText] = useState('')

  const measuredRef = React.useCallback(node => {
    if (
      node !== null &&
      isNumber(ellipsis) &&
      ellipsis > 0 &&
      isNumber(node.scrollHeight) &&
      isNumber(node.clientHeight)
    ) {
      // Note: Not doing a 1:1 comparison since line-height issues with Kite on certain Title sizes give us a slightly higher scrollHeight than the clientHeight. We are looking for almost double the size, thus the 1.5
      const moreThanOneLineOfText = node.scrollHeight > node.clientHeight * 1.5
      setEllipsisActive(moreThanOneLineOfText)
      setTooltipText(node.innerText)
    }
  }, [])

  return (
    <TypographyWithTooltip
      tooltip={tooltip || tooltipText}
      active={ellipsisActive && !disableTooltip}
      dataTest={props['data-test'] ? `${props['data-test']}Tooltip` : 'typographyTooltip'}
    >
      <Ellipsis
        ellipsis={ellipsis}
        setTooltipText={setTooltipText}
        setEllipsisActive={setEllipsisActive}
      >
        <TypographyVariant
          ref={measuredRef}
          {...props}
          $scale={scale}
          $align={align}
          $noMargin={noMargin}
          $color={kiteColor || color}
          $isDarkMode={isDarkMode}
          as={props.as as undefined}
        />
      </Ellipsis>
    </TypographyWithTooltip>
  )
}

const Ellipsis = ({ ellipsis, children, setTooltipText, setEllipsisActive }: EllipsisProps) => {
  if (ellipsis === 'center') {
    return (
      <CenterEllipsis
        children={children}
        setTooltipText={setTooltipText}
        setEllipsisActive={setEllipsisActive}
      />
    )
  } else if (ellipsis > 0) {
    return (
      <RightEllipsis
        children={children}
        ellipsis={typeof ellipsis === 'string' ? parseInt(ellipsis, 10) : ellipsis}
      />
    )
  }
  return <>{children}</>
}

const TypographyWithTooltip = ({
  tooltip,
  active,
  children,
  dataTest,
}: TypographyWithTooltipProps) => {
  if (isEmpty(tooltip) || !active) {
    return <>{children}</>
  }
  return (
    <S.StyledToolTip dataTest={dataTest} title={tooltip} placement="bottom">
      {children}
    </S.StyledToolTip>
  )
}
