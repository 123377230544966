import React, { CSSProperties } from 'react'
import { camelCase } from 'lodash'
import * as S from './styles'
import spacing, { SpacingElement } from 'components/shared/utils/spacing'
import { Elevation } from 'components/shared/bit/utils/elevation'
import { useDarkMode } from 'components/shared/dark-mode'
import numberToPixels from 'components/shared/bit/utils/number-to-pixels'
import colors, { Color } from 'components/shared/utils/colors'
import useDimensions from 'components/shared/bit/hooks/use-dimensions'

export interface CardProps extends React.PropsWithoutRef<JSX.IntrinsicElements['div']> {
  width?: string | number
  minWidth?: string | number
  maxWidth?: string | number
  height?: string | number
  minHeight?: string | number
  maxHeight?: string | number
  padding?: SpacingElement | string | number
  imageSrc?: string
  /** String or number between 0-3. */
  elevation?: Elevation
  disablePadding?: boolean
  disableShadow?: boolean
  disableBorder?: boolean
  disableRounding?: boolean
  /** Override the default background color with your own snazzy one! Or type out the name of a kite color (ie: dark-blue-30). */
  backgroundColor?: string
  centered?: boolean
  /** The CSS justify-self property. */
  justifySelf?: CSSProperties['justifySelf']
  /** The CSS align-self property. */
  alignSelf?: CSSProperties['alignSelf']
  overflow?: string
}

export const Card = ({
  width = '100%',
  minWidth = 'unset',
  maxWidth = 'unset',
  height = 'unset',
  minHeight = 'unset',
  maxHeight = 'unset',
  elevation = 0,
  disablePadding = false,
  disableShadow = false,
  disableBorder = false,
  disableRounding = false,
  centered,
  justifySelf,
  alignSelf,
  imageSrc,
  onClick,
  backgroundColor,
  padding,
  children,
  overflow,
  ...props
}: CardProps) => {
  const { isDarkMode } = useDarkMode()
  const { ref, ...dimensions } = useDimensions()
  const pixelWidth = numberToPixels(width)
  const pixelMinWidth = numberToPixels(minWidth)
  const pixelMaxWidth = numberToPixels(maxWidth)
  const pixelHeight = numberToPixels(height)
  const pixelMinHeight = numberToPixels(minHeight)
  const pixelMaxHeight = numberToPixels(maxHeight)
  const pixelPadding = spacing.element[`${padding}`.toLowerCase()] || numberToPixels(padding)
  const kiteColor = colors[camelCase(backgroundColor) as Color] as string | undefined
  return (
    <S.Card
      ref={ref}
      $width={pixelWidth as any}
      $minWidth={pixelMinWidth}
      $maxWidth={pixelMaxWidth}
      $height={pixelHeight}
      $minHeight={pixelMinHeight}
      $maxHeight={pixelMaxHeight}
      $centered={centered}
      $justifySelf={justifySelf}
      $alignSelf={alignSelf}
      $elevation={elevation}
      $disableBorder={disableBorder}
      $disableShadow={disableShadow}
      $disableRounding={disableRounding}
      $backgroundColor={kiteColor || backgroundColor}
      $isDarkMode={isDarkMode}
      $overflow={overflow}
      {...props}
    >
      {imageSrc && <S.Image $width={numberToPixels(dimensions.width) as any} $src={imageSrc} />}
      <S.CardContent
        $padding={pixelPadding ?? ''}
        $disablePadding={disablePadding}
      >
        {children}
      </S.CardContent>
    </S.Card>
  )
}
