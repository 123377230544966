const breakpoints = {
  xs: 0,
  sm: 480,
  md: 600,
  lg: 840,
  xl: 960,
  xxl: 1280,
}

export const mediaQueries = {
  xs: `@media screen and (max-width: ${breakpoints.sm - 1}px)`,
  sm: `@media screen and (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.md - 1}px)`,
  md: `@media screen and (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg - 1}px)`,
  lg: `@media screen and (min-width: ${breakpoints.lg}px) and (max-width: ${breakpoints.xl - 1}px)`,
  xl: `@media screen and (min-width: ${breakpoints.xl}px) and (max-width: ${
    breakpoints.xxl - 1
  }px)`,
  lessThanMd: `@media screen and (max-width: ${breakpoints.md - 1}px)`,
  lessThanLg: `@media screen and (max-width: ${breakpoints.lg - 1}px)`,
  lessThanXl: `@media screen and (max-width: ${breakpoints.xl - 1}px)`,
  lessThanXxl: `@media screen and (max-width: ${breakpoints.xxl - 1}px)`,
  xxl: `@media screen and (min-width: ${breakpoints.xxl}px)`,
}

export type Breakpoint = keyof typeof breakpoints

export default breakpoints
