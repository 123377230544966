import colors from '../colors'

export type Elevation = 0 | 1 | 2 | 3 | 4 | '0' | '1' | '2' | '3' | '4'

function generateElevation(shadowColor: string) {
  return {
    0: 'box-shadow: none',
    1: `box-shadow: 0 1px 4px ${shadowColor}`,
    2: `box-shadow: 0 2px 8px ${shadowColor}`,
    3: `box-shadow: 0 3px 12px ${shadowColor}`,
    4: `box-shadow: 0 24px 24px ${shadowColor}`,
  }
}

export const lightMode = generateElevation(colors.lightMode.shadow)
export const darkMode = generateElevation(colors.darkMode.shadow)

export default {
  lightMode,
  darkMode,
}
