import styled, { css } from 'styled-components'
import spacing from 'components/shared/utils/spacing'
import colors from 'components/shared/utils/colors'

export const TabsContainer = styled.div`
  width: 100%;
`
export const TabPanelContainer = styled.div``

interface tabListContainerProps {
  $tabPosition?: string
}

export const TabListContainer = styled.div<tabListContainerProps>`
  display: flex;
  justify-content: ${({ $tabPosition }) => ($tabPosition === 'left' ? 'flex-start' : $tabPosition)};
`

export const TabPanel = styled.div`
  &&:focus {
    outline: none;
  }
`

interface TabProps {
  $isActive?: boolean
  $isDarkMode?: boolean
}

export const Tab = styled.button<TabProps>`
  width: 100%;
  text-align: center;
  padding: ${spacing.element.md} ${spacing.element.xl} 14px ${spacing.element.xl};
  cursor: pointer;
  background-color: unset;
  border: unset;
  font-size: 1rem;

  :focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none;
  }

  :focus {
    outline: 0;
  }

  :focus {
    box-shadow: ${({ $isDarkMode }) => ($isDarkMode ? colors.white : colors.blue20)} 0px 0px 2px 2px;
  }

  ${({ $isActive, $isDarkMode }) => {
    return $isActive
      ? css`
          border-bottom: 2px solid ${colors.blue20};
          cursor: default;
          && > div > p {
            font-weight: 600;
          }
        `
      : css`
          &&:hover {
            color: ${$isDarkMode ? colors.white : colors.black};
            border-bottom: 2px solid ${$isDarkMode ? colors.white : colors.gray30};
          }
        `
  }}

  ${({ $isActive }) => {
    return css`
      &&:disabled,
      &&:hover:disabled {
        border-bottom: ${$isActive
          ? `2px solid
              ${colors.gray30}`
          : 'none'};
        cursor: default;
      }
    `
  }}
`

export const BadgeContainer = styled.div`
  margin-left: 1rem;
`

export const IconButtonContainer = styled.div`
  margin-left: 1rem;
`
