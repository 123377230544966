export const element: Record<string, string> = {
  xxs: '4px',
  xs: '8px',
  sm: '12px',
  md: '16px',
  lg: '20px',
  xl: '24px',
  xxl: '32px',
  xxxl: '40px',
}

export const section: Record<string, string> = {
  link: '20px',
  xs: '48px',
  sm: '64px',
  md: '80px',
  lg: '100px',
  xl: '120px',
}

export type SpacingElement = keyof typeof element
export type SpacingSection = keyof typeof element

export default { element, section }
