import React from 'react'
import Typography from 'components/shared/typography'
import Flex from 'components/shared/flex'
import Icon from 'components/shared/icon'
import Tooltip from 'components/shared/Tooltip'
import * as S from './styled'
import Spacing from 'components/shared/utils/spacing'
import isEmpty from 'lodash/isEmpty'
import { useDarkMode } from 'components/shared/dark-mode'

function ConditionalTooltip({
  active,
  title = '',
  children,
}: {
  active: boolean
  title: string
  children: React.ReactNode
}) {
  return active ? <Tooltip title={title}>{children}</Tooltip> : <>{children}</>
}

function ChartsHeading({
  title,
  description,
  summary,
  titleVariant = 'title6',
  value,
  valueLabel,
  valueTooltip,
  actionMenu,
  warnings,
  children,
}: {
  title?: string
  description?: string
  summary?: string
  titleVariant?: 'title6' | 'bodyDisplay'
  value?: string
  valueLabel?: string
  valueTooltip?: string
  actionMenu?: JSX.Element
  warnings?: JSX.Element | boolean
  children?: React.ReactNode
}) {
  const { isDarkMode } = useDarkMode()
  const ChartTitle = (
    <S.ChartTitle variant={titleVariant} noMargin>
      {title}
    </S.ChartTitle>
  )
  const ChartValue = (
    <Tooltip title={`${valueTooltip}` ?? value ?? valueLabel}>
      <Typography
        color={isDarkMode ? 'gray10' : 'gray30'}
        variant="bodyDisplay"
        noMargin
        style={{
          fontFamily: 'Open Sans',
        }}
        data-test={`aggregationValue${title}`}
      >
        {value}
      </Typography>
    </Tooltip>
  )
  const ChartDescription = description ? <Icon name="InfoCircle" size="xs" color="black" /> : <></>

  const hasTitle = !isEmpty(title)

  return (
    <>
      <Flex
        direction="column"
        justifyContent="center"
        style={{
          flexGrow: 1,
          marginBottom: Spacing.element.xxs,
        }}
        gap={Spacing.element.sm}
      >
        {warnings && <>{warnings}</>}
        <Flex
          direction="row"
          justifyContent="space-between"
          gap={Spacing.element.md}
          alignItems="center"
          style={{ ...(hasTitle ? { minHeight: '44px' } : {}) }}
        >
          {hasTitle && (
            <Flex
              direction="row"
              gap={Spacing.element.sm}
              justifyContent="space-between"
              alignItems="center"
              style={{ flexGrow: 1 }}
            >
              <ConditionalTooltip title={description || ''} active={!isEmpty(description || '')}>
                <Flex direction="row" gap={Spacing.element.sm} alignItems="center">
                  {ChartTitle}
                  {ChartDescription}
                </Flex>
              </ConditionalTooltip>
              {ChartValue}
            </Flex>
          )}

          <Flex direction="row" gap={Spacing.element.sm} alignItems="center">
            {actionMenu}
          </Flex>
        </Flex>
      </Flex>
      {summary ? <S.SummaryDescription>{summary}</S.SummaryDescription> : ''}
      {children && <Typography>{children}</Typography>}
    </>
  )
}

export default ChartsHeading
