import isNaN from 'lodash/isNaN'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import TableCell from '@material-ui/core/TableCell'
import MuiTableRow from '@material-ui/core/TableRow'
import MuiTooltip from '@material-ui/core/Tooltip'
import Flex from 'components/shared/flex/Flex'
import { Button } from 'components/shared/Button'
import MetricTooltipTitle from 'components/voiceOfCustomer/MetricTooltipTitle'
import { formatBoolean } from 'components/voiceOfCustomer/utils'
import SequenceButton from '../SequenceButton'
import CopyVisitIDButton from 'components/voiceOfCustomer/CopyVisitIDButton'
import CategoryTableCell from '../CategoryTableCell'
import AccountNumberTableCell from './AccountNumberTableCell'
import { roundToTwoDecimals } from 'utils'
import { types } from '..'
import * as S from '../styles'
import { Tooltip } from '@material-ui/core'

// Convert timezone to UTC -7
export const UTCOffset = '7'

function renderCell(
  row: types.FeedbackModel,
  header: types.ColumnHeader,
  decryptedAccountNumber: string,
  setDecryptedAccountNumber: Function,
  hasTransformerDecryptAccess: boolean,
  categories?: { label: string; value: string }[]
) {
  const type = header.id
  const sentimentTypes: string[] = ['negative', 'positive', 'mixed', 'neutral']
  const sequenceLink = `/tools/sequence/${row.visitId}`
  const dataSource = row?.[type] === 'cqe' || row[type] === 'cqeInternal' ? 'Quantum' : row[type]
  const value = row[type] ?? ''
  const fieldValue = type === 'SR' ? formatBoolean(value, true) : value
  const userSelectedCategory = row.userSelectedCategory as string
  const notArchivedManualCategories = row.prismAppliedManualCategories?.filter(
    (prismAppliedManualCategory: types.ManualCategory) => !prismAppliedManualCategory.isArchived
  )
  const visit = row?.visit as {
    experimentUuids: Array<string>
    activatedExperimentUuids: Array<string>
    variantUuids: Array<string>
  }
  const displayVisitCells = !isNil(visit) && !isEmpty(visit[type])
  switch (type) {
    case 'timestamp':
      return (
        <TableCell key={type}>
          <S.TimestampContainer>
            <div>{moment.utc(row?.[type]).subtract(UTCOffset, 'h').format('MM/DD/YY')}</div>
            <div>{moment.utc(row?.[type]).subtract(UTCOffset, 'h').format('h:mm A')}</div>
          </S.TimestampContainer>
        </TableCell>
      )
    case 'shouldRetrain':
      return (
        <TableCell key={type} align={(header.align as any) ?? 'left'}>
          {row?.[type] ? 'Yes' : 'No'}
        </TableCell>
      )
    case 'awsKeywords':
      return (
        <S.StyledVerbatimTableCell align={(header.align as any) ?? 'left'}>
          {row?.[type]?.join(', ')}
        </S.StyledVerbatimTableCell>
      )
    case 'awsSentiment':
      return (
        <TableCell key={type} align={(header.align as any) ?? 'left'}>
          <S.MultipleTableSorts>
            {sentimentTypes.map((sentiment: string) => {
              const sentimentValue = roundToTwoDecimals(row?.[type]?.[sentiment])
              const displayValue = (sentimentValue * 100).toFixed(0)
              return <div>{isNaN(sentimentValue) ? 'N/A' : `${displayValue}%`}</div>
            })}
          </S.MultipleTableSorts>
        </TableCell>
      )
    case 'awsCategories':
      return <CategoryTableCell key={type} categoryContent={row[type]} suppressManualCategory />
    case 'prismAppliedCategories': {
      return (
        <CategoryTableCell
          key={type}
          categoryContent={row[type]}
          feedbackID={row._id}
          manualCategories={notArchivedManualCategories}
          categories={categories}
        />
      )
    }

    case 'visitId':
      if (!row.visitId) return <S.ActionCell key={type}>No Visit ID on this feedback</S.ActionCell>
      return (
        <S.ActionCell key={type}>
          <Flex column center margin="8px" padding="8px">
            <CopyVisitIDButton visitID={row[type]} isTextButton />
            <SequenceButton url={sequenceLink} />
          </Flex>
        </S.ActionCell>
      )
    case 'encAccountNumber':
      if (!row.encAccountNumber)
        return (
          <S.StyledVerbatimTableCell align={(header.align as any) ?? 'left'}>
            {row.encAccountNumber}
          </S.StyledVerbatimTableCell>
        )

      return (
        <S.ActionCell key={type} data-test="accountNumber">
          <AccountNumberTableCell
            accountNumber={row.encAccountNumber as string}
            decryptedAccountNumber={decryptedAccountNumber}
            setDecryptedAccountNumber={setDecryptedAccountNumber}
            hasTransformerDecryptAccess={hasTransformerDecryptAccess}
          />
        </S.ActionCell>
      )
    case 'activationMethod':
      return (
        <TableCell
          key={type}
          data-test="activationMethodPath"
          align={
            (header.align as 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined) ??
            'left'
          }
        >
          {row.activationMethod}
        </TableCell>
      )
    case 'source':
      return (
        <TableCell key={type} align={(header.align as any) ?? 'left'}>
          {dataSource}
        </TableCell>
      )
    case 'SCP':
      return (
        <TableCell key={type} align={(header.align as any) ?? 'left'}>
          {row?.[type]?.toString()}
        </TableCell>
      )
    // case 'CES':
    case 'NPS':
    case 'SR':
    case 'CSAT':
    case 'starRating':
    case 'starRatingExternal':
      return isNil(fieldValue) ||
        isEmpty(fieldValue) ||
        isEmpty(userSelectedCategory) ||
        !fieldValue.toString().includes(', ') ? (
        <TableCell key={type} data-pho={type} align={(header.align as any) ?? 'left'}>
          {fieldValue}
        </TableCell>
      ) : (
        <MuiTooltip title={MetricTooltipTitle({ fieldValue, userSelectedCategory }) ?? ''}>
          <TableCell key={type} data-pho={type} align={(header.align as any) ?? 'left'}>
            {fieldValue}
          </TableCell>
        </MuiTooltip>
      )

    case 'isFlagged':
    case 'isHidden':
    case 'isReviewed':
    case 'isNullVerbatim':
      return (
        <S.StyledVerbatimTableCell align={(header.align as any) ?? 'left'}>
          {row?.[type] ? 'Yes' : 'No'}
        </S.StyledVerbatimTableCell>
      )
    case 'experimentUuids':
    case 'activatedExperimentUuids':
    case 'variantUuids':
      return (
        <S.ActionCell key={type}>
          <Flex center margin="8px" padding="8px">
            {displayVisitCells && (
              <>
                <Tooltip title={`Click to Copy All UUID(s)`} placement="top" arrow>
                  <div>
                    <Button
                      iconSize="sm"
                      icon="Copy"
                      onClick={() => navigator?.clipboard?.writeText(visit[type].join(', '))}
                      variant="borderless"
                      data-pho="copyAllUUIDs"
                      style={{ minWidth: 50 }}
                    />
                  </div>
                </Tooltip>
                <Tooltip title={visit[type].join(', ')} placement="top" arrow>
                  <div>
                    {!isEmpty(visit[type]) && visit[type].length > 1
                      ? `${visit[type][0]}, +${visit[type].length - 1} more UUID(s)...`
                      : visit[type][0]}
                  </div>
                </Tooltip>
              </>
            )}
          </Flex>
        </S.ActionCell>
      )
    default:
      return (
        <S.StyledVerbatimTableCell align={(header.align as any) ?? 'left'}>
          {row?.[type]}
        </S.StyledVerbatimTableCell>
      )
  }
}

function TableRow({
  row,
  columnHeaders,
  decryptedAccountNumber,
  setDecryptedAccountNumber,
  hasTransformerDecryptAccess,
  categories,
}: TableRowProps) {
  return (
    <MuiTableRow>
      {columnHeaders.map((header: types.ColumnHeader) =>
        renderCell(
          row,
          header,
          decryptedAccountNumber,
          setDecryptedAccountNumber,
          hasTransformerDecryptAccess,
          categories
        )
      )}
    </MuiTableRow>
  )
}

interface TableRowProps {
  row: types.FeedbackModel
  columnHeaders: Array<types.ColumnHeader>
  decryptedAccountNumber: string
  setDecryptedAccountNumber: Function
  hasTransformerDecryptAccess: boolean
  categories?: { label: string; value: string }[]
}

export default TableRow
