import React from 'react'
import 'focus-visible'
import Flex from 'components/shared/flex/Flex'
import { useDarkMode } from 'components/shared/dark-mode'
import * as S from './styles'

export interface CheckboxProps {
  /** The label for the checkbox. */
  label: string
  /** Use this to set an initial state for the checkbox.. (Don't use in conjunction with "checked.") */
  initialChecked?: boolean | 'indeterminate'
  disabled?: boolean
  required?: boolean
  /** Use this to take control of the checkbox's state.. (Don't use in conjunction with "initialChecked.") */
  checked?: boolean | 'indeterminate'
  /** Fires when the checkbox status has changed. First argument is a boolean whether or not the new status is "checked" or not. Second argument is the label of the checkbox. */
  onChange?: (isChecked: boolean, label: string) => void
  /** Hides the label. Not recommended, as this would likely confuse the user. */
  hideLabel?: boolean
}

export const Checkbox = ({
  label,
  initialChecked = false,
  disabled = false,
  required = false,
  checked,
  onChange,
  hideLabel,
  ...props
}: CheckboxProps) => {
  const { isDarkMode } = useDarkMode()
  const [isChecked, setIsChecked] = React.useState(initialChecked)
  const isControlled = checked !== undefined
  const trueIsChecked = isControlled ? checked : isChecked

  const handleChange = React.useCallback(() => {
    if (!disabled) {
      onChange && onChange(!trueIsChecked, label)
      setIsChecked(!trueIsChecked)
    }
  }, [disabled, trueIsChecked, setIsChecked, onChange, label])

  return (
    <Flex position="relative" {...props}>
      <S.Input
        name={label}
        id={label}
        key={label}
        checked={trueIsChecked === true}
        onChange={handleChange}
        disabled={disabled}
        required={required}
        $isDarkMode={isDarkMode}
      />
      {trueIsChecked === true && <S.CheckIcon onClick={handleChange} />}
      {trueIsChecked === 'indeterminate' && (
        <S.DashIcon $isDarkMode={isDarkMode} onClick={handleChange} />
      )}
      {!hideLabel && (
        <S.Label htmlFor={label} $disabled={disabled} $isDarkMode={isDarkMode}>
          {label}
          {required && <span>*</span>}
        </S.Label>
      )}
    </Flex>
  )
}
