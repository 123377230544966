import styled from 'styled-components'
import MenuList from '@material-ui/core/MenuList'
import colors from 'components/shared/bit/utils/colors'

export const FavoriteApplicationsContainer = styled(MenuList)`
  height: 100%;
  width: 100%;
`

export const MenuContainer = styled.div`
  padding: 1.25rem;
  color: ${colors.black};
  width: 25rem;
  max-height: 90vh;

  > *:first-child {
    margin-bottom: 1rem;
  }
`

export const NavTitle = styled.h6``

export const TitleContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`

export const NoResultsMessage = styled.div`
  margin-top: 15px;
  font-size: 0.9rem;
`

export const EmptyFavoritesMessage = styled.div`
  margin-bottom: 10px;
  color: ${colors.gray30};
`

export const SectionLabel = styled.div`
  width: 8rem;
`
