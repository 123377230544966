import React from 'react'
import styled, { css } from 'styled-components'
import Chip from '@material-ui/core/Chip'

import Icon from '../icon/Icon'
import { defaultFont } from 'common/constants'
import colors from '../bit/utils/colors'
export const captionStyles = css`
  ${defaultFont};
  margin-bottom: 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
`

export const StyledChip = styled(({ outline, backgroundColor, color, ...props }) => (
  <Chip {...props} />
))`
  &&,
  &&:focus {
    ${captionStyles};
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    height: 1.75rem;
    padding: 0 1rem;
    border-radius: 14px;
    margin: 2px 0.5rem;
    background-color: ${props => (props.outline ? colors.white : props.backgroundColor)};
    color: ${props => (props.outline ? colors.blue10 : props.color)};
    border: ${props => (props.outline ? css`1px solid ${colors.blue10}` : 'none')};
    margin: 2px 1rem 2px 0;
    cursor: ${props => (props.onClick || props.to || props.href ? 'pointer' : 'auto')};
    max-width: unset;
    min-width: 120px;
    justify-content: ${props => (props.onDelete ? 'space-between' : 'center')};
  }

  && span {
    padding-left: 0px;
    padding-right: 0px;
  }

  & p {
    color: ${props => (props.outline ? colors.blue10 : props.color)};
    margin-bottom: 0;
  }

  && h3 {
    ${props => !props.onDelete && `margin: 0 0.75rem 0 0`};
  }

  && svg {
    color: ${props => props.color};
    fill: ${props => props.color};
  }

  && img {
    margin: 0 0.75rem 0 0;
  }
`

export const Text = styled.p`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  color: ${colors.gray30};
  text-align: center;
  font-size: 0.875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const DeletableIcon = styled(Icon)`
  && {
    margin: 0 0 0 0.75rem;
  }
`
