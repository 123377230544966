import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { startCase, kebabCase, reduce, isEmpty, uniq, compact } from 'lodash'

import { entityTypes, environments } from 'common/constants'
import colors, { primary, Color } from 'components/shared/bit/utils/colors'

export const createStyled = (styles: object, options?: object) => {
  function Styled(props: { children: Function }) {
    const { children, ...other } = props
    return props.children(other)
  }

  Styled.propTypes = {
    children: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  }

  return withStyles(styles, options)(Styled)
}

export const LINE_LENGTH = '516px'

export const valueToString = (value: string | boolean | undefined): string => {
  let stringValue = value
  if (!value) {
    stringValue = 'NONE'
    if (value === undefined) stringValue = 'UNDEFINED'
    if (value === false) stringValue = 'false'
  }
  if (value === true) stringValue = 'true'

  return `${stringValue}`
}

export const getEntityLink = (entityId: string, entityType: string, env?: string): string => {
  const splitId = entityId.split('/')
  switch (entityType) {
    case entityTypes.ACTIVATION_EVENT:
      return `/event-schema/${kebabCase(entityType)}s/${splitId[1]}`
    case entityTypes.ATOM:
      return `/reporting/${kebabCase(entityType)}s/${entityId}`
    default:
      return `/event-schema/${env}/${kebabCase(entityType)}s/${kebabCase(splitId[1])}/${splitId[2]}`
  }
}

export const getEntityElementIconText = (entityType: string): string => {
  switch (entityType) {
    case entityTypes.EVENT_TYPE:
      return 'Et'
    case entityTypes.EVENT_CASE:
      return 'Ec'
    case entityTypes.TEST_CASE:
      return 'Tc'
    case entityTypes.ACTIVATION_EVENT:
      return 'Ae'
    default:
      if (entityType) {
        return startCase(entityType.toLowerCase()).substring(0, 2)
      }
      return 'Qu'
  }
}

export const transition = {
  durations: {
    extraShort: '100ms',
    short: '200ms',
    medium: '300ms',
    long: '400ms',
    extraLong: '500ms',
  },
}

export const getEntityColor = (entityType?: string): Color => {
  switch (entityType) {
    case entityTypes.EVENT_TYPE:
      return colors.teal as Color
    case entityTypes.EVENT_CASE:
      return colors.orange as Color
    case entityTypes.TEST_CASE:
      return colors.purple as Color
    case entityTypes.ATOM:
      return colors.darkBlue10 as Color
    case entityTypes.ACTIVATION_EVENT:
      return colors.green as Color
    case entityTypes.GROUP:
      return primary.blue20 as Color
    default:
      return primary.blue20 as Color
  }
}

export const hasIrrelevantFields = (object: object): boolean => {
  const hasIrrelevantFields = reduce(
    object,
    (accum, value, key) =>
      accum ||
      key === '__typename' ||
      key === 'trace' ||
      value === null ||
      value === undefined ||
      (typeof value === 'object' && isEmpty(value)),
    false
  )
  return hasIrrelevantFields
}

export const getTrueValue = (string: string): string | boolean => {
  if (string === 'true') {
    return true
  }
  if (string === 'false') {
    return false
  }
  return string
}

export const envRegExp = new RegExp('(/environments/)[a-z]*')

export const getEnv = (path: string): string => {
  const matches = path.match(envRegExp) || []
  return matches[0] === environments.DEVELOP ||
    matches[0] === environments.STAGING ||
    matches[0] === environments.PRODUCTION
    ? matches[0]
    : environments.DEVELOP
}

export function getAppNames(appIDs?: string[]): string[] {
  const appNames = appIDs?.map((app: string) => app.split('@')[0])
  return uniq(compact(appNames)) as string[]
}

export function getAppTypes(appIDs?: string[]): string[] {
  const appTypes = appIDs?.map((app: string) => app.split('@')[1])
  return uniq(compact(appTypes)) as string[]
}

export enum inputTypes {
  DATE_PICKER = 'DATE_PICKER',
  CHECKBOX = 'CHECKBOX',
  RADIO_BUTTON = 'RADIO_BUTTON',
  INPUT = 'INPUT',
  TEXT_FIELD = 'TEXT_FIELD',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
}
