import React, { useState } from 'react'
import { isEmpty, get, isNil } from 'lodash'

import colors from '../bit/utils/colors'
import * as S from './styles'

function PillGroup({
  pills,
  inputProps,
  onDelete,
  onAdd,
  color,
  backgroundColor,
  ...otherProps
}: PillGroupProps): React.FunctionComponentElement<PillGroupProps> {
  const [inputValue, setInputValue] = useState('')

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target
    setInputValue(value)
  }

  function handleInputSubmit(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter' && !isEmpty(inputValue)) {
      onAdd && onAdd(inputValue)
      setInputValue('')
    }
  }

  function handleInputBlur() {
    if (!isEmpty(inputValue)) {
      onAdd && onAdd(inputValue)
      setInputValue('')
    }
  }

  function renderInputContainer() {
    if (isNil(inputProps)) return null

    return (
      <S.SearchInputContainer>
        <S.StyledSearchInput
          placeholder={inputProps.placeholder}
          onChange={handleOnChange}
          onBlur={handleInputBlur}
          onKeyDown={handleInputSubmit}
          suggestions={inputProps.suggestions}
          value={inputValue}
        />
      </S.SearchInputContainer>
    )
  }

  function renderPills() {
    if (isNil(pills)) return null

    return pills.map((pill, index) => (
      <S.StyledPill
        key={`${index}`}
        outline={false}
        backgroundColor={backgroundColor || colors.gray20}
        color={color || colors.black}
        onDelete={onDelete}
        value={get(pill, 'value', pill)}
        deletable={get(pill, 'deletable')}
      >
        {get(pill, 'label', pill)}
      </S.StyledPill>
    ))
  }

  return (
    <div {...otherProps}>
      {renderPills()}
      {renderInputContainer()}
    </div>
  )
}

interface PillGroupProps {
  pills?: Array<string | Pill>
  inputProps?: { placeholder: string; suggestions: any }
  onDelete: Function
  onAdd?: Function
  color?: string
  backgroundColor?: string
}

type Pill = {
  key: string
  value: string
  label?: React.ReactNode
  deletable?: boolean
}
export default PillGroup
export { S as Styles }
