import { useState, useEffect } from 'react'
import * as S from './styles'
import Icon, { IconName } from 'components/shared/icon'
import colors from 'components/shared/utils/colors'
import { useDarkMode } from 'components/shared/dark-mode'
import Typography from 'components/shared/typography'

export interface ToastProps {
  /** The text content inside of the toast */
  label: string
  /** An optional icon that will display next to the toast */
  icon?: IconName
  /** The duration (in seconds) for the toast to display. Can also specify the strings short or long for a 2.5s or 3.5s duration, respectively */
  duration?: 'short' | 'long' | number
  /** An optional boolean to control whether the toast is displayed */
  isToastOpen?: boolean
  /** An optional color for the icon. Defaults to white */
  iconColor?: string
  /** Location for the toast. Defaults to top-right. */
  placement?: 'top-left' | 'top' | 'top-right' | 'bottom-right' | 'bottom' | 'bottom-left'
  /** A callback function that is triggered at the end of declared duration for the Toast. */
  onDismiss?: Function
}

export const Toast = ({
  label,
  icon,
  duration = 'long',
  isToastOpen = true,
  iconColor = colors.white,
  placement = 'top-right',
  onDismiss,
}: ToastProps) => {
  const { isDarkMode } = useDarkMode()
  const [isToastShown, setIsToastShown] = useState(isToastOpen)
  const parsedDuration = parseDuration(duration)

  useEffect(() => {
    if (isToastOpen) {
      setIsToastShown(true)
      const timer = setTimeout(() => {
        onDismiss && onDismiss()
        setIsToastShown(false)
      }, parsedDuration * 1000)

      return () => clearTimeout(timer)
    }
    return
  }, [duration, setIsToastShown, isToastOpen])

  if (!isToastOpen) return null

  return (
    <S.Toast
      $isToastShown={isToastShown}
      $isDarkMode={isDarkMode}
      $placement={placement}
      role="status"
      aria-live="polite"
    >
      {icon && <Icon size="sm" name={icon} margin={{ right: 'sm' }} color={iconColor} />}
      <Typography data-pho="toastText" color={colors.white}>
        {label}
      </Typography>
    </S.Toast>
  )
}

function parseDuration(duration: 'short' | 'long' | number): number {
  switch (duration) {
    case 'short':
      return 2.5
    case 'long':
      return 3.5
    default:
      return duration
  }
}
