import React from 'react'
import * as S from './styled'

function Option({ isRadioButton, ...props }: OptionProps): React.ReactElement {
  const OptionComponent = isRadioButton ? S.RadioButton : S.PrismReactCheckbox

  return <OptionComponent {...props} />
}

interface OptionProps {
  label: string
  isRadioButton?: boolean
  color?: string
  onChange?: any
  value?: string
  checked?: any
  initialChecked?: any
}

export default Option
