import styled from 'styled-components'
import colors from '../utils/colors'
import NoSearchResultsSVG from './NoSearchResultsLogo'

export const Title = styled.h1`
  margin-bottom: 1rem;
  letter-spacing: -0.3px;
`

export const PageNotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2.1875rem;
  align-items: center;
  height: 100%;
  flex: 1;
`

export const PageNotFoundContent = styled.div`
  width: 637px;
  text-align: center;
`

export const PageNotFoundText = styled.p`
  font-weight: 500;
  line-height: 1.63;
  letter-spacing: -0.3px;
  color: ${colors.gray30};
`

export const PageNotFoundImage = styled(NoSearchResultsSVG)`
  margin-bottom: 3.1875rem;
`
