import styled, { css } from 'styled-components'
import TextField from '@material-ui/core/TextField'
import { Button } from 'components/shared/Button'
import Color from 'components/shared/bit/utils/colors'

export const MenuContainer = styled.div`
  padding: 1.25rem 1.5rem 1.25rem 1.25rem;
  color: ${Color.black};
  min-width: 22rem;
  max-height: 90vh;
`

export const FavoriteContainer = styled.div`
  display: flex;
  align-items: center;

  > li {
    white-space: pre-wrap;
  }
`

export const FavoriteTypeContainer = styled.div`
  padding-left: 1.5rem;
  overflow: scroll;
`

export const TitleContainer = styled.div<{ margin?: string }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  height: 44px;
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `};
`

export const ModalContentContainer = styled.div`
  min-width: 34.375rem;
  padding: 1rem;
`

export const StyledTextField = styled(TextField)`
  margin-bottom: 2rem;
  && {
    width: 100%;
  }
`

export const ModalPanelVerticalDivisor = styled.div`
  background: ${Color.gray20};
  width: 1px;
`

export const FavoritesVerticalDivisor = styled.div`
  background: ${Color.gray20};
  width: 1px;
`

export const SmallHeading = styled.div`
  /* margin-left: '1rem'; */
`

export const SmallHeadingIndented = styled.div``

export const MyFavoritesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.25rem;
`

export const StyledAddPageButton = styled(Button)`
  margin-left: -0.75rem;
`

export const FeedbackErrorText = styled(
  ({ shouldDisplay, ...otherProps }: { shouldDisplay: boolean; [key: string]: any }) => (
    <div {...otherProps} />
  )
)`
  visibility: ${props => (props.shouldDisplay ? 'visible' : 'hidden')};
  opacity: ${props => (props.shouldDisplay ? 1 : 0)};
  transition: opacity 0.2s linear;
  color: ${Color.red10};
`

export const TooltipItemValue = styled.div`
  margin-left: 0.5rem;
`
