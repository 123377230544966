import React from 'react'
import Typography from 'components/shared/typography'
import Icon, { IconName } from 'components/shared/icon'
import { LoadingSpinner } from 'components/shared/LoadingSpinner'
import { Badge } from 'components/shared/Badge'
import * as S from './styles'

export interface MenuItemObject {
  label: string
  onClick: () => void
  icon?: IconName
  iconColor?: string
  disabled?: boolean
  isDestructive?: boolean
  isLoading?: boolean
  'data-test'?: string
  'data-pho'?: string
  badgeCount?: number
}

export interface MenuItemProps extends MenuItemObject {
  toggleOpen: () => void
  isDarkMode: boolean
}

export const MenuItem = ({
  onClick,
  label,
  icon,
  iconColor,
  disabled = false,
  isDestructive = false,
  isLoading = false,
  toggleOpen,
  isDarkMode,
  badgeCount,
  ...props
}: MenuItemProps) => {
  const textColor = isDestructive
    ? 'red20'
    : disabled && isDarkMode
    ? 'gray30'
    : disabled
    ? 'gray25'
    : ''

  function handleClick() {
    onClick()
    toggleOpen()
  }
  return (
    <S.MenuItem
      onClick={!disabled ? handleClick : undefined}
      $isDarkMode={isDarkMode}
      data-test={props['data-test'] || label}
      disabled={disabled}
    >
      <S.LeftSideContainer>
        {isLoading && <LoadingSpinner small />}
        {!isLoading && icon && (
          <Icon name={icon} color={iconColor || textColor} margin={{ right: '18px' }} />
        )}
        <Typography variant="body" noMargin color={textColor}>
          {label}
        </Typography>
      </S.LeftSideContainer>

      {badgeCount && (
        <S.BadgeContainer>
          <Badge color="red" value={badgeCount} />
        </S.BadgeContainer>
      )}
    </S.MenuItem>
  )
}
