import React, { useState } from 'react'
import { deburr, isEmpty, isFunction, isEqual, get } from 'lodash'
import Autosuggest from 'react-autosuggest'
import { withStyles } from '@material-ui/core/styles'
import colors from '../bit/utils/colors'
import Input from './Input'
import Suggestion from './Suggestion'
import * as S from './styled'
function InputContainer(
  props: InputContainerProps
): React.FunctionComponentElement<InputContainerProps> {
  const { helperText, label, color, classes, className, shouldRenderSuggestions, ...otherProps } =
    props

  const [value, setValue] = useState(props.value || props.defaultValue || '')
  const [isFocused, setIsFocused] = useState(false)
  const [cachedSuggestions, setCachedSuggestions] = useState(props.suggestions)
  const [suggestions, setSuggestions] = useState<Array<string | SuggestionType> | undefined>([])
  const [popperNode, setPopperNode] = useState<HTMLElement | null>(null)

  function handleCaching() {
    if (!isEqual(props.suggestions, cachedSuggestions)) {
      setCachedSuggestions(props.suggestions)
      setSuggestions(getSuggestions(value, props.suggestions))
    }
  }

  function handleSuggestionsFetchRequested({ value }: { value: string }) {
    if (!isEmpty(props.suggestions)) {
      setSuggestions(getSuggestions(value, props.suggestions))
    }
  }

  function handleSuggestionsClearRequested() {
    if (!isEmpty(props.suggestions)) {
      setSuggestions([])
    }
  }

  function handleAutosuggestChange(
    e: React.ChangeEvent<HTMLInputElement>,
    { newValue }: { newValue: string }
  ) {
    change(newValue)
  }

  function handleChange(val: any) {
    const actualVal = get(val, 'target.value', val)
    change(actualVal)
  }

  function change(value: string) {
    setValue(value)

    if (isFunction(props.onChange)) {
      props.onChange(value)
    }
  }

  function handleFocus() {
    setIsFocused(true)

    if (isFunction(props.onFocus)) {
      props.onFocus()
    }
  }

  function handleBlur() {
    setIsFocused(false)

    if (isFunction(props.onBlur)) {
      props.onBlur()
    }
  }

  function getSuggestions(value: any, suggestions?: Array<SuggestionType | string>) {
    const inputValue = deburr(value.trim()).toLowerCase()
    const inputLength = inputValue.length
    let count = 0

    return (
      suggestions &&
      suggestions.filter((suggestion: SuggestionType | string) => {
        const suggestionVal =
          typeof suggestion === 'string' ? suggestion : suggestion && suggestion.value
        const keep = count < 5 && suggestionVal.slice(0, inputLength).toLowerCase() === inputValue

        if (keep) {
          count += 1
        }

        return keep
      })
    )
  }

  function getSuggestionValue(suggestion: SuggestionType | string) {
    return typeof suggestion === 'string' ? suggestion : suggestion.value
  }

  function renderSuggestion(
    suggestion: SuggestionType | string,
    { query, isHighlighted }: { query: any; isHighlighted: boolean }
  ) {
    return <Suggestion suggestion={suggestion} query={query} isHighlighted={isHighlighted} />
  }

  function renderSuggestionsContainer(options: any) {
    return (
      <S.Popper anchorEl={popperNode} open={Boolean(options.children)}>
        <S.Paper
          square
          {...options.containerProps}
          style={{ width: popperNode ? popperNode.clientWidth : null }}
        >
          {options.children}
        </S.Paper>
      </S.Popper>
    )
  }

  const inputProps = {
    ...otherProps,
    helperText,
    label,
    color: color as string,
    isFocused,
    classes,
    onBlur: handleBlur,
    onFocus: handleFocus,
    value,
    className,
  }

  handleCaching()

  if (isEmpty(cachedSuggestions)) {
    return <Input {...inputProps} onChange={handleChange} />
  }

  return (
    <Autosuggest<SuggestionType | string>
      renderInputComponent={(props: any) => <Input refFunc={props.ref} {...props} />}
      renderSuggestion={renderSuggestion}
      shouldRenderSuggestions={shouldRenderSuggestions}
      suggestions={suggestions || []}
      onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
      onSuggestionsClearRequested={handleSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      inputProps={
        {
          ...inputProps,
          inputRef: (node: HTMLElement) => {
            setPopperNode(node || null)
          },
          onChange: handleAutosuggestChange,
        } as any
      }
      theme={{
        suggestionsList: classes && classes.suggestionsList,
        suggestion: classes && classes.suggestion,
      }}
      renderSuggestionsContainer={renderSuggestionsContainer}
      highlightFirstSuggestion
    />
  )
}

interface InputContainerProps {
  helperText?: string
  label?: string
  onChange?: Function
  onBlur?: Function
  onFocus?: Function
  color?: string
  width?: string
  shouldRenderSuggestions?: (value: string) => boolean
  suggestions?: Array<SuggestionType | string>
  value?: any
  defaultValue?: any
  classes?: { suggestionsList: string; suggestion: string }
  className?: string
  type?: string
}

type SuggestionType = { label?: string; value?: any }

InputContainer.defaultProps = {
  color: colors.blue20,
  shouldRenderSuggestions: () => true,
}

export default withStyles(S.styles)(InputContainer)
