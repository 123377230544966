import React, { useState, useEffect } from 'react'
import Icon from '../icon/Icon'
import { primary, neutral } from '../bit/utils/colors'
import * as S from './styles'

function FavoriteIcon({
  handleSelectFavorite,
  index,
  content,
  favorites,
  dataPho,
}: FavoriteIconProps) {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(false)
  }, [favorites])

  if (isLoading) {
    return (
      <div>
        <S.StyledLoadingSpinner small />
      </div>
    )
  }

  if (!handleSelectFavorite) {
    return null
  }

  if (index !== 0) {
    return null
  }

  if (favorites?.includes(content)) {
    return (
      <Icon
        name="StarF"
        style={{ cursor: 'pointer' }}
        color={primary.blue20}
        onClick={() => {
          setIsLoading(true)
          handleSelectFavorite({ value: content, shouldDelete: true })
        }}
        data-pho={dataPho}
      />
    )
  }

  return (
    <Icon
      name="Star"
      style={{ cursor: 'pointer' }}
      color={neutral.gray30}
      onClick={() => {
        setIsLoading(true)
        handleSelectFavorite({ value: content })
      }}
      data-pho={dataPho}
    />
  )
}

interface FavoriteIconProps {
  handleSelectFavorite: Function | undefined
  index: number
  content: any
  favorites?: string[]
  dataPho?: string
}

export default FavoriteIcon
