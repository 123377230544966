import React from 'react'
import { DarkMode, DarkModeContext } from './DarkMode'

export default function useDarkMode(newState?: boolean): DarkMode {
  const { isDarkMode, toggleDarkMode, setIsDarkMode } = React.useContext(DarkModeContext)

  React.useEffect(() => {
    if (newState !== undefined) {
      setIsDarkMode(newState)
    }
  }, [newState]) // eslint-disable-line react-hooks/exhaustive-deps

  return { isDarkMode, toggleDarkMode, setIsDarkMode }
}
