import React from 'react'
import { useLocation } from '@reach/router'
import lodashIsArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import queryString from 'query-string'
import Colors from '../bit/utils/colors'
import Tooltip from '@material-ui/core/Tooltip'
import useFilterValues from 'hooks/useFilterValues'
import useHeader from 'hooks/useHeader'
import useVariantNames from 'hooks/useVariantNames'
import useVocState from 'hooks/useVocState'
import {
  getPillsWithValues,
  getSequenceEventTypeValues,
  getEventTypePillLabel,
  EventValueType,
  getVariantUuidLabel,
  Experiment,
  ExperimentUUID,
  Status,
} from './utils'
import * as S from './styles'
import Flex from '../flex/Flex'
import Spacing from '../bit/utils/spacing'
import { getPathValue } from 'components/shared/FilterMenu/VoCActivationMethodPathFilter'
import services from 'common/services'
import { GET_EXPERIMENTS_IDS } from 'components/shared/FilterMenu/queries'
import useApi from 'hooks/useApi'
import moment from 'moment'
import { getAppNames, getAppTypes } from 'utils/sharedUtils'
import { times } from 'utils'
import useApps from 'hooks/useApps'
import get from 'lodash/get'

const MAX_COUNT_OF_PILL_VALUES_TO_SHOW = 2

export default function PillGroup({
  updateValues,
}: {
  updateValues: (newParamValues: Record<string, any>, initialize?: boolean) => void
}) {
  const location = useLocation()
  const {
    state: { pageName },
  } = useHeader()
  const filterValues = useFilterValues()
  const { variantNames } = useVariantNames()
  const { state } = useVocState()
  const groupBy = queryString.parse((location && location.search) || '').groupBy
  const isVoC = location?.pathname?.includes('/voice-of-customer')
  const pillsWithValues = getPillsWithValues(filterValues, state, isVoC)
  const pillsWithGroupBy = {
    ...pillsWithValues,
    groupBy: groupBy,
  }
  const { currentApps } = useApps()
  const [fromDate, toDate] = times.normalizeToAbsolutePeriod(filterValues.period).split(':')
  const appNames = getAppNames(currentApps)
  const appTypes = getAppTypes(currentApps)

  const defaultApiVariables = {
    appName: appNames,
    appType: appTypes,
    startDate: moment(parseInt(fromDate)).toISOString(),
    endDate: moment(parseInt(toDate)).toISOString(),
  }

  const [apiVariables] = React.useState(defaultApiVariables)

  const experimentData = useApi({
    service: services.distillery,
    query: GET_EXPERIMENTS_IDS,
    variables: {
      where: {
        AND: [
          {
            startTime: {
              gte: apiVariables.startDate,
            },
          },
          {
            startTime: {
              lte: apiVariables.endDate,
            },
          },
        ],
        experimentToProductFeatures: {
          every: {
            product: {
              is: {
                AND: [
                  {
                    quantumApplicationName: {
                      in: apiVariables.appName,
                    },
                  },
                  {
                    quantumApplicationType: {
                      in: apiVariables.appType,
                    },
                  },
                ],
              },
            },
          },
        },
      },
    },
  })

  const status: Status[] = ['RUNNING', 'COMPLETED', 'CANCELLED', 'DELETED', 'TESTING']

  const experiments = get(experimentData, 'data.experiments')
  const filteredExperimentDataByStatus = experiments?.filter((experiment: Experiment) =>
    status.includes(experiment.experimentStatus?.name)
  )

  const variantNameValue = filteredExperimentDataByStatus?.reduce(
    (acc: { label: string; value: string }[], value: ExperimentUUID) => {
      const variantUuids: { label: string; value: string }[] = value?.variants.map(
        (variant: { name: string; uuid: string }) => {
          return { label: `${variant.name}`, value: variant.uuid }
        }
      )
      return acc.concat(variantUuids)
    },
    []
  )

  const pills = groupBy && groupBy !== 'none' ? pillsWithGroupBy : pillsWithValues

  const sequenceEventTypeValues =
    (pageName === 'sequenceVisit' || pageName === 'sequenceTrace') &&
    getSequenceEventTypeValues(filterValues)

  function getShorterPillValue(label: string, value: string[]) {
    if (label === 'Activation Method Path') {
      return value.length <= MAX_COUNT_OF_PILL_VALUES_TO_SHOW
        ? getPathValue(value)
        : `${getPathValue(value)?.slice(0, MAX_COUNT_OF_PILL_VALUES_TO_SHOW)}...`
    } else {
      if (Array.isArray(value) && value?.length > 1) {
        const joinedPillValues = value?.slice(0, MAX_COUNT_OF_PILL_VALUES_TO_SHOW)
        return value.length <= MAX_COUNT_OF_PILL_VALUES_TO_SHOW
          ? joinedPillValues
          : `${joinedPillValues}...`
      }

      return value
    }
  }

  function getVariantNames(pillValues: string | string[]) {
    if (lodashIsArray(pillValues)) {
      return pillValues?.map(pillValue => variantNames?.[pillValue] ?? pillValue)
    }
    if (variantNameValue && pillValues) {
      const variantNameLabel = variantNameValue?.find(
        (variantUUIDSNameValue: { label: string; value: string }) => {
          return variantUUIDSNameValue.value === pillValues
        }
      )
      return variantNameLabel ? [variantNameLabel.label ?? ''] : []
    } else {
      return []
    }
  }
  const experimentVariantUuidValues = filterValues.variantUuids
  const experimentVariantNames = getVariantNames(experimentVariantUuidValues)

  return (
    <S.PillGroupContainer
      isCustomPills={
        sequenceEventTypeValues ||
        (Boolean(filterValues) ? isEmpty(Object.keys(filterValues)) : false)
      }
      data-test="pillGroupContainer"
    >
      <Flex gap={Spacing.element.sm} wrap>
        {map(pills, (value: string[], key: string) => {
          const isGroupByPill = key === 'groupBy'
          const label = isGroupByPill
            ? 'Group By'
            : filterValues.filters.find((option: any) => option.id === key)?.label || key

          const hasMultipleValues =
            value.length > MAX_COUNT_OF_PILL_VALUES_TO_SHOW &&
            !isEmpty(value) &&
            lodashIsArray(value)

          const pillLabel = (
            <div>{`${label}: ${
              isGroupByPill ? startCase(groupBy as string) : getShorterPillValue(label, value)
            }`}</div>
          )

          const tooltipValues = label === 'Activation Method Path' ? getPathValue(value) : value

          const pillTooltip = (
            <Tooltip
              title={
                <S.TooltipContainer>
                  {
                    <S.LeftColumn>
                      {lodashIsArray(tooltipValues) &&
                        tooltipValues?.map((pillValue: string) => <div>{pillValue}</div>)}
                    </S.LeftColumn>
                  }
                </S.TooltipContainer>
              }
            >
              {pillLabel}
            </Tooltip>
          )

          return (
            <S.CustomPill
              key={`${key}_${getShorterPillValue(key, value)}`}
              data-test={isGroupByPill ? 'groupByPill' : 'pill'}
              backgroundColor={isGroupByPill ? Colors.green20 : Colors.gray30}
              color={Colors.white}
            >
              {hasMultipleValues ? pillTooltip : pillLabel}
            </S.CustomPill>
          )
        })}
      </Flex>
      {!isEmpty(sequenceEventTypeValues) && (
        <S.PillContainer>
          <S.CustomPill
            backgroundColor={Colors.blue10}
            color={Colors.white}
            onDelete={(id: any) => {
              updateValues({ hiddenEventTypes: null })
            }}
          >
            <Tooltip
              title={
                <S.TooltipContainer>
                  {sequenceEventTypeValues[0].count && (
                    <S.LeftColumn>
                      {sequenceEventTypeValues.map((value: EventValueType) => (
                        <div>{value.count}</div>
                      ))}
                    </S.LeftColumn>
                  )}
                  <div>
                    {sequenceEventTypeValues.map((value: EventValueType) => (
                      <div>{value.pillLabel}</div>
                    ))}
                  </div>
                </S.TooltipContainer>
              }
            >
              <div>{getEventTypePillLabel(sequenceEventTypeValues)}</div>
            </Tooltip>
          </S.CustomPill>
        </S.PillContainer>
      )}
      {!isEmpty(experimentVariantUuidValues) && (
        <S.PillContainer>
          <S.CustomPill backgroundColor={Colors.gray30} color={Colors.white}>
            <Tooltip
              title={
                <S.TooltipContainer>
                  {
                    <S.LeftColumn>
                      {experimentVariantUuidValues.map((value: any) => (
                        <div>
                          {getVariantNames(value).length > 0
                            ? `${getVariantNames(value)}: ${value}`
                            : `${value}`}
                        </div>
                      ))}
                    </S.LeftColumn>
                  }
                </S.TooltipContainer>
              }
            >
              <div>{getVariantUuidLabel(experimentVariantNames)}</div>
            </Tooltip>
          </S.CustomPill>
        </S.PillContainer>
      )}
    </S.PillGroupContainer>
  )
}
