import colors from '../utils/colors'

export function getAlertIcon(variant: string) {
  if (variant.includes('caution')) {
    return 'CautionAlertF'
  }
  if (variant.includes('info')) {
    return 'InfoCircleF'
  }
  if (variant.includes('success')) {
    return 'CheckmarkCircleF'
  }
  return 'CautionCircleF'
}

export function getAlertColor(variant: string) {
  if (variant.includes('caution')) {
    return colors.yellow20
  }
  if (variant.includes('info')) {
    return colors.lightBlue20
  }
  if (variant.includes('success')) {
    return colors.green20
  }
  return colors.red20
}
