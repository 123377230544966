import React from 'react'
import Flex from 'components/shared/flex/Flex'
import Tab, { TabProps } from './Tab'
import { AddNewTabButton } from './AddNewTabButton'
import * as S from './styles'
import { useDarkMode } from 'components/shared/dark-mode'

export interface TabsProps {
  /** An array of objects to populate the tabs with a required `title<string>` and optional props - `content<React.ReactNode>`, `disabled<boolean>`, `data-pho<string>`  */
  tabs: TabProps[]
  /** Fires when a tab is clicked. First argument is tab title. Second argument is tab index. */
  onClick?: (tab: string, tabIndex: number) => void
  /** Flex justify-content positions for tab list container. Not setting this value defaults to "space-around" */
  tabPosition?: 'center' | 'left' | 'space-between'
  /** Index to use as the activeTabIndex in place of the default 0 index */
  initialTabIndex?: number
  /** Index used to override the state for which tab is active */
  tabIndex?: number
  /** If true, single tab won't render */
  dontRenderSingleTab?: boolean
  actionMenu?: React.ReactNode
  addTabButton?: {
    addTabCallback: Function
    shouldShow: boolean
    disabled?: boolean
    ref?: React.RefObject<HTMLDivElement>
    dataTest?: string
  }
  /** Allows to hide overflow text. Adds ellipsis and tooltip for text */
  overflowTextHasEllipsis?: boolean
  /** Specify data-test for tabList component */
  tabListDataTest?: string
  /** Tab title slice number */
  tabTitleSliceNumber?: number
}

export const Tabs = ({
  tabs,
  onClick,
  tabPosition = 'left',
  initialTabIndex = 0,
  tabIndex,
  actionMenu,
  addTabButton,
  overflowTextHasEllipsis,
  dontRenderSingleTab,
  tabListDataTest,
  tabTitleSliceNumber,
  ...props
}: TabsProps) => {
  const { isDarkMode } = useDarkMode()
  const [activeTabIndex, setActiveTabIndex] = React.useState(initialTabIndex)
  const trueActiveTabIndex = tabIndex === undefined ? activeTabIndex : tabIndex
  const activeTab = tabs[trueActiveTabIndex]
  const activeColor = isDarkMode ? 'white' : 'blue-20'
  const regularColor = isDarkMode ? 'white' : 'black'
  const showAddNewTabButton = addTabButton?.shouldShow

  const handleTabClick = React.useCallback(
    (tab: string, tabIndex: number) => {
      setActiveTabIndex(tabIndex)
      onClick && onClick(tab, tabIndex)
    },
    [setActiveTabIndex, onClick]
  )

  function handleAddNewTab() {
    addTabButton && addTabButton?.addTabCallback()
  }

  const isOnlyOneTab = tabs.length === 1
  const tabShouldNotBeRendered = isOnlyOneTab && dontRenderSingleTab

  const renderTabs = tabs.map((tab: TabProps, index: number) => {
    const tabIndex = tab.customIndex || index
    const isActive = trueActiveTabIndex === tabIndex
    const tabColor = isActive ? activeColor : regularColor

    return tabShouldNotBeRendered ? (
      <></>
    ) : (
      <Tab
        tab={tab}
        index={tabIndex}
        tabColor={tabColor}
        isActive={isActive}
        handleTabClick={handleTabClick}
        overflowTextHasEllipsis={overflowTextHasEllipsis}
        tabTitleSliceNumber={tabTitleSliceNumber}
      />
    )
  })

  const renderAddTabButton = (
    <AddNewTabButton
      isDisabled={addTabButton?.disabled || false}
      handleAddNewTab={handleAddNewTab}
      addTabButtonRef={addTabButton?.ref}
      dataTest={addTabButton?.dataTest}
      tabShouldNotBeRendered={tabShouldNotBeRendered}
    />
  )

  return (
    <S.TabsContainer {...props}>
      <S.TabListContainer
        role="tablist"
        $tabPosition={tabPosition}
        data-test={tabListDataTest ? tabListDataTest : 'tabList'}
      >
        {actionMenu ? (
          <>
            <Flex>
              {renderTabs}
              {showAddNewTabButton && renderAddTabButton}
            </Flex>
            {actionMenu}
          </>
        ) : (
          <>
            {renderTabs}
            {showAddNewTabButton && renderAddTabButton}
          </>
        )}
      </S.TabListContainer>
      <S.TabPanelContainer>
        <S.TabPanel
          key={`tabPanel_${trueActiveTabIndex}`}
          role="tabpanel"
          tabIndex={0}
          aria-labelledby={`tab_${trueActiveTabIndex}`}
        >
          {activeTab?.content}
        </S.TabPanel>
      </S.TabPanelContainer>
    </S.TabsContainer>
  )
}
