import React from 'react'
import RadioButton, { RadioButtonProps } from '../radio-button/RadioButton'
import Flex from '../../flex/Flex'
import * as S from './styles'

export interface RadioButtonGroupProps {
  legend: string
  /** An array of props for the radio buttons. If "initialChecked" or "checked" are used, they will override the group state. */
  radioButtons: RadioButtonProps[]
  /** A string that should match a radio button label. Use this to set an initial state for the checkbox group. (Don't use in conjunction with "checked.") */
  initialChecked?: string
  /** A string that should match a radio button label ("indeterminate" is not an option in RadioButton Groups.) Use this to take control of the checkbox group's state. (Don't use in conjunction with "initialChecked.") */
  checked?: string
  /** Fires when the checkbox group state has changed. The only argument is the new state object of checked radio buttons (by their labels.) */
  onChange?: (checked: string) => void
  required?: boolean
}

export default ({
  legend,
  radioButtons,
  initialChecked,
  checked,
  onChange,
  required,
  ...props
}: RadioButtonGroupProps) => {
  const [checkState, setCheckState] = React.useState(initialChecked)
  const isControlled = checked !== undefined
  const trueCheckState = isControlled ? checked : checkState

  const handleChange = React.useCallback(
    (_, label: string) => {
      onChange && onChange(label)
      setCheckState(label)
    },
    [trueCheckState, setCheckState, onChange]
  )

  return (
    <Flex as="fieldset" padding="0" margin="0" marginBetween="8px" border="none" column {...props}>
      <S.Legend>
        {legend}
        {required && <span>*</span>}
      </S.Legend>
      {radioButtons.map(checkbox => (
        <RadioButton
          key={checkbox.label}
          checked={checkbox.checked ?? trueCheckState === checkbox.label}
          onChange={handleChange}
          disabled={checkbox.disabled}
          required={checkbox.required}
          label={checkbox.label}
        />
      ))}
    </Flex>
  )
}
