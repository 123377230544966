export enum ServiceType {
  REST = 'REST',
  GraphQl = 'GRAPHQL',
}

export const services = {
  filteredTestCases: {
    url: '/filtered-test-cases',
    type: ServiceType.GraphQl,
  },
  distillery: {
    url: '/distillery',
    type: ServiceType.GraphQl,
  },
  druid: {
    url: '/api/druid',
    type: ServiceType.REST,
  },
  druidFront: {
    url: '/druid-front',
    type: ServiceType.REST,
  },
  druidInfo: {
    url: '/druid/info',
    type: ServiceType.REST,
  },
  druidMetrics: {
    url: '/druid/metrics',
    type: ServiceType.REST,
  },
  helixVersions: {
    url: '/helix-versions',
    type: ServiceType.REST,
  },
  helixRequirement: {
    url: '/helix-requirement',
    type: ServiceType.REST,
  },
  helixReport: {
    url: '/helix-report',
    type: ServiceType.REST,
  },
  apiNameMappings: {
    url: '/api-name-mappings',
    type: ServiceType.REST,
  },
  apiWarpNameMappings: {
    url: '/api-name-mappings/warp',
    type: ServiceType.REST,
  },
  history: {
    url: '/history',
    type: ServiceType.REST,
  },
  hiveDatabases: {
    url: '/hive/databases',
    type: ServiceType.REST,
  },
  hiveTables: {
    url: '/hive/tables',
    type: ServiceType.REST,
  },
  hiveTableDetail: {
    url: '/hive/tableDetails',
    type: ServiceType.REST,
  },
  realtimeDashboardApi: {
    url: '/realtime-dashboard',
    type: ServiceType.REST,
  },
  sequence: {
    url: '/sequence',
    type: ServiceType.REST,
  },
  users: {
    url: '/users',
    type: ServiceType.REST,
  },
  openSearchCharts: {
    url: '/elastic-search-charts',
    type: ServiceType.REST,
  },
  applications: {
    url: '/applications',
    type: ServiceType.REST,
  },
  dashboards: {
    url: '/dashboards',
    type: ServiceType.REST,
  },
  metric: {
    url: '/metric',
    type: ServiceType.REST,
  },
  metrics: {
    url: '/metrics',
    type: ServiceType.REST,
  },
  groups: {
    url: '/groups',
    type: ServiceType.REST,
  },
  techMobile: {
    url: '/tech-mobile',
    type: ServiceType.REST,
  },
  techMobileWorkOrders: {
    url: '/tech-mobile-work-orders',
    type: ServiceType.REST,
  },
  techMobileWorkOrdersJourney: {
    url: '/tech-mobile-work-orders-journey',
    type: ServiceType.REST,
  },
  techMobileTechnicianInfo: {
    url: '/tech-info',
    type: ServiceType.REST,
  },
  tableau: {
    url: '/tableau',
    type: ServiceType.REST,
  },
  multiChart: {
    url: '/multi-chart',
    type: ServiceType.REST,
  },
  version: {
    url: '/version',
    type: ServiceType.REST,
  },
  gitlabApi: {
    url: '/gitlab-api',
    type: ServiceType.REST,
  },
  webexApi: {
    url: '/webex-api',
    type: ServiceType.REST,
  },
  internalPermissions: {
    url: '/permissions',
    type: ServiceType.REST,
  },
  transformer: {
    url: '/transformer',
    type: ServiceType.REST,
  },
  roku: {
    url: '/roku',
    type: ServiceType.REST,
  },
  prismUIFilters: {
    url: '/prism-ui-filters',
    type: ServiceType.REST,
  },
  volt: {
    url: '/volt',
    type: ServiceType.REST,
  },
  shortUrl: {
    url: 'short-url',
    type: ServiceType.REST,
  },
  requirementsVersionSwap: {
    url: '/requirements-version-swap',
    type: ServiceType.REST,
  },
  userAccessLog: {
    url: '/user-access',
    type: ServiceType.REST,
  },
}

export interface ServiceDefinition {
  url: string
  type: ServiceType
  headers?: any
}

export const debug = (obj1: unknown, obj2?: unknown) => console.log(obj1, obj2)

export default services
