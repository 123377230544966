import { createGlobalStyle } from 'styled-components'
import colors from '../../utils/colors'
import { typography } from '../../../typography'

export default createGlobalStyle`
  body {
    min-width: 320px;
    background-color: ${({ theme }) =>
      (theme as any)?.isDarkMode ? colors.darkMode.background : colors.lightMode.background};
  }

  ${typography}
`
