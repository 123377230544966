import React from 'react'
import DarkModeProvider from '../dark-mode/DarkMode'
import ThemeProvider from '../theme'
import ViewportSizeProvider from '../viewport-size'

const AllProviders: React.FC = ({ children }) => (
  <DarkModeProvider>
    <ThemeProvider>
      <ViewportSizeProvider>{children}</ViewportSizeProvider>
    </ThemeProvider>
  </DarkModeProvider>
)

export default AllProviders
