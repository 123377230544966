import styled, { css } from 'styled-components'
import colors from 'components/shared/utils/colors'
import Icon from 'components/shared/icon/Icon'
import { Button } from 'components/shared/Button'
import NucleusReactPillGroup from 'components/shared/PillGroupNR'
import { Switch } from 'components/shared/Switch'
import Flex from 'components/shared/flex/Flex'

const DOUBLE_CALENDAR_WIDTH = '632px'
const ONE_CALENDAR_WIDTH = '300px'
const TWO_COLUMN_WIDTH = '500px'
const ONE_COLUMN_WIDTH = '326px'

export const AutocompleteContainer = styled.div`
  color: ${colors.black};
  padding: 0 1rem;
  margin-bottom: 8px;
`

export const CustomStepWarning = styled.div`
  color: ${colors.red10};
  margin-bottom: 0.2rem;
  font-size: smaller;
`

export const DatePickerContainer = styled.div<{ isVoC?: boolean }>`
  margin: ${({ isVoC }) => (isVoC ? '1rem 0' : '1rem')};
  min-width: ${({ isVoC }) => (isVoC ? DOUBLE_CALENDAR_WIDTH : ONE_CALENDAR_WIDTH)};
  max-width: ${({ isVoC }) => (isVoC ? DOUBLE_CALENDAR_WIDTH : ONE_CALENDAR_WIDTH)};
  && div {
    width: 100%;
  }
  && div > div {
    width: unset;

    ${({ isVoC }) =>
      isVoC &&
      css`
        gap: 1rem;
      `};
  }
  && div > div > div > div {
    width: ${({ isVoC }) => (isVoC ? ONE_CALENDAR_WIDTH : '100%')};
  }
  && div > div > div > div > div {
    width: ${({ isVoC }) => (isVoC ? ONE_CALENDAR_WIDTH : 'unset')};
  }
`

export const ErrorMessage = styled.div`
  color: ${colors.red10};
  margin: 0.2rem 0 0 0.5rem;
  font-size: 0.9rem;
`

export const FilterContainer = styled.div`
  color: ${colors.black};
  padding: 0 0.5rem;
  display: flex;
`

export const PageFilterContainer = styled.div`
  display: flex;
  min-width: max-content;
  && > button > svg {
    color: ${colors.black};
    margin: 0 0.5rem;
  }
  && > button {
    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
`

export const PillGroup = styled(NucleusReactPillGroup)`
  margin-top: 1rem;
`

export const StepSliderContainer = styled.div`
  margin: 0.5rem 1.5rem 0.5rem 1.5rem;
`

export const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 1rem 1rem;
  width: 100%;
`

export const StyledFilterMenuContainer = styled.div`
  display: flex;
  font-size: 1rem;

  && button span {
    padding-left: 0.5rem;
    color: ${colors.black};
    margin: 0 0px;
  }

  && > button > svg {
    color: ${colors.black};
    margin: 0 0.5rem;
    padding-right: 0.1rem;
  }

  && > button {
    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
`

export const StyledInfoIcon = styled(Icon).attrs({
  name: 'InfoCircle',
})`
  margin: 0.25rem;
  vertical-align: top;
  width: 0.9rem;
`

export const Tab = styled.div<{ isActive?: boolean }>`
  padding: 0.8rem 0;
  width: 50%;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.9rem;
  ${props => (!props.isActive ? `padding-bottom: calc(.8rem + 2px);` : ``)}
  ${props => (props.isActive ? `color: ${colors.blue20};` : `color: ${colors.gray30};`)}
  ${props =>
    props.isActive ? `border-bottom: solid 2px ${colors.blue20};` : `border-bottom: none`}
`

export const TabContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-evenly;
  width: 100;
  margin-bottom: 0.3rem;
`

export const TimeListContainer = styled.div<{ numberOfFilters: number }>`
  padding: ${props => (props.numberOfFilters === 1 ? '0.5rem 0rem 0.5rem 1rem;' : '0.5rem 1rem;')};
  color: ${colors.black};
  width: 240px;
  min-height: ${props => (props.numberOfFilters > 6 ? '9rem' : '7rem')};
  display: grid;
  grid-template-columns: ${props =>
    props.numberOfFilters > 3 ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)'};
  grid-template-rows: ${props =>
    props.numberOfFilters > 6 ? 'repeat(4, auto)' : 'repeat(3, auto)'};
  grid-auto-flow: column;
`

export const TimeMenuContainer = styled.div<{ isTwoColumns?: boolean; isVoC?: boolean }>`
  width: ${({ isTwoColumns, isVoC }) => {
    if (isVoC) return 'unset'
    if (isTwoColumns) return TWO_COLUMN_WIDTH
    return ONE_COLUMN_WIDTH
  }};
`

export const StyledTimeMenuButton = styled.span`
  padding-left: 7px;
`

export const TimeMenuTitle = styled.div`
  padding: 1rem 1rem 0 1rem;
  font-weight: 600;
`

export const WeekRangeFootnote = styled.div`
  color: ${colors.gray30};
  font-size: 0.7rem;
  margin: 0.5rem 1rem;
`

export const TimeListItem = styled.span<{ isActive?: Boolean }>`
  padding: 0.25rem 0;
  cursor: pointer;
  font-size: 0.875rem;
  color: ${props => props.isActive && colors.blue20};
  &:hover {
    color: ${colors.blue20};
  }
`

export const VoCTimeListItem = styled.span<{ isActive?: Boolean }>`
  padding: 0.5rem 0;
  cursor: pointer;
  font-size: 0.875rem;
  color: ${props => props.isActive && colors.blue20};
  &:hover {
    color: ${colors.blue20};
  }
`

export const TimePeriod = styled.div`
  margin-bottom: 1rem;
`

export const TimePeriodContainer = styled.div<{ isTwoColumns: boolean }>`
  border-bottom: solid 1px ${colors.gray20};
  display: grid;
  grid-auto-flow: column;
  & > div:last-of-type {
    border-left: ${props => (props.isTwoColumns ? `solid 1px ${colors.gray20}` : 'none')};
  }
`

export const SidebarTimePeriodContainer = styled.div`
  border-bottom: solid 1px ${colors.gray20};
  display: flex;
  flex-flow: column nowrap;
`

export const TimePeriodHeader = styled.div`
  padding: 1rem 1rem 0 1rem;
  font-weight: 600;
`

export const StyledSwitchContainer = styled.div`
  display: flex;
  padding: 0.5rem;

  > div:nth-child(1) {
    margin-right: 3rem;
  }
`

export const TimePeriodSubheader = styled.div`
  padding: 1rem 1rem 0 1rem;
`

export const SidebarTimePeriodSubheader = styled.div`
  padding: 1rem 1rem 0 0;
  font-size: 0.9rem;
  font-weight: 500;
`

export const CurrentSelection = styled.div`
  font-size: 0.8rem;
  margin-top: 0.4rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
`

export const StyledIcon = styled(Icon)`
  align-self: center;
`

export const StyledSwitch = styled(Switch)`
  margin-right: 0.5rem;
`

export const DateInputContainer = styled(Flex)`
  border-top: 1px solid ${colors.gray20};
`
