import React, { useRef, useState } from 'react'
import queryString from 'query-string'
import { ResponsiveLine } from '@nivo/line'
import useFilterValues from 'hooks/useFilterValues'
import useVariantNames from 'hooks/useVariantNames'
import { useLocation } from '@reach/router'
import Spacing from '../bit/utils/spacing'
import Flex from '../flex/Flex'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import defaults from 'lodash/defaults'
import get from 'lodash/get'
import { times } from 'utils'
import { FormattedLineData } from 'components/shared/ChartData/types'
import NoDataChartMessage from 'components/shared/NoDataChartMessage'
import CustomLegend from 'components/shared/customLegend'
import LineChartTooltip from './LineChartTooltip'
import * as T from './types'
import * as U from './utils'
import * as S from './styled'
import { useDarkMode } from '../dark-mode'

const STEP_REGEX = /(^[0-9]{1,})$/i

function LineChart({
  data = { lineData: [] as FormattedLineData },
  min,
  max,
  yFormat,
  tooltipFormat,
  legends,
  customLegend = false,
  legendTitle,
  tooltipPointTitle,
  margin,
  title,
  colors,
  isStacked = false,
  isAreaChart = false,
  Container = S.Container,
  customLegendClickPathObj,
  yMinScaleIsAuto,
  hasTabButtons,
  isSmall,
  origintz,
  customFilters,
}: T.LineChartProps) {
  const filterValues = useFilterValues()
  const location = useLocation()
  const { isDarkMode } = useDarkMode()
  const { variantNames } = useVariantNames()

  const [chartColors, setChartColors] = useState(colors)
  const [hoveredColor, setHoveredColor] = useState<null | string>(null)
  const [tooltipYOffset, setTooltipYOffset] = useState(0)
  const [chartHeight, setChartHeight] = useState(0)

  const tooltipRef = useRef(null)
  const ChartContainerRef = useRef(null)

  React.useEffect(() => {
    const height =
      ChartContainerRef &&
      ChartContainerRef?.current &&
      get(ChartContainerRef, 'current.offsetHeight')
    if (height && height !== chartHeight) {
      setChartHeight(height)
    }
  }, [chartHeight, data])

  const query = queryString.parse(location.search || '')

  const chartTheme = U.getChartTheme(isDarkMode)
  const isVocChart = location.pathname.includes('/voice-of-customer')
  const validCustomTimeFilters = U.timeMenuFilters.filter(value => {
    const customFilter = customFilters && customFilters[value]
    return !isNil(customFilter)
  })
  const filterValuesToUse = isEmpty(validCustomTimeFilters)
    ? filterValues
    : defaults({}, customFilters, filterValues)

  const { period, step } = filterValuesToUse
  const numericalStep = STEP_REGEX.test(step)

  function handleMouseMove(e: { nativeEvent: { offsetY: HTMLInputElement } }) {
    const { halfTooltipHeight, halfChartHeight } = U.getHalfHeights(tooltipRef, ChartContainerRef)
    const mouseYPos: any = e.nativeEvent.offsetY

    if (halfChartHeight && halfTooltipHeight) {
      if ((halfChartHeight as any) > mouseYPos) {
        const yOffset = halfTooltipHeight
        if (tooltipYOffset !== yOffset) {
          setTooltipYOffset(yOffset)
        }
      } else if ((halfChartHeight as any) < mouseYPos) {
        const yOffset = -halfTooltipHeight
        if (tooltipYOffset !== yOffset) {
          setTooltipYOffset(yOffset)
        }
      }
    }
  }

  function setLegendHoverColor(legendColor: string) {
    const highlightHovered = colors?.map(color => {
      return color === legendColor ? color : '#0000000'
    })
    setChartColors(highlightHovered)
    setHoveredColor(legendColor)
  }

  function resetLegendHoverColor() {
    setChartColors(colors)
    setHoveredColor(null)
  }

  const commonProps = {
    data: data?.lineData ?? [],
    colors: chartColors,
    pointSize: U.getPointSize(data),
    pointColor: ({ color }: { color: string }) => U.getPointColor(color, hoveredColor),
  }

  const yScaleStacked = isStacked ? { stacked: true } : {}
  const yScaleMin = yMinScaleIsAuto ? ('auto' as 'auto') : (min as number) ?? (0 as number)

  if (
    isEmpty(data?.lineData) ||
    isEmpty(data?.lineData?.[0]?.data) ||
    U.checkIfAllDataIsNull(data)
  ) {
    return <NoDataChartMessage />
  }

  if (isSmall) {
    return (
      <S.Container dataPho={`${title}-${yScaleMin === 'auto' ? 'Zoomed' : 'NotZoomed'}`}>
        <ResponsiveLine
          {...commonProps}
          margin={margin || { top: 4, right: 4, bottom: 9, left: 4 }}
          xScale={{
            type: 'time',
            format: '%Q',
            precision: 'millisecond',
          }}
          xFormat="time:%b %d"
          yScale={{
            type: 'linear',
            min: yScaleMin,
            max: yMinScaleIsAuto ? ('auto' as 'auto') : (max as 'auto' | number),
            ...yScaleStacked,
          }}
          yFormat={yFormat || ',d'}
          enableGridX={false}
          gridYValues={5}
          enableSlices="x"
          axisLeft={null}
          axisRight={{
            tickValues: 5,
            tickSize: 4,
            tickPadding: 8,
            format: yFormat || '~s',
          }}
          axisBottom={null}
          crosshairType="x"
          useMesh
          theme={chartTheme}
          sliceTooltip={({ slice }: any) => {
            return (
              <LineChartTooltip
                tooltipRef={tooltipRef}
                slice={slice}
                step={step}
                numericalStep={numericalStep}
                variantNames={variantNames}
                query={query}
                tooltipPointTitle={tooltipPointTitle}
                tooltipFormat={tooltipFormat}
              />
            )
          }}
        />
      </S.Container>
    )
  }

  const timestamp = isVocChart
    ? times.normalizeToAbsolutePeriodVoC(period as string)
    : times.normalizeToAbsolutePeriod(period as string)
  let lineData = undefined
  if (data?.lineData) {
    lineData = data.lineData[0]?.data
  }

  const tickValues = U.getTickValues({
    timestamp,
    lineData,
    origintz,
    numericalStep,
    step,
    isVocChart,
  })
  const labelsArray = U.createLabelsArray(data, variantNames, query)

  return (
    <>
      <div style={{ flexGrow: 1, height: '100%' }} ref={ChartContainerRef}>
        <Container
          onMouseMove={(e: any) => {
            handleMouseMove(e)
          }}
          tooltipYOffset={tooltipYOffset}
          dataPho={`${title}-${yScaleMin === 'auto' ? 'Zoomed' : 'NotZoomed'}`}
        >
          <ResponsiveLine
            key={`${labelsArray.length}_${U.getLineChartKey(data.lineData as any)}`}
            {...commonProps}
            theme={chartTheme}
            margin={margin || { top: 4, right: 36, bottom: 30, left: 18 }}
            xScale={{
              type: 'time',
              format: '%Q',
              precision: 'millisecond',
            }}
            xFormat="time:%b %d"
            yScale={{
              type: 'linear',
              min: yScaleMin,
              max: max as 'auto' | number,
              ...yScaleStacked,
            }}
            yFormat={yFormat || ',d'}
            axisBottom={{
              format: (value: any) => U.getAxisBottomValue(value, tickValues),
              tickValues: tickValues,
              tickSize: 4,
              tickPadding: 8,
            }}
            enableGridX={false}
            gridYValues={U.getAxisRightTickValues(chartHeight)}
            enableSlices="x"
            axisLeft={null}
            axisRight={{
              tickValues: U.getAxisRightTickValues(chartHeight),
              tickSize: 4,
              tickPadding: 8,
              format: yFormat || '~s',
            }}
            crosshairType="x"
            legends={legends}
            sliceTooltip={({ slice }: any) => {
              return (
                <LineChartTooltip
                  tooltipRef={tooltipRef}
                  slice={slice}
                  step={step}
                  numericalStep={numericalStep}
                  variantNames={variantNames}
                  query={query}
                  tooltipPointTitle={tooltipPointTitle}
                  tooltipFormat={tooltipFormat}
                />
              )
            }}
            useMesh
            enableArea={isAreaChart}
            lineWidth={isAreaChart ? 0 : 2}
          />
        </Container>
      </div>
      <Flex margin={`${Spacing.element.sm}`}>
        {customLegend && (
          <CustomLegend
            data={legendTitle ? legendTitle : labelsArray}
            customLegendClickPathObj={customLegendClickPathObj}
            colors={colors}
            withoutAbsolutePosition={hasTabButtons}
            setLegendHoverColor={setLegendHoverColor}
            resetLegendHoverColor={resetLegendHoverColor}
          />
        )}
      </Flex>
    </>
  )
}

export default LineChart
