import Typography from '../typography/Typography'
import styled from 'styled-components'
import StarIcon from '@material-ui/icons/Star'
import colors from '../bit/utils/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const MainContainer = styled.div`
  display: flex;
  min-width: 75%;
  flex-direction: column;
  align-items: center;
`

export const AccBodyContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  gap: 0.5rem;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 2rem;
`

export const AccordionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column wrap;
  align-items: flex-start;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fff;
`

export const ButtonsContainer = styled.div<{ isNewUser: boolean }>`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: ${props => (props.isNewUser ? 'space-between' : 'center')};
`

export const AccEntryContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const WelcomeCopy = styled.div`
  font-size: 1.2rem;
  margin-bottom: 45px;
  max-width: 500px;
  text-align: center;
`

export const ModalTitle = styled(Typography)``

export const FavoritesTitle = styled(Typography)``

export const ModalSubtitle = styled.div`
  font-size: 1.1rem;
  margin-bottom: 30px;
  margin-top: 4px;
`

export const StyledAlertContainer = styled.div`
  margin: 0.5rem 0;
  width: 100%;
`
export const StyledStarIcon = styled(StarIcon)`
  color: ${colors.blue10};
`
