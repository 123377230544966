import styled from 'styled-components'
import elevation from '../bit/utils/elevation'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

const squareSize = '40px'

export const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    maxWidth: '800px',
  },
}))(Tooltip)

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
`

export const Colorblock = styled.div<{ color?: string }>`
  width: 0.75rem;
  height: 0.75rem;
  background-color: ${props => props.color};
  margin-right: 0.5rem;
`

export const GridSquare = styled.div<{ color: string }>`
  width: ${squareSize};
  height: 30px;
  background-color: ${props => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    && {
      opacity: 1;
      ${elevation.lightMode[3]};
    }
  }
`

export const GridContainer = styled.div<{ columnCount: number }>`
  display: grid;
  grid-gap: 2px;
  grid-template-columns: repeat(${props => props.columnCount}, ${squareSize});
  width: 100%;
  height: 100%;
`

export const GridNumber = styled.span`
  font-size: 0.65rem;
  font-weight: 600;
`

export const GridHover = styled.div`
  &:hover > div > div {
    opacity: 0.5;
  }
`

export const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
`
