import React, { useEffect, useReducer, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import keyMirror from 'keymirror'
import { LoadingSpinner } from 'components/shared/LoadingSpinner'
import ErrorMessage from 'components/shared/ErrorMessage'
import { queries, types } from 'components/voiceOfCustomer'
import { getVocClient } from 'components/voiceOfCustomer/utils'
import useFilterValues from 'hooks/useFilterValues'
import useApps from 'hooks/useApps'
import {
  CategoryGroupEntry,
  ProcessedCategoryGroupData,
  VocContextValue,
  VocFilterOption,
  VocStateProps,
} from './'
import usePlatform from 'hooks/usePlatform'
import { CategoryModel, ManualCategory } from 'components/voiceOfCustomer/types'
import isNil from 'lodash/isNil'

export const VocStateContext = React.createContext<VocContextValue>({} as VocContextValue)
export const VocStateProvider = VocStateContext.Provider

export const actions = keyMirror({
  UPDATE_CATEGORIES_DATA: null,
  UPDATE_CATEGORY_OPTIONS: null,
  UPDATE_CATEGORY_FILTERS: null,
  UPDATE_CATEGORY_GROUP_DATA: null,
  UPDATE_CATEGORY_GROUP_OPTIONS: null,
  UPDATE_APP_IDS: null,
  UPDATE_MANUAL_CATEGORIES: null,
  UPDATE_MIDDLE_CATEGORY_OPTIONS: null,
  UPDATE_MIDDLE_CATEGORY_FILTERS: null,
  RESET_STATE: null,
})

const INITIAL_STATE = {
  categoriesData: [],
  categoryOptions: [],
  middleCategoryOptions: [],
  categoryFilters: { id: 'vocCategory', label: 'Category', type: 'AUTOCOMPLETE', options: [] },
  middleCategoryFilters: {
    id: 'vocMidCategory',
    label: 'Mid-Level Category',
    type: 'AUTOCOMPLETE',
    options: [],
  },
  surveyIDFilters: {
    id: 'surveyID',
    label: 'Survey ID',
    type: 'AUTOCOMPLETE',
    resetOnEntityChange: true,
    options: [],
  },
  applicationIDs: [],
  processedCategoryGroupData: {},
  categoryGroupOptions: [],
  manualCategories: [],
}

function timePeriodReducer(state: VocStateProps = INITIAL_STATE, action: Record<string, any>) {
  switch (action.type) {
    case actions.UPDATE_CATEGORIES_DATA:
      return { ...state, categoriesData: action.payload }
    case actions.UPDATE_CATEGORY_OPTIONS:
      return { ...state, categoryOptions: action.payload }
    case actions.UPDATE_MIDDLE_CATEGORY_OPTIONS:
      return { ...state, middleCategoryOptions: action.payload }
    case actions.UPDATE_MIDDLE_CATEGORY_FILTERS:
      return { ...state, middleCategoryFilters: action.payload }
    case actions.UPDATE_APP_IDS:
      return { ...state, applicationIDs: action.payload }
    case actions.UPDATE_CATEGORY_FILTERS:
      return { ...state, categoryFilters: action.payload }
    case actions.UPDATE_CATEGORY_GROUP_DATA:
      return { ...state, processedCategoryGroupData: action.payload }
    case actions.UPDATE_CATEGORY_GROUP_OPTIONS:
      return { ...state, categoryGroupOptions: action.payload }
    case actions.UPDATE_MANUAL_CATEGORIES:
      return { ...state, manualCategories: action.payload }
    case actions.RESET_STATE:
      return { ...INITIAL_STATE, ...action.payload }
    default:
      return state
  }
}

export function VocState({ children }: { children: React.ReactNode }) {
  const { currentApps: applicationIDs } = useApps()
  const { environment } = useFilterValues()
  const platform = usePlatform()
  const [state, dispatch] = useReducer(timePeriodReducer, { ...INITIAL_STATE, applicationIDs })

  const client = getVocClient(environment as string)

  const [
    fetchCategories,
    {
      loading: categoriesLoading,
      error: categoriesError,
      data: categoriesData,
      refetch: categoriesRefetch,
    },
  ] = useLazyQuery<{
    getCategories: CategoryModel[]
  }>(queries.GET_CATEGORIES, {
    client,
    fetchPolicy: 'network-only',
  })

  const [
    fetchManualCategory,
    { loading: manualCategoriesLoading, error: manualCategoriesError, data: manualCategoriesData },
  ] = useLazyQuery<{
    getManualCategories: ManualCategory[]
  }>(queries.GET_MANUAL_CATEGORIES, {
    client,
    fetchPolicy: 'network-only',
  })

  const [
    fetchCategoryGroups,
    {
      loading: categoryGroupsLoading,
      error: categoryGroupsError,
      data: categoryGroupData,
      refetch: categoryGroupsRefetch,
    },
  ] = useLazyQuery<{
    getCategoryGroups: CategoryGroupEntry[]
  }>(queries.GET_CATEGORY_GROUPS, {
    client,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!applicationIDs?.every(appID => platform?.applicationIDs.includes(appID)))
      dispatch({ type: actions.RESET_STATE, payload: { applicationIDs } })
    
  }, [applicationIDs, platform]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (platform?.id) {
      fetchCategories({ variables: { applicationIDs: platform?.applicationIDs } })
      fetchManualCategory({ variables: { applicationIDs: platform?.applicationIDs } })
      fetchCategoryGroups({ variables: { applicationIDs: platform?.applicationIDs } })
    }
  }, [platform]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const processedCategoryGroupData = categoryGroupData?.getCategoryGroups?.reduce(
      (acc: ProcessedCategoryGroupData, categoryGroup: CategoryGroupEntry) => {
        const { _id: id, categoryOptions } = categoryGroup

        return { ...acc, [id]: categoryOptions }
      },
      {}
    )
    const categoryGroupOptions = categoryGroupData?.getCategoryGroups?.map(
      (categoryGroup: CategoryGroupEntry) => {
        const { _id: id, categoryGroupName } = categoryGroup
        return { label: categoryGroupName, value: id }
      }
    )

    dispatch({ type: actions.UPDATE_CATEGORY_GROUP_OPTIONS, payload: categoryGroupOptions ?? [] })
    dispatch({
      type: actions.UPDATE_CATEGORY_GROUP_DATA,
      payload: processedCategoryGroupData ?? {},
    })
  }, [categoryGroupData, platform])

  useEffect(() => {
    const categories = categoriesData?.getCategories
    const categoryOptions = categories?.reduce<{
      topLevelCategoryOptions: VocFilterOption[]
      midLevelCategoryOptions: VocFilterOption[]
    }>(
      (acc, category: types.CategoryModel) => {
        if (category.isArchived === true || category?.hasArchivedParent) return acc
        if (!isNil(category?.parent)) {
          const parentCategory = categories.find(
            (c: types.CategoryModel) => c._id.toString() === category.parent?.toString()
          )
          const catOption = {
            label: `${category.category} (${parentCategory && parentCategory.category})`,
            value: category._id,
            parent: category.parent,
            isArchived: category.isArchived || category?.hasArchivedParent,
          }
          acc.midLevelCategoryOptions.push(catOption)
        } else {
          const catOption = {
            label: `${category.category}`,
            value: category._id,
            isArchived: category.isArchived,
          }
          acc.topLevelCategoryOptions.push(catOption)
        }

        return acc
      },
      { topLevelCategoryOptions: [], midLevelCategoryOptions: [] }
    )
    dispatch({ type: actions.UPDATE_CATEGORIES_DATA, payload: categoriesData?.getCategories ?? [] })
    dispatch({
      type: actions.UPDATE_CATEGORY_OPTIONS,
      payload: categoryOptions?.topLevelCategoryOptions ?? [],
    })
    dispatch({
      type: actions.UPDATE_MIDDLE_CATEGORY_OPTIONS,
      payload: categoryOptions?.midLevelCategoryOptions ?? [],
    })
    dispatch({
      type: actions.UPDATE_CATEGORY_FILTERS,
      payload: {
        id: 'vocCategory',
        label: 'Top-Level Category',
        type: 'AUTOCOMPLETE',
        options: categoryOptions?.topLevelCategoryOptions ?? [],
      },
    })
    dispatch({
      type: actions.UPDATE_MIDDLE_CATEGORY_FILTERS,
      payload: {
        id: 'vocMidCategory',
        label: 'Mid-Level Category',
        type: 'AUTOCOMPLETE',
        options: categoryOptions?.midLevelCategoryOptions ?? [],
      },
    })
  }, [categoriesData, platform])

  useEffect(() => {
    dispatch({
      type: actions.UPDATE_MANUAL_CATEGORIES,
      payload: manualCategoriesData?.getManualCategories ?? [],
    })
  }, [manualCategoriesData,platform])

  if (categoriesLoading || categoryGroupsLoading || manualCategoriesLoading)
    return <LoadingSpinner />
  if (categoriesError || categoryGroupsError || manualCategoriesError)
    return (
      <ErrorMessage
        errorType="api"
        errorMessage="Error fetching Voice of Customer categories data."
      />
    )
  return (
    <VocStateProvider value={{ state, dispatch, categoriesRefetch, categoryGroupsRefetch }}>
      {children}
    </VocStateProvider>
  )
}
