import colors, { Color } from 'components/shared/utils/colors'
import { useDarkMode } from 'components/shared/dark-mode'
import { camelCase } from 'lodash'
import * as S from './styles'

export interface BadgeProps {
  /** The font color of the badge. Can only be dark mode or light mode text */
  color?: string
  /** The background color of the badge */
  backgroundColor?: string
  /** The numeric value to be shown in the badge. If higher than 99, "99+" will be shown */
  value?: number
  /** Text to be display in the Badge */
  text?: string
  /** Data test attribute */
  dataTest?: string
}

const formatBadgeValue = (value: number, limit = 99) => {
  if (value > 0) {
    return value > limit ? `${limit}+` : `${value}`
  }
  return null
}

export const Badge = ({ color, backgroundColor, value, text, dataTest }: any) => {
  const { isDarkMode } = useDarkMode()

  const kiteBackgroundColor = colors[camelCase(backgroundColor) as Color] as string
  const formattedValue = formatBadgeValue(value)

  /**
   * Returns a color to be used for badge text
   * The user is only able to choose between dark mode and light mode text
   */
  const getColor = (color?: string) => {
    if (!color) {
      return colors.darkMode.text
    }
    const kiteColor = colors[camelCase(color) as Color] as string
    if ([colors.lightMode.text, colors.darkMode.text].includes(kiteColor)) {
      return kiteColor
    } else if (
      [colors.lightMode.text.toUpperCase(), colors.darkMode.text.toUpperCase()].includes(
        color.toUpperCase()
      )
    ) {
      return color
    }
    return colors.darkMode.text
  }
  const badgeDisplayText = [text, formattedValue].join(' ')
  return (
    (formattedValue || text) && (
      <S.Badge
        $isDarkMode={isDarkMode}
        $backgroundColor={kiteBackgroundColor || backgroundColor}
        data-test={dataTest || 'badge'}
      >
        <S.BadgeTypography
          $color={getColor(color)}
          $paddingLeftRight={(badgeDisplayText?.length ?? 0) > 1 && (8 as any)}
        >
          {badgeDisplayText}
        </S.BadgeTypography>
      </S.Badge>
    )
  )
}
