import React from 'react'

export interface Dimensions {
  width: number
  height: number
  ref: React.Ref<HTMLDivElement>
}

export default function (): Dimensions {
  const ref = React.useRef<HTMLDivElement>(null)
  const [dimensions, setDimensions] = React.useState({
    ref,
    width: ref.current?.offsetWidth || 0,
    height: ref.current?.offsetHeight || 0,
  })

  React.useLayoutEffect(() => {
    const width = ref.current?.offsetWidth || 0
    const height = ref.current?.offsetHeight || 0
    setDimensions({ ref, width, height })
  }, [ref.current?.offsetWidth, ref.current?.offsetHeight, setDimensions])

  return dimensions
}
