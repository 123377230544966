import React, { useRef, useState } from 'react'
import moment from 'moment'
import useFilterValues from 'hooks/useFilterValues'
import { ResponsiveBar } from '@nivo/bar'
import isNil from 'lodash/isNil'
import defaults from 'lodash/defaults'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import times from 'lodash/times'
import numeral from 'numeral'
import spectrumColors from '../bit/utils/colors'
import colors from '../bit/utils/colors'
import { Card } from '../Card/Card'
import { chartColors } from 'common/constants'
import { FormattedBarData } from 'components/shared/ChartData/types'
import NoDataChartMessage from 'components/shared/NoDataChartMessage'
import { getAxisRightTickValues } from 'components/shared/lineChart'
import NivoContainer from 'components/shared/NivoContainer'
import { RelativePeriods, DateFormats } from 'utils/times/times'
import * as S from './styles'
import { useDarkMode } from '../dark-mode'

function MetricBarChart({ data, legendTitle, customFilters }: MetricBarChartProps) {
  const [chartHeight, setChartHeight] = useState(0)
  const filterValues = useFilterValues()
  const filterValuesToUse = isNil(customFilters)
    ? filterValues
    : defaults({}, customFilters, filterValues)
  const { isDarkMode } = useDarkMode()

  const chartTheme = {
    tooltip: {
      container: {
        background: isDarkMode ? spectrumColors.darkMode.background : spectrumColors.white,
      },
    },
    textColor: isDarkMode ? 'white' : 'black',
    axis: {
      ticks: {
        text: {
          fontSize: '12px',
          fontFamily: 'Open Sans',
        },
      },
    },
  }

  const { step, period } = filterValuesToUse

  const ChartContainerRef = useRef(null)

  React.useEffect(() => {
    const height =
      ChartContainerRef &&
      ChartContainerRef?.current &&
      get(ChartContainerRef, 'current.offsetHeight')

    if (height && height !== chartHeight) {
      setChartHeight(height)
    }
  }, [data, chartHeight])

  if (isEmpty(data)) {
    return <NoDataChartMessage />
  }

  return (
    <div style={{ height: '100%' }} ref={ChartContainerRef}>
      <NivoContainer>
        <ResponsiveBar
          data={data}
          theme={chartTheme}
          layout="vertical"
          labelSkipWidth={19}
          indexBy="index"
          colors={chartColors}
          keys={getKeys(data)}
          labelTextColor={colors.white}
          enableLabel={false}
          axisLeft={null}
          axisBottom={{
            tickSize: 4,
            tickPadding: 5,
            tickRotation: 0,
            legend: legendTitle,
            legendPosition: 'middle',
            legendOffset: -110,
            tickValues: getTickValues(data),
            format: (value: any) => {
              if (Number(value) === 0) return value
              if (
                !isNull(step) &&
                Number(step) < 86400000 &&
                (period === RelativePeriods.LastTwelveHours ||
                  period === RelativePeriods.LastTwentyFourHours)
              ) {
                return moment(Number(value)).format(DateFormats.HoursAndMinutes)
              }

              return moment(Number(value)).format(DateFormats.DayAndAbbreviatedMonth)
            },
          }}
          axisRight={{
            format: (value: any) => numeral(value).format('0a'),
            tickValues: getAxisRightTickValues(chartHeight),
          }}
          margin={{
            top: 12,
            right: 37,
            bottom: 30,
            left: 10,
          }}
          tooltip={({ color, id, indexValue, value }) => {
            const formatString = Number(step) < 864000 ? 'MMM D, YYYY hh:mm a' : 'MMM D, YYYY'
            const timestamp = moment(indexValue).format(formatString)

            return (
              <Card padding="0.5rem 1rem">
                <div>{timestamp}</div>
                <S.TooltipContentText>
                  <S.Colorblock color={color} />
                  {id}: <b>{numeral(value).format('0,0')}</b>
                </S.TooltipContentText>
              </Card>
            )
          }}
          indexScale={{ type: 'band', round: false }}
        />
      </NivoContainer>
    </div>
  )
}

export function getTickValues(data: FormattedBarData): string[] {
  return data.map((value, index) => {
    const tickInterval = getTickInterval(data.length)

    if (index % tickInterval === 0) return value?.index?.toString()

    return `${times(index, () => ' ').join('')}`
  })
}

export function getKeys(data: FormattedBarData): string[] {
  const keysSet = new Set<string>()

  data.forEach(value => {
    const keys = Object.keys(value)
    keys.forEach(key => keysSet.add(key))
  })

  return Array.from(keysSet).filter(key => key !== 'index')
}

export function getTickInterval(dataLength: number): number {
  if (dataLength > 100) {
    return Math.round(dataLength / 15)
  }

  if (dataLength > 80) {
    return 8
  }

  if (dataLength > 50) {
    return 6
  }

  if (dataLength > 15) {
    return 4
  }

  return 1
}

interface MetricBarChartProps {
  data: FormattedBarData
  legendTitle?: string
  customFilters?: Record<string, any>
}

export default MetricBarChart
