import React, { useState, MouseEventHandler } from 'react'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import capitalize from 'lodash/capitalize'
import compact from 'lodash/compact'
import { RouteComponentProps, navigate } from '@reach/router'
import Alert from 'components/shared/Alert'
import { Button } from 'components/shared/Button'
import { Domains } from 'common/constants'
import useApps from 'hooks/useApps'
import Entity from 'components/shared/Entity'
import { PrismUserState } from 'components/App/Providers/Data/PrismUser'
import { HandleApplicationSwitcherClose } from 'components/layout/Topbar/ApplicationSwitcher'
import { filterOutDatedFavorites } from './utils'
import * as S from './styles'
import { Accordion } from 'components/shared/Accordion'

interface FavoritesModalContentProps extends RouteComponentProps {
  prismUser: PrismUserState
  handleBack: () => void
  isNewUser: boolean
  onClose: MouseEventHandler<HTMLElement>
  handleApplicationSwitcherClose?: (event: HandleApplicationSwitcherClose) => void
  shouldNavigate?: boolean
}

function FavoritesModalContent({
  prismUser,
  handleBack,
  isNewUser,
  onClose,
  handleApplicationSwitcherClose,
  shouldNavigate,
}: FavoritesModalContentProps) {
  const { appsByDataSource, appsByDomain, apps, entityID } = useApps()
  const [selectedFavorites, setSelectedFavorites] = useState<string[]>(
    filterOutDatedFavorites(prismUser.favorites, apps)
  )
  const [alertMessage, setAlertMessage] = React.useState('')

  function createAccordions(appsByDomain: Record<string, string[]>, selectedFavorites: string[]) {
    const accordionsPanels = map(appsByDomain, (domainApps, key) => {
      let favorite = false
      const domainAppSort = domainApps.map((option: any) => {
        const appIDParts = option.split('@')
        const name = appIDParts?.[0]
        const type = appIDParts?.[1]
        const techType = appIDParts?.[2]
        const isFavorite = selectedFavorites.includes(option)
        if (isFavorite) favorite = true
        return (
          <S.AccEntryContainer key={option} onClick={() => toggleFavorite(option)}>
            <Entity
              title={name}
              description={`${type || ''}${techType ? ` (${techType})` : ''}`}
              isFavorite={isFavorite}
            />
          </S.AccEntryContainer>
        )
      })
      return {
        title: formatDomainTitle(key),
        label: favorite && <S.StyledStarIcon />,
        content: <S.AccBodyContainer>{domainAppSort}</S.AccBodyContainer>,
      }
    })

    return accordionsPanels
  }

  function toggleFavorite(e: any) {
    setAlertMessage('')
    const selected = e?.target?.value || e

    if (selectedFavorites.includes(selected)) {
      setSelectedFavorites(selectedFavorites.filter(elem => elem !== selected))
    } else {
      setSelectedFavorites([...selectedFavorites, selected])
    }
  }

  const handleContinue = React.useCallback(
    // @TODO: When we migrate to the new popover component, we should set this type to "React.MouseEvent<HTMLButtonElement, MouseEvent>""
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      // React.MouseEventHandler<HTMLButtonElement> | undefined
      if (isEmpty(selectedFavorites)) {
        setAlertMessage('No favorites selected.')
      } else {
        setAlertMessage('')
        const updatedUser = await prismUser.update({ favorites: compact(selectedFavorites) })
        if (!updatedUser) {
          setAlertMessage('There was an error updating your favorites.')
          return
        }
        if (shouldNavigate) {
          const navigateToApp = entityID ? entityID : (updatedUser?.favorites || [])[0]
          const section = appsByDataSource.quantum.includes(navigateToApp)
            ? 'client-analytics'
            : 'server-analytics'

          const navigateTo = `/${navigateToApp}/${section}/overview`
          navigate(navigateTo)
        }
        onClose(event)
        handleApplicationSwitcherClose && handleApplicationSwitcherClose(event)
      }
    },
    [
      appsByDataSource.quantum,
      entityID,
      handleApplicationSwitcherClose,
      onClose,
      prismUser,
      selectedFavorites,
      shouldNavigate,
    ]
  )

  return (
    <>
      <S.HeaderContainer>
        <div>
          <S.FavoritesTitle data-pho="favoritesTitle" variant="title2">
            Favorites
          </S.FavoritesTitle>
          <S.ModalSubtitle>Choose some applications to mark as favorites</S.ModalSubtitle>
        </div>
        <S.ButtonsContainer isNewUser={isNewUser}>
          {isNewUser && (
            <Button variant="secondary" onClick={handleBack}>
              Back
            </Button>
          )}
          <Button variant="primary" onClick={handleContinue}>
            Save
          </Button>
        </S.ButtonsContainer>
        {alertMessage && (
          <S.StyledAlertContainer>
            <Alert children={alertMessage} variant="page-error" isPersistent />
          </S.StyledAlertContainer>
        )}
      </S.HeaderContainer>
      <S.MainContainer>
        <S.AccordionContainer>
          <Accordion
            asCard
            width="100%"
            panels={createAccordions(appsByDomain, selectedFavorites)}
          />
        </S.AccordionContainer>
      </S.MainContainer>
    </>
  )
}

function formatDomainTitle(domain: string) {
  switch (domain) {
    case Domains.Portals:
      return 'SSPP'
    case Domains.Internal:
      return 'Internal'
    case Domains.JourneySurvey:
      return 'Journey Survey'
    case Domains.EmployeeExperience:
      return 'Employee Experience'
    default:
      return capitalize(domain)
  }
}

export default FavoritesModalContent
