import styled, { css } from 'styled-components'
import { Rnd } from 'react-rnd'
import { Card } from 'components/shared/Card'
import MuiFilterIcon from '@material-ui/icons/FilterListRounded' // @TODO find suitable replacement
import TextField from '@material-ui/core/TextField'
import { IconButton } from 'components/shared/IconButton'
import colors from 'components/shared/utils/colors'
import NRInput from 'components/shared/Input'
import FormControl from '@material-ui/core/FormControl'

export const FILTER_WIDTH = '12rem'

export const FilterIcon = styled(MuiFilterIcon)`
  && {
    width: 15px;
    height: 10px;
    margin-right: 10px;
    color: ${colors.black};
  }
`

export const Text = styled.span`
  color: ${colors.black};
  font-weight: 500;
  font-size: 0.875rem;
  font-family: inherit;
  text-transform: unset;
`

export const RndPanel = styled(Rnd)`
  z-index: 100;
  min-width: 28rem;
  min-height: 10rem;

  && {
    margin: 0;
  }
`

export const StyledCard = styled(Card)`
  > div {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
  > div > * {
    min-width: unset;
    :not(:last-child) {
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
  }
`

export const CloseIcon = styled(IconButton)`
  && {
    position: absolute;
    top: 0;
    right: 0;
  }
`

export const Input = styled(NRInput)`
  && {
    min-width: 7rem;
  }
`

export const RangeInput = styled(TextField)`
  padding-right: 1rem;
  && {
    min-width: 6rem;
  }
`

export const RangeActions = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;

  > button {
    width: 7rem;
    height: 30px;
  }
`

export const StyledTextField = styled(TextField)`
  margin-top: 0.6rem;
  label {
    font-size: 0.8rem;
  }
`

export const FilterMenuContainer = styled.div`
  position: relative;
`

export const GroupByMenuContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

export const ExperimentAndVariantUuidsContainer = styled.div`
  && > fieldset:first-of-type > div {
    margin-right: 1rem;
  }
`

export const VocCategoryGroupContainer = styled.div`
  display: flex;
  && > fieldset:first-of-type {
    margin-right: 1rem;
  }
`

export const FilterContainer = styled.div<{
  fullWidth?: boolean
  isExperimentAndVariantUuidsFilter?: boolean
}>`
  width: ${props => (props.fullWidth ? '100%' : FILTER_WIDTH)};
  height: max-content;
  ${({ isExperimentAndVariantUuidsFilter }) =>
    isExperimentAndVariantUuidsFilter &&
    css`
      width: 25rem;
    `}
`

export const RangeContainer = styled.div`
  display: flex;
`

export const StyledFormControl = styled(FormControl as any)`
  min-width: ${props => (props.fullWidth ? '100%' : '12rem')};
`
