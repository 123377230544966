import styled from 'styled-components'
import MenuIcon from '@material-ui/icons/Menu'
import EditIcon from '@material-ui/icons/Edit'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Chip from 'components/shared/Sidebar/Chip'
import colors from 'components/shared/bit/utils/colors'

export const MenuButton = styled(MenuIcon)`
  color: ${colors.gray30};
`

export const EditButton = styled.div`
  font-weight: bold;
  color: ${colors.blue10};
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

export const EditFavIcon = styled(EditIcon)`
  height: 1.25rem;
  width: 1.25rem;
  margin-left: 0.3125rem;
`

export const AppMenuItem = styled(MenuItem)`
  font-size: 14px;
  font-family: inherit;
`

export const FavoriteApplicationsContainer = styled(MenuList)`
  height: 100%;
  width: 100%;
`

export const MenuContainer = styled.div`
  padding: 1.25rem;
  color: ${colors.black};
  width: 22rem;
  max-height: 90vh;
`

export const NavTitle = styled.div`
  font-weight: bold;
  height: 1.25rem;
`

export const ViewAllTitle = styled(NavTitle)`
  color: ${colors.blue10};
  cursor: pointer;
  font-size: 0.9rem;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
`

export const NoResultsMessage = styled.div`
  margin-top: 15px;
  font-size: 0.9rem;
`

export const EmptyFavoritesMessage = styled.div`
  margin-bottom: 10px;
  color: ${colors.gray30};
`

export const NavMenuContainer = styled.div`
  margin-left: 1rem;
`

export const ChipStyled = styled(Chip)`
  && {
  }
`
