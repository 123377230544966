import React from 'react'

export default function useToggle(initialState: boolean = false) {
  const [isOn, setIsOn] = React.useState(initialState)

  function toggle(isOnOverride?: boolean) {
    setIsOn(prevIsOn => isOnOverride ?? !prevIsOn)
  }

  const value: [boolean, (isOnOverride?: boolean) => void] = [isOn, toggle]

  return value
}
