import React from 'react'
import styled, { css } from 'styled-components'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { LoadingSpinner } from 'components/shared/LoadingSpinner'
import FormControl from '@material-ui/core/FormControl'
import Colors from 'components/shared/utils/colors'
import Spacing from 'components/shared/utils/spacing'

export const ErrorMessage = styled.div`
  color: ${Colors.red10};
`

export const AutocompleteContainer = styled.div<{ $disablePortal?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ $disablePortal }) =>
    // This is material UI bug fix, when using custom Dialog
    $disablePortal &&
    css`
      .MuiAutocomplete-popperDisablePortal {
        bottom: 0;
        top: 100%;
        transform: translateY(-100%);
      }
    `}
`

export const StyledAutocomplete = styled(({ fullWidth, width, fullHeight, ...props }) => (
  <Autocomplete {...props} />
))`
  width: ${props => (props.fullWidth ? '100%' : props.width ?? '10rem')};
  height: ${props => !props.fullHeight && '1rem'};

  label {
    font-size: 0.8rem;
  }
  > div {
    ${props => !props.fullHeight && 'margin-bottom: 1rem;'}
  }

  > div > div {
    display: flex;
    flex-direction: column-reverse;
    align-items: end;
    width: 100%;
    padding: 6px 6px unset 6px !important; /* !important because material-ui overwrites padding property*/
  }

  > div > div > input {
    width: 100% !important; /* !important because material-ui overwrites width property*/
  }

  .MuiAutocomplete-endAdornment {
    top: 6px;
  }
`

export const AppVersionFormControl = styled(FormControl as any)`
  min-width: ${props => (props.fullWidth ? '100%' : props.width || '12rem')};
  padding-bottom: ${Spacing.element.sm};
`

export const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-top: 0.5rem;
`

export const ChipContainer = styled.div`
  width: 100%;
`
