import styled, { css } from 'styled-components'
import { Checkbox } from 'components/shared/Checkbox'
import PrismReactRadioButton from 'components/shared/bit/radio-button'

export const buttonStyle = css`
  && {
    margin-bottom: 0.5rem;
  }
`

export const RadioButton = styled(PrismReactRadioButton)`
  ${buttonStyle}
`

export const PrismReactCheckbox = styled(Checkbox)`
  ${buttonStyle}
`
