import styled, { css } from 'styled-components'
import colors from 'components/shared/utils/colors'
import transition from 'components/shared/utils/transition'

export const Container = styled.button<{ $isDarkMode?: boolean }>`
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  /* Disable outline when click-focused */
  :focus,
  > span:focus {
    outline: none;
  }

  /* Enable outline when tab-focused */
  :focus > span {
    border-radius: 4px;
    box-shadow: ${({ $isDarkMode }) => ($isDarkMode ? colors.white : colors.blue20)} 0px 0px 2px 2px;
  }
`

export const SwitchContainer = styled.span<{ $isActive: boolean }>`
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  width: 50px;
  height: 25px;
  border-radius: 30px;
  background-color: ${({ $isActive }) => ($isActive ? colors.blue20 : colors.gray30)};
  transition-property: background-color;
  transition-duration: ${transition.durations.short};
  transition-timing-function: ${transition.timingFunctions.standard};
`

export const Circle = styled.span<{ $isActive: boolean }>`
  display: inline-block;
  width: 21px;
  height: 21px;
  background-color: ${colors.white};
  border-radius: 30px;
  margin: 0 2px;
  transform: translateX(${({ $isActive }) => ($isActive ? '25px' : 0)});
  transition-property: transform;
  transition-duration: ${transition.durations.short};
  transition-timing-function: ${transition.timingFunctions.standard};
`

export const InnerLabel = styled.span<{ $isActive: boolean; $isOn?: true }>`
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  margin: 0 6px;
  color: ${colors.white};
  opacity: ${({ $isActive, $isOn }) => (($isActive && $isOn) || (!$isActive && !$isOn) ? 1 : 0)};
  transition-property: opacity;
  transition-duration: ${transition.durations.short};
  transition-timing-function: ${transition.timingFunctions.standard};

  ${({ $isOn }) =>
    $isOn
      ? css`
          left: 0;
        `
      : css`
          right: -2px;
        `};

  > p {
    font-size: 10px;
    margin: 0;
  }
`
