import React from 'react'
import copy from 'copy-to-clipboard'
import { Toast, ToastProps } from 'components/shared/Toast'
import { Button, ButtonProps } from 'components/shared/Button'
import { IconButton } from 'components/shared/IconButton'
import useToggle from 'components/shared/bit/hooks/use-toggle'

export default function CopyButton({
  textToCopy,
  disabled = false,
  toastMessage = 'Copied to Clipboard!',
  toastLocation = 'bottom-left',
  variant = 'borderless',
  onClick,
  children,
  showToast = true,
}: {
  textToCopy: string
  disabled?: boolean
  toastMessage?: string
  toastLocation?: ToastProps['placement']
  variant?: ButtonProps['variant']
  onClick?: () => void
  children?: string
  showToast?: boolean
}) {
  const [isToastOpen, toggleToast] = useToggle()

  const handleClick = React.useCallback(
    (event: React.SyntheticEvent | React.MouseEvent) => {
      copy(textToCopy)
      toggleToast()
      event.stopPropagation()
    },
    [textToCopy, toggleToast]
  )

  return (
    <>
      {children ? (
        <Button
          onClick={onClick || handleClick}
          variant={variant}
          icon="Copy"
          iconSize="sm"
          data-pho="copyButton"
          disabled={disabled}
        >
          {children}
        </Button>
      ) : (
        <IconButton
          onClick={onClick || handleClick}
          icon="Copy"
          size="sm"
          data-pho="copyButton"
          disabled={disabled}
        />
      )}
      {showToast ? (
        <Toast
          key={Math.random()}
          placement={toastLocation}
          isToastOpen={isToastOpen}
          onDismiss={toggleToast}
          duration={6}
          label={toastMessage}
        />
      ) : null}
    </>
  )
}
