import React from 'react'
import styled, { css } from 'styled-components'
import { Card } from 'components/shared/Card'
import Spacing from '../bit/utils/spacing'
import Colors from '../bit/utils/colors'

export const ChartsContainer = styled.div<{
  shouldAddMarginTop?: boolean
  shouldWrapText?: boolean
}>`
  display: grid;
  flex-wrap: wrap;
  width: 100%;
  grid-gap: ${Spacing.element.xl};
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 5.25rem;
  ${props =>
    props.shouldAddMarginTop &&
    css`
      margin-top: 1rem;
    `}
  > div {
    width: 100%;
  }
  span {
    ${props =>
      props.shouldWrapText &&
      css`
          white-space: normal !important;
        }
      `}
  }
`

export const ChartContainer = styled(
  ({
    width,
    height,
    chartIsScrollable,
    extendContent,
    isActiveChart,
    ...otherProps
  }: {
    width: number
    height: number
    isActiveChart?: boolean
    [key: string]: any
  }) => <Card elevation={0} disablePadding {...otherProps} />
)`
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
  height: ${props => (props.extendContent ? 'fit-content' : '100%')};
  grid-column-start: span ${props => props.width || 2};
  grid-row-start: span ${props => props.height || 4};

  && {
    ${props => (props.isActiveChart ? `border: solid 2px ${Colors.blue10};` : '')}
    overflow: ${props => (props.chartIsScrollable || props.extendContent ? 'auto' : 'visible')};
    && > div {
      min-height: 0px;
    }
  }
`

export const ChartsGridContainer = styled.div`
  .react-grid-item.react-grid-placeholder {
    background: ${Colors.blue10};
  }
`

export const DraggableChart = styled.div<{ isEditing?: boolean }>`
  :hover {
    cursor: ${props => (props.isEditing ? 'grab' : 'default')};
  }
  :active {
    cursor: grabbing;
  }
`
