import styled from 'styled-components'
import colors from '../bit/utils/colors'
import Spacing from '../bit/utils/spacing'

export const BottomContainer = styled.div<{
  height?: number
}>`
  height: ${props => (props.height ? props.height + 'px' : '100%')};
  flex: 1;
  min-height: 0;
`

export const ChartFooter = styled.div`
  border-top: solid ${colors.gray20} 1px;
  display: flex;
  justify-content: space-between;
  margin: 0 -1rem;
  padding: 0.3rem 1rem;
  line-height: 1;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  padding: 1rem;
  font-family: 'Open Sans';
`

export const StyledLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #aaa;
`

export const TopContainer = styled.div`
  flex-grow: 0;
  display: flex;
  justify-content: space-between;
`

export const TabbedChartTopContainer = styled.div`
  flex: unset;
  flex-direction: column;
  display: flex;
  min-height: 0;
`

export const ChartDescriptionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const ChartTitle = styled.h4`
  margin-bottom: 0;
`

export const ChartDescription = styled.div`
  color: ${colors.gray30};
  font-size: 0.7rem;
`

export const ButtonTabContainer = styled.div`
  display: flex;
  margin: 0.5rem 0;
  gap: ${Spacing.element.xs};
`
