import React from 'react'
import styled, { css } from 'styled-components'
import { Card } from 'components/shared/Card'
import MuiInput from '@material-ui/core/Input'
import MuiInputLabel from '@material-ui/core/InputLabel'
import MuiFormControl from '@material-ui/core/FormControl'
import MuiFormHelperText from '@material-ui/core/FormHelperText'
import MuiMenuItem from '@material-ui/core/MenuItem'
import MuiPopper from '@material-ui/core/Popper'
import { defaultFont } from 'common/constants'
import colors from '../bit/utils/colors'

export const captionStyles = css`
  ${defaultFont};
  margin-bottom: 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
`

export const FormControl = styled(MuiFormControl)<{ width?: string }>`
  && {
    width: ${props => props.width || '100%'};
    margin-top: -2px;
  }
`

export const StyledInput = styled(MuiInput)<{ noBorderBottom?: string }>`
  && {
    ${defaultFont};

    ::after {
      border-bottom-color: ${props => props.color};
      ${props => props.noBorderBottom && 'border-bottom: none'}
    }
    ::before {
      ${props => props.noBorderBottom && 'border-bottom: none !important'}
    }
  }
`

export const MenuItem = styled(MuiMenuItem)`
  && {
    color: ${colors.black};
  }
`

export const Suggestion = styled(({ isHighlighted, ...props }) => <MenuItem {...props} />)`
  && {
    ${props =>
      props.isHighlighted &&
      css`
        background: rgba(0, 0, 0, 0.14);
      `};
  }
`

export const SuggestionText = styled.span`
  && {
    ${defaultFont};
  }
`

export const Popper = styled(MuiPopper)`
  && {
    margin-left: 2px;
    z-index: 10000;
  }
`

export const InputLabel = styled(({ color, isFocused, ...props }) => <MuiInputLabel {...props} />)`
  && {
    ${defaultFont};
    ${captionStyles};
  }

  &&[data-shrink='true'] {
    ${props =>
      props.isFocused &&
      css`
        color: ${props.color};
      `};
  }
`

export const HelpText = styled(MuiFormHelperText)`
  && {
    ${defaultFont};
    ${captionStyles};
    font-size: 0.75rem;
  }
`

export const ErrorText = styled(HelpText)`
  && {
    color: ${colors.red10};
  }
`

export const Paper = styled(Card)`
  && {
    border-radius: 4px;
  }
`

export const styles = () => ({
  suggestion: {
    display: 'block',
    padding: 0,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    '&:first-child': {
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
    },
    '&:last-child': {
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
    },
  },
})
