import { Toast } from 'components/shared/Toast'
import { Method } from 'axios'
import { rootApiUrl } from 'common/constants'
import services from 'common/services'
import Topbar from 'components/layout/Topbar'
import { axios, axiosConfigBase } from 'hooks/useApi'
import useSite from 'hooks/useSite'
import useVersion from 'hooks/useVersion'
import React, { useState, lazy, Suspense } from 'react'
import 'react-base-table/styles.css'
import { RecoilRoot } from 'recoil'
import Screens from 'screens'
import NotFoundScreen from 'screens/404'
import analytics from 'utils/analytics'
import Providers from './Providers'
import * as S from './styles'
import { LoadingSpinner } from 'components/shared/LoadingSpinner'
import { Router } from '@reach/router'

const AsyncSurveyScreen = lazy(() => import('screens/survey'))
const AsyncCstSurveyScreen = lazy(() => import('screens/tools/cst/respondent_portal'))

function App() {
  const surveyPage = window.location.pathname.slice(0, 7) === '/survey'
  const cstSurveyPage = window.location.pathname.slice(0, 11) === '/cst/survey'

  const { version } = useSite()
  const isLatestVersion = useVersion(version)
  const [menuClosed, setMenuClosed] = useState(false)

  const handleSetMenuClosed = React.useCallback(() => setMenuClosed(true), [setMenuClosed])

  return (
    <Providers>
      <RecoilRoot>
        <Toast
          label="A new version of Prism is available. Refresh to view."
          isToastOpen={!isLatestVersion && !menuClosed}
          icon="CautionAlert"
          placement="top-right"
          duration={10}
          onDismiss={handleSetMenuClosed}
        />
        {surveyPage || cstSurveyPage ? (
          <Suspense fallback={<LoadingSpinner />}>
            <Router component={React.Fragment} primary={false}>
              <AsyncCstSurveyScreen path="cst/survey/*" />
              <AsyncSurveyScreen path="survey/*" />
            </Router>
          </Suspense>
        ) : (
          <S.AppContainer>
            <Topbar />
            <Screens />
          </S.AppContainer>
        )}
      </RecoilRoot>
    </Providers>
  )
}

class AppHandler extends React.Component<React.ComponentClass, React.ComponentState> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false, error: null }
  }
  static getDerivedStateFromError(error: any) {
    return { hasError: true, error }
  }
  componentDidCatch(error: Error, info: React.ErrorInfo) {
    const { message } = error

    analytics.track('error', {
      appErrorType: 'generic',
      appClientErrorCode: 'componentDidCatchError',
      ...(message ? { appErrorMessage: message } : {}),
    })
    const visitId = analytics.getVisitID()

    try {
      const axiosConfig = {
        ...axiosConfigBase(),
        method: 'POST' as Method,
        data: {
          href: encodeURI(window?.location?.href),
          message,
          user: { email: 'Gabriel.Konkle@charter.com', name: 'Gabriel Konkle' },
          type: 'crash',
          visitId,
          info: JSON.stringify(info),
        },
        url: `${rootApiUrl}${services.webexApi.url}`,
      }
      axios({ ...axiosConfig })
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    const { hasError = false } = this.state
    if (hasError) {
      return (
        <NotFoundScreen
          title={`Error: ${this.state.error.message}`.toUpperCase() || 'An unknown error occurred'}
          error
          copy={''}
        />
      )
    }

    return <App />
  }
}

export default AppHandler
