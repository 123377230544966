import React from 'react'
import useToggle from './useToggle'

export const ToggleContext = React.createContext<[boolean, () => void]>([false, () => null])

export function ToggleContextProvider({
  initialState = false,
  children,
}: {
  initialState: boolean
  children: React.ReactNode
}) {
  const value = useToggle(initialState)

  return <ToggleContext.Provider value={value}>{children}</ToggleContext.Provider>
}

export default function useToggleContext() {
  return React.useContext(ToggleContext)
}
