import React from 'react'
import Icon from '../icon/Icon'
import { primary } from '../bit/utils/colors'
import Typography from '../typography/Typography'
import Flex from '../flex/Flex'

import * as S from './styles'

export default function TableHeaders({
  columnHeaders = ['Name', 'Source', 'Type', 'Description'],
  columnAlignment,
  sortByColumn,
  handleSortDirectionChange,
  activeColumnIndex,
  sortReverse,
}: TableHeadersProps): React.FunctionComponentElement<TableHeadersProps> {
  return (
    <S.ColumnHeadersRow id="end-row">
      {columnHeaders.map((columnHeader, index) => (
        <S.StyledMuiTableTitleCell
          key={`${index}_${columnHeader}`}
          align={columnAlignment && columnAlignment[index]}
          data-pho={columnHeader}
        >
          <S.StyledSort onClick={() => sortByColumn && handleSortDirectionChange(index)}>
            <Typography
              variant="body"
              color={sortByColumn && activeColumnIndex === index ? primary.blue20 : 'black'}
              noMargin
              as="span"
            >
              <Flex direction="row" alignItems="center">
                <span style={{ marginRight: '8px' }}>{columnHeader}</span>{' '}
                <Icon
                  name={sortReverse ? 'ChevronDown' : 'ChevronUp'}
                  color={primary.blue20}
                  size="sm"
                />
              </Flex>
            </Typography>
          </S.StyledSort>
        </S.StyledMuiTableTitleCell>
      ))}
    </S.ColumnHeadersRow>
  )
}

interface TableHeadersProps {
  columnHeaders?: any[]
  columnAlignment?: any
  sortByColumn: boolean
  handleSortDirectionChange: Function
  activeColumnIndex: number
  sortReverse: boolean
}
