import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import maxBy from 'lodash/maxBy'
import numeral from 'numeral'
import spectrumColors from '../bit/utils/colors'
import colors from '../bit/utils/colors'
import { chartColors } from 'common/constants'
import { FormattedBarData } from 'components/shared/ChartData/types'
import NoDataChartMessage from 'components/shared/NoDataChartMessage'
import NivoContainer from 'components/shared/NivoContainer'
import { useDarkMode } from '../dark-mode'
import { checkForReplaceValue } from 'utils/typechange'

function BarChart({ data, legendTitle }: BarChartProps) {
  const { isDarkMode } = useDarkMode()

  const chartTheme = {
    tooltip: {
      container: {
        background: isDarkMode ? spectrumColors.darkMode.background : spectrumColors.white,
      },
    },
    textColor: isDarkMode ? 'white' : 'black',
    axis: {
      ticks: {
        text: {
          fontSize: '12px',
          fontFamily: 'Open Sans',
        },
      },
    },
  }

  const sortedBarChartData = sortBy(data, datum => datum.count)
  if (isEmpty(data)) {
    return <NoDataChartMessage />
  }
  // This part is used to render correctly data with equal names, because Nivo takes name as unique key
  // We are adding whitespace to get each name unique. Whitespace do not render, so we are not changing any data.
  const uniqueData = sortedBarChartData.map(
    (item: { [key: string]: string | number }, index: number) => {
      let whitespace = ' '

      for (let i = 0; i < index; i++) {
        whitespace = `${whitespace} `
      }
      return { ...item, category: `${checkForReplaceValue(whitespace)}${checkForReplaceValue(item?.category)}` } as Record<
        string,
        string | number
      >
    }
  )
  // fixes bug https://gitlab.spectrumflow.net/client-analytics/prism/-/issues/1067
  function getTickCount() {
    const maxValue = (maxBy(uniqueData || [], 'count')?.count as number) || 0

    if (maxValue <= 2) {
      // if the maxValue is two or less need to add the same number as maxValue, Nivo chart bug
      return maxValue
    } else if (maxValue > 2 && maxValue <= 5) {
      return (maxValue as number) + 1
    } else {
      return 5
    }
  }

  return (
    <NivoContainer>
      <ResponsiveBar
        data={uniqueData}
        theme={chartTheme}
        layout="horizontal"
        labelSkipWidth={19}
        indexBy="category"
        colors={chartColors[0]}
        keys={['count']}
        labelTextColor={colors.white}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: legendTitle,
          legendPosition: 'middle',
          legendOffset: -110,
        }}
        valueFormat=" >-,"
        axisBottom={{
          tickValues: getTickCount(),
          format: (value: any) => numeral(value).format('0a'),
        }}
        margin={{
          right: 20,
          bottom: 25,
          left: 125,
        }}
        indexScale={{ type: 'band', round: false }}
      />
    </NivoContainer>
  )
}

interface BarChartProps {
  data: FormattedBarData
  legendTitle?: string
}

export default BarChart
