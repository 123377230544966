import orderBy from 'lodash/orderBy'

export const allColumnHeaders = [
  {
    id: 'timestamp',
    label: 'Date',
    section: 'General',
    show: true,
  },
  {
    id: 'source',
    label: 'Source',
    section: 'Application',
    show: true,
  },
  {
    id: 'visitId',
    label: 'Visit ID',
    align: 'center',
    section: 'Analysis',
    show: true,
  },
  {
    id: 'appName',
    label: 'Application Name',
    section: 'Application',
    show: true,
  },
  {
    id: 'appType',
    label: 'Application Type',
    section: 'Application',
    show: true,
  },
  {
    id: 'verbatim',
    label: 'Review (Verbatim)',
    section: 'General',
    minWidth: 400,
    show: true,
  },
  {
    id: 'altVerbatim',
    label: 'Alt Review (Alt Verbatim)',
    section: 'General',
    minWidth: 400,
    show: true,
  },
  {
    id: 'mlAnalysisField',
    label: 'ML Analysis Field',
    section: 'General',
    show: true,
  },
  {
    id: 'CSAT',
    label: 'CSAT',
    align: 'right',
    section: 'Metrics',
    show: true,
  },
  // {
  //   id: 'CES',
  //   label: 'CES',
  //   align: 'right', *commented out until proper equations are used to get these stats
  //   section: 'Metrics',
  // },
  {
    id: 'NPS',
    label: 'NPS',
    align: 'right',
    section: 'Metrics',
    show: true,
  },
  {
    id: 'SR',
    label: 'Success Rate',
    align: 'right',
    section: 'Metrics',
    show: true,
  },
  {
    id: 'starRating',
    label: 'Star Rating (Internal)',
    section: 'Metrics',
    show: true,
  },
  {
    id: 'starRatingExternal',
    label: 'Star Rating (App Store)',
    section: 'Metrics',
    show: true,
  },
  {
    id: 'userSelectedCategory',
    label: 'User Selected Category',
    section: 'Analysis',
    show: true,
  },
  {
    id: 'surveyID',
    label: 'Survey ID',
    section: 'Application',
    show: true,
  },
  {
    id: 'appVersion',
    label: 'Application Version',
    section: 'Application',
    show: true,
  },
  {
    id: 'footprint',
    label: 'MSO',
    section: 'Device',
    show: true,
  },
  {
    id: 'browser',
    label: 'Browser',
    section: 'Device',
    show: true,
  },
  {
    id: 'os',
    label: 'OS',
    section: 'Device',
    show: true,
  },
  {
    id: 'deviceManufacturer',
    label: 'Device Manufacturer',
    section: 'Device',
    show: true,
  },
  {
    id: 'deviceType',
    label: 'Device Type',
    section: 'Device',
    show: true,
  },
  {
    id: 'deviceModel',
    label: 'Device Model',
    section: 'Device',
    show: true,
  },
  {
    id: 'deviceFormFactor',
    label: 'Device Form Factor',
    section: 'Device',
    show: true,
  },
  {
    id: 'loggedInStatus',
    label: 'Logged In Status',
    section: 'Account',
    show: true,
  },
  {
    id: 'prismAppliedCategories',
    label: 'Applied Categories',
    section: 'Analysis',
    show: true,
  },
  {
    id: 'awsCategories',
    label: 'Generated Categories',
    section: 'Analysis',
    show: true,
  },
  {
    id: 'awsSentiment',
    label: 'Sentiment',
    sublabels: [
      { id: 'awsSentiment.negative', label: 'NEG' },
      { id: 'awsSentiment.positive', label: 'POS' },
      { id: 'awsSentiment.mix', label: 'MIX' },
      { id: 'awsSentiment.neutral', label: 'NEU' },
    ],
    align: 'center',
    section: 'Analysis',
    show: true,
  },
  {
    id: 'awsKeywords',
    label: 'Keywords',
    section: 'Analysis',
    minWidth: 500,
    show: true,
  },
  {
    id: 'shouldRetrain',
    label: 'Use for Retraining?',
    section: 'Analysis',
    show: true,
  },
  {
    id: 'notes',
    label: 'Notes',
    section: 'Analysis',
    minWidth: 500,
    show: true,
  },
  {
    id: 'pageName',
    label: 'Page Name',
    section: 'Application',
    show: true,
  },
  {
    id: 'SCP',
    label: 'SCP',
    section: 'Account',
    show: true,
  },
  {
    id: 'accountType',
    label: 'Account Type',
    section: 'Account',
    show: true,
  },
  {
    id: 'billerType',
    label: 'Biller Type',
    section: 'Account',
    show: true,
  },
  {
    id: 'billingServices',
    label: 'Billing Services',
    section: 'Account',
    show: true,
  },
  {
    id: 'deliveryMethod',
    label: 'Delivery Method',
    section: 'Account',
    show: true,
  },
  {
    id: 'userRole',
    label: 'User Role',
    section: 'Account',
    show: true,
  },
  {
    id: 'autoPayStatus',
    label: 'Auto Pay Status',
    section: 'Account',
    show: true,
  },
  {
    id: 'accountExpired',
    label: 'Account Expired?',
    section: 'Account',
    show: true,
  },
  {
    id: 'expirationDate',
    label: 'Expiration Date',
    section: 'Account',
    show: true,
  },
  {
    id: 'internetService',
    label: 'Internet Service',
    section: 'Account',
    show: true,
  },
  {
    id: 'secureConnectService',
    label: 'Secure Connect Service',
    section: 'Account',
    show: true,
  },
  {
    id: 'tvService',
    label: 'TV Service',
    section: 'Account',
    show: true,
  },
  {
    id: 'voiceService',
    label: 'Voice Service',
    section: 'Account',
    show: true,
  },
  {
    id: 'wifiService',
    label: 'WiFi Service',
    section: 'Account',
    show: true,
  },
  {
    id: 'mobileService',
    label: 'Mobile Service',
    section: 'Account',
    show: true,
  },
  {
    id: 'encAccountNumber',
    label: 'Account Number (Encrypted)',
    section: 'Account',
    align: 'center',
    show: true,
  },
  {
    id: 'encMobileAccountNumber',
    label: 'Mobile Account Number (Encrypted)',
    section: 'Account',
    align: 'center',
    show: true,
  },
  {
    id: 'userType',
    label: 'Bulk User Type',
    section: 'Account',
    align: 'center',
    show: true,
  },
  {
    id: 'surveyType',
    label: 'Survey Type',
    section: 'Survey',
    align: 'center',
    show: true,
  },
  {
    id: 'journeyType',
    label: 'Journey Type',
    section: 'Survey',
    align: 'center',
    show: true,
  },
  {
    id: 'activationMethod',
    label: 'Activation Method Path',
    section: 'Survey',
    align: 'center',
    show: true,
  },
  {
    id: 'instructionsClarityScale',
    label: 'Clarity of Instructions',
    section: 'Installation Drivers',
    align: 'center',
    show: true,
  },
  {
    id: 'equipmentConnectionScale',
    label: 'Ease of Equipment Connection',
    section: 'Installation Drivers',
    align: 'center',
    show: true,
  },
  {
    id: 'activationProcessScale',
    label: 'Activation Process',
    section: 'Installation Drivers',
    align: 'center',
    show: true,
  },
  {
    id: 'closedLoop',
    label: 'Closed Loop',
    section: 'Survey',
    align: 'center',
    show: true,
  },
  {
    id: 'businessUnit',
    label: 'Business Unit',
    section: 'General',
    align: 'center',
    show: true,
  },
  {
    id: 'department',
    label: 'Department',
    section: 'General',
    align: 'center',
    show: true,
  },
  {
    id: 'phoneNumber',
    label: 'Phone Number',
    section: 'General',
    align: 'center',
    show: true,
  },
  {
    id: 'email',
    label: 'Email',
    section: 'General',
    align: 'center',
    show: true,
  },
  {
    id: 'firstName',
    label: 'First Name',
    section: 'General',
    align: 'center',
    show: true,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    section: 'General',
    align: 'center',
    show: true,
  },
  {
    id: 'pid',
    label: 'PID',
    section: 'General',
    align: 'center',
    show: true,
  },
  {
    id: 'title',
    label: 'Title',
    section: 'General',
    align: 'center',
    show: true,
  },
  {
    id: 'xumoLogin',
    label: 'Xumo Login',
    section: 'General',
    align: 'center',
    show: true,
  },
  {
    id: 'accountNumber',
    label: 'Account Number',
    section: 'General',
    align: 'center',
    show: true,
  },
  {
    id: 'username',
    label: 'Username',
    section: 'General',
    align: 'center',
    show: true,
  },
  {
    id: 'altVerbatim',
    label: 'Alt Verbatim',
    section: 'General',
    minWidth: 500,
    show: true,
  },
  {
    id: 'language',
    label: 'Language',
    section: 'Device',
    show: true,
  },
  {
    id: 'isFlagged',
    label: 'Flagged',
    section: 'General',
    show: true,
  },
  {
    id: 'isReviewed',
    label: 'Reviewed',
    section: 'General',
    show: true,
  },
  {
    id: 'isHidden',
    label: 'Hidden',
    section: 'General',
    show: true,
  },
  {
    id: 'isNullVerbatim',
    label: 'Null Verbatim',
    section: 'General',
    show: true,
  },
  {
    id: 'experimentUuids',
    label: 'Experiment UUIDs',
    align: 'center',
    section: 'General',
    minWidth: 250,
    show: true,
  },
  {
    id: 'activatedExperimentUuids',
    label: 'Activated Experiment UUIDs',
    align: 'center',
    section: 'General',
    minWidth: 250,
    show: true,
  },
  {
    id: 'variantUuids',
    label: 'Variant UUIDS',
    align: 'center',
    section: 'General',
    minWidth: 250,
    show: true,
  },
  {
    id: 'recentIssues',
    label: 'Recent Issues',
    align: 'center',
    section: 'General',
    show: true
  },
  {
    id: 'connectivityIssueRecurrence',
    label: 'Connectivity Issue Recurrence',
    align: 'center',
    section: 'General',
    show: true
  },
  {
    id: 'wifiServiceIssues',
    label: 'WiFi Service Issues',
    align: 'center',
    section: 'General',
    show: true
  },
  {
    id: 'problemDeviceTypes',
    label: 'Problem Device Types',
    align: 'center',
    section: 'General',
    show: true
  },
  {
    id: 'resolutionActions',
    label: 'Resolution Actions',
    align: 'center',
    section: 'General',
    show: true
  },
  {
    id: 'speedExpectations',
    label: 'Speed Expectations',
    align: 'center',
    section: 'General',
    show: true
  },
  {
    id: 'rebootFrequency',
    label: 'Reboot Frequency',
    align: 'center',
    section: 'General',
    show: true
  },
  {
    id: 'wifiPodsExperience',
    label: 'WiFi Pods Experience',
    align: 'center',
    section: 'General',
    show: true
  },
  {
    id: 'wifiPodsSpeedExpectation',
    label: 'WiFi Pods Speed Expecation',
    align: 'center',
    section: 'General',
    show: true
  },
  {
    id: 'installationActivationExperience',
    label: 'Installation and Activation Experience',
    align: 'center',
    section: 'General',
    show: true
  }

]

export const searchByFields = [
  { label: 'Verbatim', value: 'verbatim' },
  { label: 'Alt Verbatim', value: 'altVerbatim' },
  { label: 'Notes', value: 'notes' },
  { label: 'Visit ID', value: 'visitId' },
  { label: 'Account Number (Encrypted)', value: 'encAccountNumber' },
  { label: 'Mobile Account Number (Encrypted)', value: 'encMobileAccountNumber' },
]

export const verbatimLength = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
]

export const filteredColumnOptions = allColumnHeaders.filter(column => column.show)

export const sortedColumnOptions = orderBy(filteredColumnOptions, column => column.section, ['asc'])
  .filter(
    column =>
      column.section === 'General' ||
      column.section === 'Metrics' ||
      column.section === 'Analysis' ||
      column.section === 'Application' ||
      column.section === 'Device' ||
      column.section === 'Survey' ||
      column.section === 'Installation Drivers' ||
      column.section === 'Account'
  )
  .map(column => {
    return { value: column.id, label: column.label, section: column.section }
  })

export const allColumnsView = { label: 'All Columns', value: 'allColumnsView' }
export const reviewsView = { label: 'Reviews View', value: 'reviewsView' }
export const detailsView = { label: 'Details View', value: 'detailsView' }
export const epepView = { label: 'Employee Exp View', value: 'epepView' }

export const defaultColumns = [allColumnsView, reviewsView, detailsView, epepView]

export const allColumnHeaderLabels = allColumnHeaders.map(header => header.label)
export const reviewsViewColumnHeaderLabels = [
  'Date',
  'Review (Verbatim)',
  'CSAT',
  // 'CES', *commented out until proper equations are used to get these stats
  'NPS',
  'Success Rate',
  'Applied Categories',
]
export const detailsViewColumnHeaderLabels = ['Date', 'Review (Verbatim)', 'CSAT', 'SR', 'Visit ID']
export const epepViewColumnHeaderLabels = [
  'Date',
  'Review (Verbatim)',
  'Alt Review (Alt Verbatim)',
  'ML Analysis Field',
  'CSAT',
  'Department',
  'Business Unit',
  'Email',
  'PID',
  'Title',
  'First Name',
  'Last Name',
  'Account Number',
  'Username',
]

export const DESCRIPTION_CHARACTER_LIMIT = 256
