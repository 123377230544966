import styled, { css } from 'styled-components'
import chroma from 'chroma-js'
import { Button } from 'components/shared/Button'
import colors from 'components/shared/utils/colors'

interface StyledIconButtonProps {
  $height: string
  $isDarkMode?: boolean
}

export const IconButton = styled(Button)<StyledIconButtonProps>`
  min-width: ${({ width }) => width};
  min-height: ${({ $height }) => $height};
  border-radius: 50%;
  height: ${({ $height }) => $height};

  /* Interactions */
  ${({ disabled, iconColor, $isDarkMode }) =>
    !disabled &&
    css`
      :hover {
        background-color: rgba(
          ${chroma(iconColor || ($isDarkMode ? colors.white : colors.blue20))
            .alpha(0.1)
            .rgba()
            .join(',')}
        );
      }

      :active {
        background-color: rgba(
          ${chroma(iconColor || ($isDarkMode ? colors.white : colors.blue20))
            .alpha(0.2)
            .rgba()
            .join(',')}
        );
      }
    `}
`
