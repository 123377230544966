import React, { ReactNode } from 'react'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import colors from '../../utils/colors'
import spacing from '../../utils/spacing'
import transition from '../../utils/transition'
import GlobalStyle from './GlobalStyle'
import breakpoints, { mediaQueries } from '../../utils/breakpoints'
import { useDarkMode } from '../dark-mode'

interface ThemeProps {
  children: ReactNode
  overrides?: Record<string, unknown>
}

export const theme = {
  colors,
  spacing,
  transition,
  breakpoints,
  mediaQueries,
  isDarkMode: false,
}

export type Theme = typeof theme

export default ({ children, overrides = {} }: ThemeProps) => {
  const { isDarkMode } = useDarkMode()
  return (
    <SCThemeProvider theme={{ ...theme, isDarkMode, ...overrides }}>
      <GlobalStyle />
      {children}
    </SCThemeProvider>
  )
}
