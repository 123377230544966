import styled, { css } from 'styled-components'
import Spacing from 'components/shared/bit/utils/spacing'
interface ScreenContentContainerProps {
  readonly bg?: string
}

export const ScreenContentContainer = styled.div<ScreenContentContainerProps>`
  flex: 1;
  min-height: 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  max-width: 100%;
  width: 100%;
  height: 100%;
  background: ${props => (props.bg ? props.bg : '')};
`

export const ScreenContent = styled.div<{ docsScreen?: boolean }>`
  padding: ${Spacing.section.xs};
  padding-bottom: ${Spacing.section.sm};
  min-height: 100%;

  & > div {
    flex: 1;
  }
  ${({ docsScreen }) =>
    !docsScreen &&
    css`
      display: flex;
      flex: 1;
    `};
`

export const FullScreenContent = styled.div<{ docsScreen?: boolean }>`
  min-height: 100%;

  & > div {
    flex: 1;
  }
  ${({ docsScreen }) =>
    !docsScreen &&
    css`
      display: flex;
      flex: 1;
    `};
`
