import React from 'react'
import { Link as RouterLink } from '@reach/router'
import styled, { css } from 'styled-components'

import colors from 'components/shared/bit/utils/colors'
import { LinkProps } from './index'

export const mainStyles = css`
  font-weight: 500;
  color: ${colors.blue20};
  cursor: pointer;

  &:hover,
  &:active,
  &:hover *,
  &:active * {
    text-decoration: ${(props: LinkProps) => (props.underline ? 'underline' : 'none')};
  }
`

export const ReactRouterLink = styled(({ underline, ...props }) => <RouterLink {...props} />)`
  ${mainStyles};
`

export const AnchorLink = styled.a`
  ${mainStyles};
`

export const SpanLink = styled.span`
  ${mainStyles};
`
