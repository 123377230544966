import React from 'react'
import styled, { css } from 'styled-components'
import MuiLaunchIcon from '@material-ui/icons/Launch' // @TODO determine use and convert icons
import MenuItem from '@material-ui/core/MenuItem'
import StarIcon from '@material-ui/icons/Star'
import Chip from '@material-ui/core/Chip'
import Spacing from '../bit/utils/spacing'
import colors from '../bit/utils/colors'

const APPLICATION_LIST_WIDTH = '190px'

export const EntityContainer = styled(
  ({
    disableClick,
    hasBottomDivider,
    ...otherProps
  }: {
    disableClick: boolean
    hasBottomDivider?: boolean
    [key: string]: any
  }) => <MenuItem {...otherProps} />
)`
  ${props => (props.disableClick ? 'cursor: default;' : '')}
  padding-left: 2px;
  font-size: 14px;
  font-family: inherit;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${props =>
    !props.disableClick
      ? ''
      : `
    &:hover {
      background-color: initial;
    }
    &:active {
      background-color: initial;
    }
  `}
  ${({ hasBottomDivider }) =>
    hasBottomDivider ? `border-bottom: solid 1px ${colors.gray20};` : ''}
  width: calc(100% + 3rem);
  margin-left: -1.5rem;
  padding: 0.5rem 1.75rem;
  &:hover {
    background-color: ${colors.gray20};
  }
`

export const EntityContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
`

export const IconContainer = styled.div.attrs({
  $size: '40px',
})`
  flex-shrink: 0;
  background-color: ${colors.gray20};
  ${({ $size }) => css`
    border-radius: ${$size};
    width: ${$size};
    height: ${$size};
  `}

  margin-right: ${Spacing.element.sm};
  text-transform: uppercase;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`

export const ApplicationIDContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`

export const AppTypeText = styled.div`
  font-size: 0.8rem;
  white-space: pre-wrap;
  width: 7rem;
  width: 100%;
`

export const StyledStarIcon = styled(StarIcon)`
  color: ${colors.blue10};
`

export const StyledLaunchIcon = styled(MuiLaunchIcon)`
  color: ${colors.blue10};
`

export const ApplicationName = styled.div`
  font-weight: 500;
`

export const StyledChip = styled(Chip)`
  font-weight: 500;
`

export const StyleGroupRow = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: ${APPLICATION_LIST_WIDTH};
`
