import React from 'react'
import * as S from './styles'
import { useDarkMode } from 'components/shared/dark-mode'
import Flex from 'components/shared/flex'
import Typography from 'components/shared/typography/Typography'
import colors from 'components/shared/utils/colors'

export interface SwitchProps {
  startActive?: true
  active?: boolean
  innerLabel?: true
  innerLabelText?: {
    on: string
    off: string
  }
  onSwitch?: (newState: boolean) => void
  children?: string
}

export const Switch = ({
  startActive,
  innerLabel,
  active,
  onSwitch,
  children,
  innerLabelText = { on: 'ON', off: 'OFF' },
  ...props
}: SwitchProps) => {
  const { isDarkMode } = useDarkMode()
  const [isActive, setIsActive] = React.useState(!!startActive)
  const isTrulyActive = active !== undefined ? active : isActive

  function toggleIsActive(): void {
    onSwitch && onSwitch(!isTrulyActive)
    setIsActive(!isActive)
  }

  return (
    <S.Container onClick={toggleIsActive} $isDarkMode={isDarkMode} {...props}>
      <Flex tabIndex={-1} inline marginBetween="sm">
        {children && (
          <Typography
            color={isDarkMode ? colors.white : colors.black}
            variant="body"
            as="span"
            noMargin
          >
            {children}
          </Typography>
        )}
        <S.SwitchContainer $isActive={isTrulyActive}>
          {innerLabel && (
            <S.InnerLabel $isOn $isActive={isTrulyActive}>
              <Typography color="white" variant="caption" noMargin>
                {innerLabelText.on}
              </Typography>
            </S.InnerLabel>
          )}
          {innerLabel && (
            <S.InnerLabel $isActive={isTrulyActive}>
              <Typography color="white" variant="caption" noMargin>
                {innerLabelText.off}
              </Typography>
            </S.InnerLabel>
          )}
          <S.Circle $isActive={isTrulyActive} />
        </S.SwitchContainer>
      </Flex>
    </S.Container>
  )
}
