import React from 'react'
import styled from 'styled-components'

import Pill from 'components/shared/Pill'
import SearchInput from './SearchInput'

import colors from '../bit/utils/colors'

export const StyledSearchInput = styled(
  ({
    value,
    suggestions,
    ...otherProps
  }: {
    value: string
    suggestions: any
    [key: string]: any
  }) => <SearchInput {...otherProps} />
)`
  && {
    height: 28px;
    width: 122px;
    margin-top: -8px;
    border-color: ${colors.blue20};
  }

  && input {
    ::placeholder {
      color: ${colors.blue20};
      opacity: 1;
    }
  }
`

export const SearchInputContainer = styled.div`
  display: inline-block;
  margin: 0 0.5rem;
`

export const StyledPill = styled(Pill)`
  &&,
  &&:focus {
    margin-bottom: 0.8125rem;
  }
`
