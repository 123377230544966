import styled, { css } from 'styled-components'
import colors from '../bit/utils/colors'
import ReactBaseTable from 'react-base-table'

export const StyledBaseTable = styled(ReactBaseTable)`
  div[role='row'] {
    border-bottom: 1px solid ${colors.gray20};
  }

  & .defaultRow {
    cursor: pointer;
  }

  & .BaseTable__table-main {
    outline: 1px solid ${colors.gray20};
  }

  & .BaseTable__row-cell-text {
    white-space: normal;
  }
`

export const TableContainer = styled.div<{ isChart?: boolean; showBorder?: boolean }>`
  width: 100%;
  height: 100%;

  .BaseTable__row-cell {
    font-size: 0.825rem;
  }

  && {
    display: inline-table;
  }

  && table tbody :last-child td {
    border: none;
  }

  && table tbody :first-child td {
    padding: 1rem 1.5rem 1rem 0;
  }

  && table tbody tr > :first-child {
    padding-left: 1rem;
  }
  ${props => (props.isChart ? 'overflow: auto;' : '')}
  ${props => (props.isChart ? 'display: flex;' : '')}
  ${props => (props.isChart ? 'flex-flow: row nowrap;' : '')}
  ${props => (props.isChart ? 'justify-content: center;' : '')}
  
  ${({ showBorder }) =>
    showBorder &&
    css`
      border-top: 1px solid #d8dde6;
      border-bottom: 1px solid #d8dde6;
    `};

  padding: 0;
  margin-top: 8px;
`
export const ApiNameContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`
