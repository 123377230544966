import React from 'react'
import breakpoints, { Breakpoint } from '../../utils/breakpoints'

export interface ViewportSize {
  width: number
  height: number
  isMobile: boolean
  isClient: boolean
}

function getBreakpoint(width: number): Breakpoint {
  const breakpointKeys = Object.keys(breakpoints) as Array<Breakpoint>
  const breakpointKey = breakpointKeys.find(key => {
    const breakpoint = breakpoints[key]
    const nextBreakpoint = breakpointKeys
      .filter(nextKey => breakpoints[nextKey] > breakpoint)
      .reduce((accum: number, nextKey) => Math.min(accum, breakpoints[nextKey]), 99999)
    return width >= breakpoint && width < nextBreakpoint
  })
  return breakpointKey || 'xs'
}

export function getViewportSize() {
  const isClient = typeof window === 'object'
  const dimensions = {
    width: isClient ? window.innerWidth : 0,
    height: isClient ? window.innerHeight : 0,
  }
  const isMobile = dimensions.width < breakpoints.lg
  return {
    ...dimensions,
    isMobile,
    isClient,
    breakpoint: getBreakpoint(dimensions.width),
  }
}

export const ViewportSizeContext = React.createContext<ViewportSize>(getViewportSize())

export default ({ children }: any) => {
  const [viewportSize, setViewportSize] = React.useState(getViewportSize())

  React.useEffect(() => {
    function handleResize(): void {
      setViewportSize(getViewportSize())
    }
    if (viewportSize.isClient) {
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
    return
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ViewportSizeContext.Provider value={viewportSize}>{children}</ViewportSizeContext.Provider>
  )
}
